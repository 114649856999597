import React from 'react';
import ReactMarkdown from 'react-markdown';
import {SobreNosotros} from './Avisos';
import { useEffect } from 'react';
const Nosotros = () => {
  //Scroll to top
  useEffect (() => {
    window.scrollTo(0, 0);
  }
  , []);
  return (
    <div
        style={{
            padding: '20px',
            maxWidth: '800px',
            margin: 'auto',
        }}
    >
        <ReactMarkdown>
            {SobreNosotros}
        </ReactMarkdown>
    </div>
  );
};

export default Nosotros;
