import React from 'react';
import styled, { keyframes } from 'styled-components';

const linearProgressAnimation = keyframes`
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
`;

const LinearProgressBarContainer = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  position: relative;
`;

const Bar = styled.div`
  width: 0;
  height: 100%;
  background-color: #359C4F;
  position: absolute;
  animation: ${linearProgressAnimation} 2s infinite linear;
  
  @media (max-width: 768px) {
    animation-duration: 1s; /* Faster animation duration for mobile */
  }
`;

function MaterialDesignProgressBar() {
  return (
    <LinearProgressBarContainer>
      <Bar />
    </LinearProgressBarContainer>
  );
}

export default MaterialDesignProgressBar;
