import React, {useState, useEffect, Fragment} from 'react';
import { Actions, CartButton, HeaderContainer, Logo,CartTitle,CartItem,CartItemTitle,CartItemPrice,CartItemPriceTotal,CartItemImage,CartItemInfo, LogoContainer, MobileMenuButton, ProfileButton, Search, SearchBarLoading, SearchButton, SearchContainer, SearchContent, SearchLink, MobileDrawer, MobileDrawerHeader, MobileDrawerItem, MobileDrawerItemSpecialCard } from './Styles/head';
import logo from '../../assets/logo.png';
import {AiOutlineSearch, AiOutlineUser, AiOutlineMenu} from 'react-icons/ai';
//Filled User Icon
import { BsPersonFill } from 'react-icons/bs';
import { BsCart3 } from 'react-icons/bs';
import Typed from 'react-typed';
import MaterialDesignProgressBar from './Styles/loader';
import Panel from '../Miscellaneous/Panel';
import Button from '../Miscellaneous/Button';
import { Separator } from '../../Screens/Login/style';
import { Link, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import {BiBarcode, BiLogOut, BiTrash} from 'react-icons/bi';
import { addProducts, getProductSearch, needReloadProducts } from '../../indexedDBService';
import ReactDOMServer from 'react-dom/server';
import { useAuth } from '../../Context/Auth';
import CartService from '../../Utils/API/cart';
import { toast } from 'react-toastify';
import {CheckoutItemInfoQuantity, CheckoutItemInfoQuantityButton, CheckoutItemInfoQuantityContainer} from '../../Screens/Checkout/style';
import ProductService from '../../Utils/API/products';
import { Portal } from 'react-portal';
import {BiSolidOffer} from 'react-icons/bi';
import escrituraycorrecion from '../../assets/Escritura-y-correcion.svg';
import productosdeoficina from '../../assets/productos-de-oficina.svg';
import productosescolares from '../../assets/productos-escolares.svg';
import etiquetasyembalajes from '../../assets/etiquetas-y-embalajes.svg';
import papelcuardernosysobres from '../../assets/papel-cuadernos-y-sobres.svg';
import archivosyclasificacion from '../../assets/archivos-y-clasificacion.svg';
import consumiblesyaccesoriosinformaticos from '../../assets/consumibles-y-accesorios-informaticos.svg';
import mobiliariodeoficina from '../../assets/mobiliario-de-oficina.svg';
import organizacion from '../../assets/organizacion.svg';
import juegos from '../../assets/juegos.svg';
import catalogos from '../../assets/catalogo.svg';
import { ItemIcon } from './Styles/menu';
import AuthService from '../../AuthService';
import algoliasearch from 'algoliasearch/lite';
import { Hits, InstantSearch, SearchBox } from 'react-instantsearch';
import InputWithAbortController from './input';
const searchClient = algoliasearch('VTZBRYYBNV', '00a2112cad1da11c2d76fdfb90048ef5');

//get screen height
const height = window.innerHeight;
const itemHeight = (height - 75 - 80) /10;    

const useOutsideAlerter = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

export default function Head(props){
    const history = useNavigate();
    
    const {user, logout, loggedIn} = useAuth();
    const [search, setSearch] = useState("");
    const [opened, setOpened] = useState(false);
    const [cartOpened, setCartOpened] = useState(false);
    const [cart, setCart] = useState(null);
    const [loginPanel, setLoginPanel] = useState(false);
    const wrapperRef = React.useRef(null);
    const panelRef = React.useRef(null);
    const [searchItems, setSearchItems] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchProductsLoading, setSearchProductsLoading] = useState(true);
    const [cartTotalItems, setCartTotalItems] = useState(0);
    
    const [MobileDrawerOpened, setMobileDrawerOpened] = useState(false);
    const signOut = () => {
        logout();
        setLoginPanel(false);
        history('#');
    }
        

    //delay function


    const delay = ms => new Promise(res => setTimeout(res, ms));



    const makeSearch = (search, signal) => {
        setSearchLoading(true);
        console.log(search);
        ProductService.searchProducts(search,signal).then(res => {
            setSearchItems(res);
            setSearchLoading(false);
        }
        ).catch(err => {
            console.log(err);
        });



    }
    function boldSubstringInString(inputString) {
        // Create a regular expression pattern that matches all search terms
        const searchPattern = new RegExp(`(${search.split(' ').map(escapeRegExp).join('|')})`, 'gi');
      
        // Replace matching substrings with <b> tags
        const resultString = inputString.replace(searchPattern, '<b>$&</b>');
      
        return (
          <div dangerouslySetInnerHTML={{ __html: resultString }} />
        );
      }
      
      // Helper function to escape regular expression special characters
      function escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
      
      
      function addQuantity(id) {
        const newProducts = cart.map(product => {
            if(product._id === id){
                product.quantity = parseInt(product.quantity) + 1*product.quantityStep;
                CartService.addProductQuantity(product.productID, product.quantity);
            }
            return product;
        });
        setCart(newProducts);
    }

    function removeQuantity(id) {
        const newProducts = cart.map(product => {
            if(product._id === id){
                
                product.quantity = parseInt(product.quantity) - 1*product.quantityStep;
                if(product.quantity < 0){
                    product.quantity = 0;
                }
            }
            return product;
        });

        console.log(id);

        CartService.addProductQuantity(newProducts.find(product => product._id === id).productID, newProducts.find(product => product._id === id).quantity+"");

        setCart(newProducts);
    }

       
    function changeQuantity(id, quantity) {
        let timer;
        const newProducts = cart.map(product => {
            if(product._id === id && quantity % product.quantityStep === 0){
                product.quantity = (quantity);
                CartService.addProductQuantity(product.productID, product.quantity);
            }else if(product._id === id && quantity % product.quantityStep !== 0){
                product.quantity = quantity;
            }
            clearTimeout(timer);
            return product;
        });
        timer = setTimeout(() => checkMultiple(id), 600);
        setCart(newProducts);
    }

    function checkMultiple(id) {
        const newProducts = cart.map(product => {
            if(product._id === id && product.quantity % product.quantityStep !== 0){
                //allways round up
                product.quantity = Math.ceil(product.quantity/product.quantityStep)*product.quantityStep;
                CartService.addProductQuantity(product.productID, product.quantity);
                toast.error(`La cantidad debe ser múltiplo de ${product.quantityStep}`);
            }
            return product;
        });

        setCart(newProducts);
    }
    useEffect(() => {

        CartService.getTotalItems().then(res => {
            //add to all items the field originalPrice 
            setCartTotalItems(res);
        }).catch(err => {
            setCartTotalItems(0);
        })

      
    }, [])
    useOutsideAlerter(wrapperRef, () => {
        setOpened(false)
    })
    useOutsideAlerter(panelRef, () => {
        setLoginPanel(false)
    })
    return (
        <HeaderContainer>
            <MobileMenuButton
                onClick={() => setMobileDrawerOpened(!MobileDrawerOpened)}
            >
                <AiOutlineMenu
                    size={20}
                    color="#028322"
                />
            </MobileMenuButton>
            {
                MobileDrawerOpened && (
                    <Portal>
                    <MobileDrawer>
                        <MobileDrawerHeader>
                        <MobileMenuButton
                onClick={() => setMobileDrawerOpened(!MobileDrawerOpened)}
            >
                <AiOutlineMenu
                    size={20}
                    color="#028322"
                />
            </MobileMenuButton>
                        <LogoContainer onClick={() => window.location.href='/'}>
                            <Logo src={logo} alt="Distribuciones RB"/>
                        </LogoContainer>
                        </MobileDrawerHeader>
                        <MobileDrawerItem special onClick={() => {window.location.href = ('/categories/ofertas');}}>
                          <BiSolidOffer color='#fff' size={28} style={{
                            marginRight: '10px',
                          }}/>Ofertas
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/escritura-y-correccion');}}>
                          <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={escrituraycorrecion} alt="Escritura y corrección"/>Escritura y corrección
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/productos-de-oficina');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={productosdeoficina} alt="Productos de oficina"/>Productos de oficina
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/productos-escolares');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={productosescolares} alt="Productos escolares"/>Productos escolares
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/etiquetas-y-embalajes');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={etiquetasyembalajes} alt="Etiquetas y embalajes"/>Etiquetas y embalajes
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/papel-cuadernos-y-sobres');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={papelcuardernosysobres} alt="Papel, cuadernos y sobres"/>Papel, cuadernos y sobres
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/archivo-y-clasificacion');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={archivosyclasificacion} alt="Archivo y clasificación"/>Archivo y clasificación
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/consumibles-y-accesorios-informaticos');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={consumiblesyaccesoriosinformaticos} alt="Consumibles y accesorios informáticos"/>Consumibles y accesorios informáticos
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/mobiliario-de-oficina');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={mobiliariodeoficina} alt="Mobiliario de oficina"/>Mobiliario de oficina
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/organizacion');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={organizacion} alt="Organización"/>Organización
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/juegos');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={juegos} alt="Juegos"/>Juegos
                        </MobileDrawerItem>
                        <MobileDrawerItem h={itemHeight} onClick={() => {window.location.href =('/categories/catalogos');}}>
                            <ItemIcon style={{
                            filter: 'invert(1)',
                            marginRight: '10px',
                        }} src={catalogos} alt="Catálogos"/>Catálogos
                        </MobileDrawerItem>
                    </MobileDrawer>
                    </Portal>
                )
            }
            <Link to="/">
            <LogoContainer>
                <Logo src={logo} alt="Distribuciones RB"/>
            </LogoContainer>
            </Link>

            {
                        !searchProductsLoading ? <SearchBarLoading/> : (
                            <SearchContainer
                            opened={opened}
                        >
                            <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                            ref={wrapperRef}
                            
                            >
            

                            <InputWithAbortController
                                onClick={() => {
                                        setOpened(true);
                                    
                                }}
                                setSearch={setSearch}
                                onKeyDown={(e) => {
                                    if(e.key === 'Enter'){
                                        window.location.href='/search?s='+search;
                                    }
                                }}
                                onSearch={makeSearch}
                            type="text" placeholder=""/>

                            <SearchButton
                                opened={opened}
                                onClick={() => {
                                    window.location.href='/search?s='+search;
                                }}
                            >
                                      
                                <AiOutlineSearch
                                    size={28}
                                    color="#fff"
                                />
                            </SearchButton>
                            </div>
                            {opened && (
                    <>
                                    <SearchContent ref={wrapperRef}>
                                        {
                                            searchLoading && <MaterialDesignProgressBar/>
                                        }
                                        {

                                            searchItems.map(item => (
                                                <SearchLink href={'/details/'+item.productURL}>
                                                    {
                                                        boldSubstringInString(item.name)
                                                    }
                                                </SearchLink>
                                                ))
                                        }
                                    </SearchContent>
                    </>
                )
                }

            </SearchContainer>
                        )
                    }
                    {/* <SearchContainer
                    >
                                           <InstantSearch searchClient={searchClient} indexName="DistribucionesRB">
                            <SearchBox 
                            translations={{
                                placeholder: 'Buscar productos...'
                            }}
                            />
                            <div style={{
                                display: 'flex',
                                maxHeight: '300px',
                                overflowY: 'auto',
                            }}>
                            <Hits hitComponent={({hit}) => (
                                <></>
    )
                        }/>

                            </div>
                            </InstantSearch>
                            <SearchContent ref={wrapperRef}>
                                        {
                                            searchLoading && <MaterialDesignProgressBar/>
                                        }
                                        {

                                            searchItems.map(item => (
                                                <SearchLink href={'/details/'+item.productURL}>
                                                    {
                                                        boldSubstringInString(item.name)
                                                    }
                                                </SearchLink>
                                                ))
                                        }
                                    </SearchContent>
                    </SearchContainer> */}

            <Actions>

                <ProfileButton
                    onClick={() => {
                        setLoginPanel(!loginPanel)
                    }}
                >
                    {
                        loggedIn ? (
                            <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingRight: '10px',

                            }}
                            >
                            <AiOutlineUser
                            size={28}
                            color="#028322"
                        />
                        <span
                        style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#028322',
                        }}
                        >{user?.Name}</span>
                        </div>
                        ) : (
                            <AiOutlineUser
                            size={28}
                            color="#028322"
                        />
                        )
                    }

                </ProfileButton>
                <CartButton
                    count={cartTotalItems < 99 ? cartTotalItems : "+99"}
                    //onMouseEnter Delay function
                    onMouseEnter={async () => {
                        CartService.getCart().then(res => {
                            const newProducts = res.items.map(product => {
                                product.originalPrice = product.price;
                                return product;
                            }
                            );
                            setCart(newProducts);
                            CartService.getTotalItems().then(res => {
                                setCartTotalItems(res);
                            }).catch(err => {
                                setCartTotalItems(0);
                            }
                            )

                        }).catch(err => {
                            setCart([]);
                        })
                        await delay(120);
                        setCartOpened(true);
                    }}
                    onClick={() => {
                        if(cartTotalItems > 0){
                            history('/checkout');
                        
                        }
                    }
                    }
                    onMouseLeave={async () => {
                        await delay(120);
                        setCartOpened(false);
                    }}


                >
                    <BsCart3
                        size={28}
                        color="#fff"
                    />
                                <CSSTransition
                in={cartOpened}
                timeout={150}
                appear={true}
                classNames="scale"
                unmountOnExit
            >
            <Panel
            loading={cart === null ? true : false}
            opened={true} borderRadius={"0px"} top width={"350px"} pright={"0px"} ptop={"75px"} zIndex={"10"} expandMobile>
                
                {
                    cart?.length > 0 ? (
                        <>
                    <CartTitle>
                        Mi Carrito
                    </CartTitle>

                    {
    cart.some(product =>
        product.discounts &&
        product.discounts.some(discount => discount.minQuantity <= product.quantity)
    ) && (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
            width: '100%',
            height: 'auto',
            background: '#028322',
            marginBottom: '10px',
            padding: '5px 10px',
            boxSizing: 'border-box',
        }}>
            <BiSolidOffer
                size={24}
                color="#fff"
                style={{
                    minWidth: '24px',
                }}
            />
            <p
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#fff',
                    margin: '0px 0px',
                    textAlign: 'center',
                    padding: '0px 0px',
                }}
            >
                ¡Enhorabuena! Has alcanzado una oferta en uno o más productos
            </p>
        </div>
    )
}


                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        height: 'auto',
                        maxHeight: '60vh',
                        overflowY: 'auto',
                    }}>
                    {
                            cart?.map((item,index) => (
                                <Fragment key={index}

                                >
                                    <CartItem>
                                        <CartItemImage
                                                                            onClick={() => {
                                                                                history('/details/'+item.productURL);
                                                                            }
                                                                            }
                                        background={item.images[0]}>
                                            <img src={item.images[0]} alt={item.name} style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '5px',
                                            }}/>
                                        </CartItemImage>
                                        <CartItemInfo>
                                            <CartItemTitle
                                            onClick={() => {
                                                history('/details/'+item.productURL);
                                            }
                                            }
                                            >
                                                {item.name}
                                            </CartItemTitle>
                                            <CartItemPrice>

                                                {
                                                    item.discounts && item.discounts.reduce((price, discount) => (item.quantity >= discount.minQuantity) ? item.price = discount.discountedPrice : item.price = price, item.price =item.originalPrice) !== item.originalPrice ? <span style={{
                                                        textDecoration: 'line-through',
                                                        color: '#707070',
                                                    }}>{item.originalPrice.toFixed(3).toString().replace('.',',')}€</span> : null
                                                }
                                                {' '}
                                                {
                                                    item.discounts && item.discounts.reduce((price, discount) => (item.quantity >= discount.minQuantity) ? item.price = discount.discountedPrice : item.price = price, item.price =item.originalPrice)
                                                }

                                                 € 
                                                
                                            </CartItemPrice>
                                            <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                            >
                                            <CheckoutItemInfoQuantityContainer
                                            style={{
                                                transform: 'scale(0.8)',
                                            }}
                                            >
                                                <CheckoutItemInfoQuantityButton onClick={() => removeQuantity(item?._id)}>-</CheckoutItemInfoQuantityButton>
                                                <CheckoutItemInfoQuantity onChange={(e) => changeQuantity(item?._id, e.target.value)}  value={item?.quantity}></CheckoutItemInfoQuantity>
                                                <CheckoutItemInfoQuantityButton onClick={() => addQuantity(item?._id)}>+</CheckoutItemInfoQuantityButton>
                                                <CheckoutItemInfoQuantityButton
                                                style={{
                                                    background: '#fff',
                                                    marginLeft: '0px',
                                                }}
                                                onClick={() => {
                                                 CartService.deleteProductFromCart(item.productID._id).then(res => {
                                                    setCart(cart.filter(product => product._id !== item._id));
                                                    CartService.getTotalItems().then(res => {
                                                        setCartTotalItems(res);
                                                    }).catch(err => {
                                                        setCartTotalItems(0);
                                                    }
                                                    )
                                                 }
                                                    ).catch(err => {
                                                        console.log(err);
                                                    }) 
                                                }
                                            }
                                                >
                                                    <BiTrash size={24} color="#028322"/>
                                                </CheckoutItemInfoQuantityButton>

                                            </CheckoutItemInfoQuantityContainer>
                                            <CartItemPriceTotal>
                                                {(item.price * item.quantity).toFixed(3).toString().replace('.',',')} €
                                            </CartItemPriceTotal>
                                            </div>
                                        </CartItemInfo>
                                    </CartItem>
                                </Fragment>
                            ))

                    }
                    </div>

                    <span style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#028322',
                        margin: '0px 0px',
                        marginBottom: '-15px',
                        textAlign: 'right',
                        padding: '0px 0px',
                    }}
                    >
                        Envío: {
                            cart.reduce((a, b) => a + (b['price'] * b['quantity']), 0) > 100 ? 'Gratis' : '6 €'
                        }
                    </span>
                        

                    <span
                    style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: '#028322',
                        margin: '0px 0px',
                        textAlign: 'right',
                    }}
                    >
                        Total: {
                        
                        (cart.reduce((a, b) => a + (b['price'] * b['quantity']), 0) >= 100 ? cart.reduce((a, b) => a + (b['price'] * b['quantity']), 0) : cart.reduce((a, b) => a + (b['price'] * b['quantity']), 0) + 6).toFixed(3).toString().replace('.',',') + ' €'
                        
                        }
                    </span>
                    <Button
                        onClick={() => {
                            window.location.href='/checkout';
                        }
                        }
                        width="100%"
                        expandMobile

                    >
                        Ver Carrito
                    </Button>

                    </>
                    ) : (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}>
                            <BsCart3
                                size={28}
                                color="#028322"
                            />
                            <p
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#028322',
                                    margin:0,
                                }}
                            >
                                Tu carrito está vacío
                            </p>
                            <p 
                            style={{
                                fontSize: '14px',
                                color: '#028322',
                                margin:0,
                            }}
                            >
                                ¡Empieza a añadir productos!
                            </p>
                            </div>
                    )
                }

            </Panel>
            </CSSTransition>
                </CartButton>
            </Actions>
            <CSSTransition
                in={loginPanel}
                timeout={150}
                appear={true}
                classNames="fade"
                unmountOnExit

            >
            <Panel opened={true}  top width={"250px"} pright={"10px"} ptop={"75px"} expandMobile>
                {
                    !loggedIn ? (
                        <>

                <Button onClick={()=>window.location.href='/login?r='+window.location.pathname}>
                    Iniciar Sesión
                </Button>
                <Separator>&nbsp;&nbsp;o&nbsp;&nbsp;</Separator>
                <Button outline
                onClick={()=>window.location.href='/login?r='+window.location.pathname+'&l=0'}
                >
                    Registrarse
                </Button>
                        </>
                    ) : (
                        <>
                        <Link to="/me/profile"
                        style={{
                            textDecoration: 'none',
                        }}
                        >
                        <Button item outline justifyContent={'left'}>
                            <BsPersonFill
                                size={20}
                                style={{
                                    marginRight: '10px',
                                }}
                            />
                            Mi Perfil
                        </Button>
                        </Link>

 

                        {
                            (user?.role === 'Admin' || user?.role === 'BeeAdmin') && (
                                <Button onClick={()=>window.location.href='/admin'} item outline justifyContent={'left'}>
                            Administración
                        </Button>
                            )
                        }
                        <Button onClick={()=>signOut()}>
                            <BiLogOut
                                size={20}
                                style={{
                                    marginRight: '10px',
                                }}
                                color='#FFF'
                            />
                            Salir
                        </Button>
                        </>
                    )
                }

            </Panel>
            </CSSTransition>

            </HeaderContainer>
    );
}