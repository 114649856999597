import styled from 'styled-components';

export const CheckoutContainer = styled.div`
    width: ${props => props.opened ? '100%' : '50%'};
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    @media screen and (max-width: 600px){
        gap: 10px;
        width: calc(100vw - 40px);
    }
`;
export const Animation = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: 600px){
        flex-direction: column;
        gap: 10px;
        calc(100vw - 40px); 
    }
`;
export const CheckoutContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    @media screen and (max-width: 600px){
        flex-direction: column;
        gap: 10px;
        width: calc(100vw - 60px);
    }
`;

export const CheckoutContentLeft = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 600px){
        width: 100%;
    }
`;

export const CheckoutContentRight = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 600px){
        width: 100%;
    }
`;

export const CheckoutContentTitle = styled.span`
    width: 100%;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    color: #028322;
    @media screen and (max-width: 600px){
        font-size: 16px;
    }
`;

export const CheckoutFooter = styled.div`
    width: 100vw;
    height: auto;
    min-height: 70px;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 0px -1px 4px rgba(0,0,0,.1);
    align-items: center;
    justify-content: space-between;
    transition: all .2s ease-in-out;
    `;

export const CheckoutFooterButton = styled.button`
    width: auto;
    height: 50px;
    padding:0px 20px;
    background-color: #028322;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    @media screen and (max-width: 600px){
        font-size: 12px;
        border-radius: 50%;
        padding: 0px;
        width: 50px;
    }
    `;

    export const CheckoutFooterTotal = styled.span`
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 650;
    color: #000;
    `;

export const CheckoutFooterSteps = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: 600px){
        gap: 0px;
    }


`;

export const CheckoutFooterStep = styled.div`
    width: 50px;
    height: auto;
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    &:hover{
        cursor: pointer;
        gap: 5px;
    }
    `;
export const CheckoutFooterStepLine = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#028322' : '#aaa'};

    `;

export const CheckoutItem = styled.div`
    width: 100%;
    min-height: 100px;
    max-width: 550px;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    background-color: #fff;
    border: 2px solid ${props => props.stock ? '#028322' : '#f00'};
    padding: 10px;
    ${props => !props.stock ? `
        &::after{
            content: "Producto sin suficiente stock";
            width: auto;
            height: auto;
            background-color: rgba(255,0,0,.8);
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            padding: 5px;
            border-radius: 5px;
        }
    ` : ''};
    @media screen and (max-width: 600px){
        width: 250px;
        flex-direction: column;
        gap: 10px;
        padding: 10px 10px;
        box-sizing: border-box;
    }
`;

export const CheckoutItemImage = styled.div`
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    background-color: #ddd;
    background-image: url(${props => props.background});
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    @media screen and (max-width: 600px){
        width: 100%;
        height: 200px;
    }
`;

export const CheckoutItemInfo = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color:#028322;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const CheckoutItemInfoTitle = styled.span`
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: 600;
    color:#028322;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const CheckoutItemInfoPrice = styled.span`
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color:#028322;
    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const CheckoutItemInfoQuantityContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 600px){
        justify-content: flex-end;

    }
`;

export const CheckoutItemInfoQuantity = styled.input`
    width: 50px;
    height: 30px;
    border: none;
    outline: none;
    background-color: #fff;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
`;

export const CheckoutItemInfoQuantityButton = styled.button`
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    color: #fff;
    background-color: #028322;
    padding: 0px;
    box-sizing: border-box;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CheckoutTitle = styled.span`
    font-size: 2rem;
    font-weight: 600;
    color: #028322;
    @media screen and (max-width: 600px){
        font-size: 16px;
    }
`;

export const PaymentMethod = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const PaymentMethodCard = styled.div`
    width: 100%;
    max-width: 550px;
    min-width: 300px;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-color: #eee;
    padding: 0px 10px;
    &:hover{
        gap: 10px;
        background-color: #ddd;
    }
`;
export const ShippmentCard = styled.div`
    width: 100%;
    max-width: 550px;
    min-width: 300px;
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-color: #eee;
    padding: 0px 10px;
    &::after{
        content: "${props => props.active ? '6€' : 'GRATIS'}";
        width: auto;
        height: auto;
        color: #028322;
        font-size: 18px;
        position: relative;
        bottom: 0;
        left: 0;
        transform: scaleX(1);
        transition: all .2s ease-in-out;
    }
    &:hover{
        gap: 10px;
        background-color: #ddd;
    }
`;
export const CheckOutRightLeft = styled.div`
    width: calc(100vw - 40px);
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: 600px){
        gap: 10px;
        flex-direction: column;

    }
`;

export const CheckoutConfirmBack = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(2px);
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    z-index: 100;
    transition: all .2s ease-in-out;
    box-sizing: border-box;
    display: ${props => props.resume ? 'flex' : 'none'};
    @media screen and (max-width: 600px){
        padding: 0px;
    }
`;

export const CheckoutConfirm  = styled.div`
    width: 400px;
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;

    @media screen and (max-width: 600px){
        width: calc(100vw - 0px);
        padding: 20px;
    }
`;

export const CheckoutConfirmTitle = styled.span`
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 600;
    color: #028322;
    @media screen and (max-width: 600px){
        font-size: 16px;
    }
`;

export const CheckoutConfirmMap = styled.img`
    width: 100%;
    height: 150px;
    background-color: #ddd;
    background-image: url(${props => props.background});
    border-radius: 0px;
`;