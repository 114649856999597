export default function isMobile(minScreenWidth = 768, userAgent = navigator.userAgent) {
    // Regular expressions to match common mobile device keywords
  const mobileKeywords = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;

  // Check if the user agent string contains any of the mobile keywords
  const isMobileDevice = mobileKeywords.test(userAgent);

  // Check if the screen width is less than the specified minimum width
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const isNarrowScreen = screenWidth < minScreenWidth;

  return isMobileDevice || isNarrowScreen;
}