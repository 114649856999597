import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import {PoliticaDePrivacidad} from './Avisos';
const Privacidad = () => {
  useEffect (() => {
    window.scrollTo(0, 0);
  }
  , []);
  return (
    <div
        style={{
            padding: '20px',
            maxWidth: '800px',
            margin: 'auto',
        }}
    >
        <ReactMarkdown>
            {PoliticaDePrivacidad}
        </ReactMarkdown>
    </div>
  );
};

export default Privacidad;
