import React, {useState, useEffect}from "react";
import { AddressCardContainer, AddressEmpty, ProfileContentTitle, AddressCard, AddressInput, Field } from "./style";
import { AiOutlinePlus } from "react-icons/ai";
import Modal from "../../Components/Miscellaneous/Modal";
import AddressService from "../../Utils/API/address";
import {toast} from "react-toastify";
import Button from "../../Components/Miscellaneous/Button";
import { Loader } from "../Login/style";
//CSSTransition 
import { CSSTransition } from 'react-transition-group';
export default function Address() {
    const [opened, setOpened] = useState(false);

    const [client, setClient] = useState({
        Address: '',
        ZIP: '',
        City: '',
        Province: '',
        Country: '',
    });
    const [loadingData, setLoadingData] = useState(true);
    const [address, setAddress] = useState([]);

    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    useEffect(() => {
        AddressService.getAddresses().then(response => {
            setAddress(response);
            setLoadingData(false);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    const addClient = () => {
        //Validate client object and add it to the database
        //Check if not empty any field
        setLoading(true);
        if(Object.values(client).some(x => x === '')){
            toast.error('Por favor, rellene todos los campos');
            setLoading(false);
            return;
        }



        //Check if ZIP is valid
        if(client.ZIP.length < 5){
            toast.error('Por favor, introduzca un código postal válido');
            return;
        }

        //Check if phone is valid

        //Check if country is valid
        if(client.Country.length < 3){
            toast.error('Por favor, introduzca un país válido');
            return;
        }

        //Check if province is valid
        if(client.Province.length < 3){
            toast.error('Por favor, introduzca una provincia válida');
            return;
        }

        //Check if city is valid
        if(client.City.length < 3){
            toast.error('Por favor, introduzca una ciudad válida');
            return;
        }

        //Check if address is valid

        if(client.Address.length < 3){
            toast.error('Por favor, introduzca una dirección válida');
            return;
        }


        AddressService.addAddress(client).then(response => {
                setLoading(false);
                setClient({
                    Address: '',
                    ZIP: '',
                    City: '',
                    Province: '',
                    Country: '',
                });
                close();
                AddressService.getAddresses().then(response => {
                    setAddress(response);
                }).catch(error => {
                    console.error(error);
                });
            
        }
        ).catch(error => {
            setLoading(false);
        }
        );
    }



    const close = () => {
        setOpened(false);
        if(editing){
            setEditing(false);
            setClient({
                Address: '',
                ZIP: '',
                City: '',
                Province: '',
                Country: '',
            });
        }
    }
    return (
        <div style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        
        }}>
            <ProfileContentTitle>
                Mis Direcciones
            </ProfileContentTitle>
            <AddressCardContainer>
                <AddressCard onClick={() => setOpened(true)}>
                    <AddressEmpty>
                    <AiOutlinePlus size={20}/>
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textAlign: 'center'


                        }}>Añadir dirección</span>
                    </AddressEmpty>
                </AddressCard>
                {
                    address.map((address, index) => (
                        <AddressCard key={index} onClick={() => {
                            client.Address = address.Address;
                            client.City = address.City;
                            client.ZIP = address.ZIP;
                            client.Province = address.Province;
                            client.Country = address.Country;
                            setClient(client);
                            setOpened(true);
                            setEditing(true);

                        }}>
                            <span style={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textAlign: 'left'
                            }}>
                                {address.Address}, {address.City}, {address.ZIP}<br/> {address.Province}, {address.Country}
                            </span>
                            <button style={{
                                        width: 'auto',
                                        height: 'auto',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => {
                                            AddressService.deleteDropshippingAddress(address._id).then(response => {
                                                setAddress(address.filter((item, index2) => index2 !== index));
                                            }).catch(error => {
                                                toast.error('Error al eliminar el cliente');
                                            });
                                        }
                                        }
                                    >
                                        Eliminar
                                    </button>
                        </AddressCard>
                    ))
                }
                {
                                        loadingData ? (
                                            <Loader/>
                                        ) : (
                                            null
                                        )    
                }
            </AddressCardContainer>
            <CSSTransition in={opened} timeout={300} classNames="fade" unmountOnExit>
            <Modal title={editing ? "Modificar Dirección" : "Añadir nueva dirección"} opened={opened} close={()=>close}>
                <Field>
                <AddressInput onChange={(e) => setClient({
                            ...client,
                            Country: e.target.value
                        })} placeholder="País" value={client.Country} />
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            Province: e.target.value
                        })} value={client.Province} placeholder="Provincia"/>
                </Field>
                <Field>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            City: e.target.value
                        })} value={client.City} placeholder="Ciudad"/>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            ZIP: e.target.value
                        })} value={client.ZIP} placeholder="Código Postal" type="number"/>
                </Field>
                <Field>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            Address: e.target.value
                        })} value={client.Address} style={{width:'100%'}} placeholder="Dirección"/>
                </Field>


                <Button loading={loading} onClick={() => addClient()} fontWeight={500}>
                    {
                        editing ? (
                            'Modificar Dirección'
                        ) : (
                            'Añadir Dirección'
                        )
                    }
                </Button>
            </Modal>
            </CSSTransition>
        </div>
    )
}