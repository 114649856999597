import AuthService from "../../AuthService";
const API_URL = 'https://api.rb.beeapp.es';
const token = AuthService.getToken();

class ProductService {
    //Offer products to the user
    static async getOfferProducts() {
        try {
            const response = await fetch(`${API_URL}/products/offer`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            //if 404 return empty array
            if (data.message) {
                return [];
            }
            return data || [];
        } catch (error) {
            console.error(error);
        }
    }

    static async searchProducts(search, signal) {
        const response = await fetch(`${API_URL}/products/search?s=${search}`, {
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return data || [];

    }

    //Get product by id
    static async getProduct(id) {
        try {
            const response = await fetch(`${API_URL}/products/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            return data || [];
        } catch (error) {
            console.error(error);
        }
    }
    
    //Get all products by category, filter and pagination
    static async getProducts(category, filter, page) {
        try {
            const response = await fetch(`${API_URL}/products/${category}/${filter}/${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data || [];
        } catch (error) {
            console.error(error);
        }
    }

    //filter query composer for the backend
    //is a function that receives an object and returns a string, the separator is :, the separator is a comma, example: {name: 'pepe', age: 20} => name:pepe,age:20, the last comma is removed
    static filterQueryComposer(filter) {
        let filterQuery = '';
        for (const key in filter) {
            if (filter[key] === '') {
                continue;
            }
            if (filter[key] === 'true') {
                filter[key] = true;
            }
            if (filter[key] === 'false') {
                filter[key] = false;
            }
            filter[key] = filter[key].replace(/ /g, '_');
            filterQuery += `${key}:${filter[key]},`;
        }
        filterQuery = filterQuery.slice(0, -1);
        return filterQuery;
    }

    static async getProductsForMenu() {
        try {
            const response = await fetch(`${API_URL}/products/minimal`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            return data || [];
        } catch (error) {
            console.error(error);
        }
    }

    static async getProductsBySearch(category, page=1, minPrice=0, maxPrice=Infinity, filter={}) {
        //convert filter object to string
        filter = ProductService.filterQueryComposer(filter);
        try {
            const response = await fetch(`${API_URL}/products/searchAdvanced?s=${category}&page=${page}&minPrice=${minPrice}&maxPrice=${maxPrice}&filter=${filter.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            return data || [];
        } catch (error) {
            console.error(error);
        }
    }

    static async getProductsByCategory(category, page=1, minPrice=0, maxPrice=Infinity, filter={}) {
        //convert filter object to string
        filter = ProductService.filterQueryComposer(filter);
        try {
            const response = await fetch(`${API_URL}/products/category/${category}?page=${page}&minPrice=${minPrice}&maxPrice=${maxPrice}&filter=${filter.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            return data || [];
        } catch (error) {
            console.error(error);
        }
    }


}

export default ProductService;