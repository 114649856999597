import React, { createContext, useState, useEffect } from 'react';
import CookieConsentAPI from '../Components/CookieConsent/cookie';
const UtilsContext = createContext();

const UtilsProvider = ({ children }) => {
    const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [showCookieConsent, setShowCookieConsent] = useState(false);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    const toggleMegaMenu = () => {
        setIsMegaMenuOpen(!isMegaMenuOpen);
        if (isMegaMenuOpen) {
            document.body.style.overflow = '';
        }
        else {
            document.body.style.overflow = 'hidden';
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <UtilsContext.Provider
            value={{
                isMegaMenuOpen,
                windowWidth,
                windowHeight,
                toggleMegaMenu,
                showCookieConsent,
                setShowCookieConsent
            }}
        >
            {children}
        </UtilsContext.Provider>
    );
};

export { UtilsContext, UtilsProvider };

