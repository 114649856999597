import styled from 'styled-components';

export const BackDrop = styled.div`
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 800px){
       overflow-y: scroll;
    }
`;

export const ModalContainer = styled.div`
    width: max(50vw, 500px);
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        width: 90vw;
        margin: 1rem;
    }
    

`;

export const ModalHeader = styled.div`
    width: 100%;
    height: auto;
    display: flex;

    align-items: center;
    gap: 10px;
    box-sizing: border-box;
    padding:20px 25px;
    border-bottom: 0px solid #028322;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
    & h3{
        margin: 0;
        font-size: 20px;
        font-weight: bold;
        color: #028322;
        //uppercase
        text-transform: uppercase;
    }
    @media screen and (max-width: 800px){
        flex-direction: column;
        gap: 10px;
        & h3{
            text-align: center;
        }
        display: none;
    }
`;

export const ModalBody = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 25px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        padding: 10px 15px;
        margin-top: 2rem;
    }
`;

export const ModalFooter = styled.div`
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 25px;
    box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.25);
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        flex-direction: column-reverse;
        gap: 10px;
        height: auto;



    }
`;

export const ModalBodyItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding:10px 20px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        gap: 10px;
    }
`;

export const ModalBodyItemLeft = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
    box-sizing: border-box;
    & > * {
        margin: 0;
        padding: 0;
    }
    & > p {
        text-wrap: pretty;
    }
    @media screen and (max-width: 800px){
        font-size: 12px;
    }
`;

export const ModalBodyItemRight = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
    justify-content: center;
    box-sizing: border-box;
`;

export const Switch = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
@media screen and (max-width: 800px){
    transform: scale(0.8);
}
`;

export const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: transform 0.4s;
  }

  ${HiddenInput}:checked + & {
    background-color: #028322;
  }

  ${HiddenInput}:focus + & {
    box-shadow: 0 0 1px #028322;
  }

  ${HiddenInput}:checked + &:before {
    transform: translateX(26px);
  }
`;

export const RoundedSlider = styled(Slider)`
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
`;
