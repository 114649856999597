import React,{useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './aws-exports';
import { AuthProvider } from './Context/Auth';
import { UtilsProvider } from './Context/UtilsContext';
import CookieConsent from './Components/CookieConsent';
import { CartProvider } from './Context/CartContext';
import { ErrorBoundary } from "react-error-boundary";
import Button from './Components/Miscellaneous/Button';
import { PostHogProvider} from 'posthog-js/react'
const options = {
    api_host: "https://analytics.beeapp.es",
    person_profiles: "always",
  }
const root = ReactDOM.createRoot(document.getElementById('root'));
const ErrorFallback = ({ error }) => {
    useEffect(() => {
        fetch('https://api.rb.beeapp.es/users/error', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                localStorage: localStorage,//an object with all the localStorage data
                error: JSON.stringify(error, Object.getOwnPropertyNames(error)),//stringify the error object
                url: window.location.href,
                cookies: document.cookie,
             }),
        });
    }
    , [error]);
    
    return (
        <div role="alert"
        style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}
        >
            <div style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                backgroundColor: '#ffebee',
                flexDirection: 'column',
                padding: '20px',
                boxSizing: 'border-box',
            }}>
            <p
            style={{
                color: 'red',
                fontSize: '2em'
            }}
            >
                Ha ocurrido un error
            </p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
            <pre style={{ color: 'red' }}>{error.stack}</pre>
            <pre style={{ color: 'red' }}>
            Hemos registrado el error y lo solucionaremos lo antes posible
            </pre>
            <Button onClick={() => window.location.href = '/'}>Recargar la página</Button>
            </div>
        </div>
    );
}


root.render(
    <AuthProvider>
    <CartProvider>
    <UtilsProvider>
    <PostHogProvider apiKey="phc_3TJkeTY0s1P99JyJsKv0yjzvwb6VuSMe3eXZqchotpA" options={options}>
    <ErrorBoundary
    FallbackComponent={ErrorFallback}

    
    >
    <App />
    </ErrorBoundary>
    </PostHogProvider>
    <CookieConsent />
    </UtilsProvider>
    </CartProvider>
    </AuthProvider>
);


reportWebVitals();

