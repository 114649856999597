import styled from 'styled-components';

export const ButtonContainer = styled.button`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '50px'};
    border: ${props => props.outline && !props.item ? '1px solid #028322' : 'none'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : props.outline ? 'transparent' :  '#028322'};
    color: ${props => props.outline ? '#028322' : props.color ? props.color : '#fff'};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '400'};
    outline: none;
    cursor: pointer;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '5px'};
    display: ${props => props.display ? props.display : 'flex'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    padding: ${props => props.padding ? props.padding : 'auto'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    ${props => props.expandMobile ? '@media screen and (max-width: 600px){width: 100%;}' : ''};
    ${props => props.disabled ? 'pointer-events: none; opacity: 0.5;' : ''};
    transition: all 0.125s ease-in-out;
    &:hover{
        background-color: ${props => props.outline && !props.item ? 'transparent' : props.item ? '#ddd' : '#026E1F'};
        ${props => props.boxShadow ? 'box-shadow: 0px 4px 6px -4px rgba(0,0,0,0.25);' : ''};
    }

    ${props => props.overedAnimation ? `
        &:hover{
            transform: scale(0.95);
            background-color: #028322;
            color: #fff;
        }
    ` : ''};

    `;

