import styled from "styled-components";

export const ProfileContainer = styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 20px 70px;
    box-sizing: border-box;
    @media screen and (max-width: 600px){
        flex-direction: column;
        padding: 10px 20px;
    }
    `;

export const ProfileSidebar = styled.div`
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    
    @media screen and (max-width: 600px){
        width: 100%;
        padding: 10px;
    }
`;

export const SidebarItem = styled.a`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    text-decoration: none;
    background-color: ${props => props.active ? '#028322' : '#fff'};
    color: ${props => props.active ? '#fff' : props.color ? props.color : '#028322'};
    font-size: 16px;
    gap: 10px;
    border-radius: ${props => props.active ? '5px' : '0px'};
    font-weight: ${props => props.active ? '600' : '400'};
    &::before{
        content: '';
        width: 5px;
        height: 24px;
        background-color: ${props => props.active ? '#fff' : 'transparent'};
        display: ${props => props.active ? 'block' : 'none'};
        border-radius: 5px;
    }
    ${props => props.notification ? `
    &::after{
        content: '${props.notification}';
        width: 20px;
        height: 20px;
        background-color: #028322;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 800;
        color: #fff;
        background-color: #028322;
        z-index: 0;
    }
    ` : ''};

`;

export const ProfileContent = styled.div`
    width: calc(100vw - 300px);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    @media screen and (max-width: 600px){
        width: 100%;
        padding: 10px;
    }
`;

export const ProfileContentTitle = styled.h2`
    font-size: 30px;
    font-weight: 600;
    color: #028322;
    margin: 0;
    margin-bottom: 10px;
    @media screen and (max-width: 600px){
        font-size: 20px;
    }
`;

export const ProfileContentSubTitle = styled.h3`
    font-size:26px;
    font-weight: 400;
    color: #028322;
    margin: 0;
    margin-bottom: 10px;
    @media screen and (max-width: 600px){
        font-size: 16px;
    }
`;

export const ProfileCardContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: 600px){
        gap: 10px;
        flex-direction: column;
        width: calc(100vw - 60px);
    }
`;

export const InfoBadge = styled.span`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    background-color: #359C4F;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const AddressCard = styled.div`
    width: 100%;
    height: auto;
    max-width: 500px;
    display: flex;
    background-color: #eee;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-radius: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover{
        background-color: #ddd;
    }
    &:active{
        background-color: #ccc;
    }
    transition: all 0.125s ease-in-out;
    cursor: pointer;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }
`;

export const AddressCardContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 600px){
        flex-direction: column;
    }
`;

export const AddressEmpty = styled.div`
    width: 100%;
    height: auto;
    min-height: 70px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 600px){
        flex-direction: column;
    }
`;


export const AddressInput = styled.input`
    width: 50%;
    height: 40px;
    border: none;
    outline: none;
    background-color: #eee;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    @media screen and (max-width: 600px){
        width: 100%;
    }
`;

export const AddressInputLabel = styled.label`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
`;


export const Field = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 5px;
    @media screen and (max-width: 600px){
        flex-direction: column;
        gap: 10px;
    }
`;

export const AddressCardName = styled.span`
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const AddressCardAddress = styled.span`
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    color: #000;
    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const AddressCardText = styled.span`
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const OrderCard = styled.div`
    width: 100%;
    height: auto;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #028322;
    border-radius: 5px;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }
    margin-top: 10px;

`;

export const OrderCardTitle = styled.span`
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: 600;
    color: #028322;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const OrderCardItem = styled.div`
    width: 100%;
    max-width: 550px;
    height: 100px;
    display: flex;
    flex-direction: row;
    border: 2px solid #028322;
    padding: 10px;
    padding-right: 0px;
    box-sizing: border-box;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const OrderCardQuantity = styled.div`
    height: 100px;
    width: 100px;
    background-color: #028322;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;


export const SupportChat = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
    box-sizing: border-box;
    background-color: #f6f6f6;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }
    margin-top: 10px;
`;


export const SupportChatHeader = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #028322;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }

    & > h2 {
        font-size: 20px;
        font-weight: 600;
        color: #028322;
        margin: 0;
        @media screen and (max-width: 600px){
            font-size: 14px;
        }
    }
`;

export const SupportChatSend = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0px;
    box-sizing: border-box;
    border-top: 0px solid #028322;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }
`;

export const SupportChatSendInput = styled.input`
    width: calc(100% - 50px);
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    @media screen and (max-width: 600px){
        width: 100%;
    }
`;

export const SupportChatSendButton = styled.button`
    width: 50px;
    height: 100%;
    border: none;
    outline: none;
    background-color: #028322;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    @media screen and (max-width: 600px){
        width: 100%;
    }
`;

export const SupportChatContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #f6f6f6;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }
`;

export const SupportChatMessage = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }

    ${({ me }) => !me ? `
    background-color: #028322;

    & > span {
        color: #fff;
    }
    ` : `
    background-color: #fff;
    `}
    ${props => props.outline ? `
    background-color: #f6f6f6;
    border: 0px solid #028322;
    & > span {
        color: #028322;
    }
    ` : ''}
`;

export const ProfileIVASelector = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #028322;
    border-radius: 5px;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }
`;

export const ProfileIVASelectorItem = styled.div`
    width: calc(100%/4);
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #028322;
    border-radius: 5px;
    cursor: pointer;
    color: #028322;
    &:hover{
        background-color: #028322;
        color: #fff;
    }
    ${({ active }) => active ? `
    background-color: #028322;
    color: #fff;
    ` : ''}
    transition: all 0.125s ease-in-out;
    @media screen and (max-width: 600px){
        font-size: 14px;
        max-width: 100%;
    }
`;