import React, { useEffect, useMemo, useState } from 'react';
import { CardContainer, UsersTable, UsersTableBody, UsersTableBodyColumn, UsersTableHead, UsersTableHeadColumn, UsersTableRow } from './style';
import Card from '../../../Components/Miscellaneous/Card';
import axios from 'axios';
import AuthService from '../../../AuthService';
import Zoom from 'react-medium-image-zoom'

import { BiPlus } from 'react-icons/bi';
import { PaginationButton, PaginationContainer } from '../../Categories/style';
import { Input, Loader } from '../../Login/style';
import Button from '../../../Components/Miscellaneous/Button';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';

export default function Stocks(){

    const [total, setTotal] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [navigateTo, setNavigateTo] = useState(null);
    const [modalOpened, setModalOpened] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchData(1); // Fetch data for the first page when component mounts
    }, []);

    // Function to fetch data for a given page number
    const fetchData = (pageNumber) => {
        setLoading(true);
        axios.get(`https://api.rb.beeapp.es/admin/stocks?page=${pageNumber}`, {
            headers: {
                Authorization: 'Bearer ' + AuthService.getToken()
            }
        })
        .then(response => {
            setLoading(false);
            const data = response.data;
            //order data by stock
            data.sort((a, b) => {
                return a.stock - b.stock;
            });
            console.log(data);
            setProducts(data);

        })
        .catch(error => {
            console.error(error);
        });
    };

    // Function to handle pagination button click
    return (
        <>
        {
            loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 100,
                }}>
                    <div style={{
                        backgroundColor: '#fff',
                        padding: '20px',
                        borderRadius: '5px',
                        width: 'auto',
                        textAlign: 'center',
                    }}>
                        <Loader />
                    </div>
                </div>
            )
        }

        <div style={{
            padding: '20px',
        }}>
                <UsersTable>
                    <UsersTableHead>
                        <UsersTableHeadColumn></UsersTableHeadColumn>
                        <UsersTableHeadColumn>Producto</UsersTableHeadColumn>
                        <UsersTableHeadColumn>Stock</UsersTableHeadColumn>
                        <UsersTableHeadColumn>Precio</UsersTableHeadColumn>
                        <UsersTableHeadColumn>Acciones</UsersTableHeadColumn>
                    </UsersTableHead>
                    <UsersTableBody>
                        {
                            products.length !== 0 && products.map((product, index) => {
                                return (
                                    <UsersTableRow key={index}
                                        
                                    >
                                        <UsersTableBodyColumn>
                                            <Zoom>
                                            {
                                            <img src={product.images[0]} alt={product.name} style={{
                                                width: '50px',
                                                height: '50px',
                                                objectFit: 'contain',
                                                borderRadius: '5px',
                                                marginRight: '10px',
                                            }}
                                            loading='lazy'
                                            />
                                            }
                                            </Zoom>
                                            </UsersTableBodyColumn>
                                        <UsersTableBodyColumn
                                            onClick={() => {
                                                window.location.href = '/admin/product-edit/' + product._id;
                                            }
                                            }
                                        >{product.name}</UsersTableBodyColumn>
                                        <UsersTableBodyColumn>{product.stock}</UsersTableBodyColumn>
                                        <UsersTableBodyColumn>
                                            <CurrencyFormat value={product.price} displayType={'text'} thousandSeparator={false} suffix={'€'}
                                                decimalSeparator=','
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </UsersTableBodyColumn>
                                        <UsersTableBodyColumn>
                                            <button
                                                onClick={() => {
                                                    window.location.href = '/admin/product-edit/' + product._id;
                                                }
                                                }
                                            >Editar</button>
                                        </UsersTableBodyColumn>
                                    </UsersTableRow>
                                )
                            })
                        }
                    </UsersTableBody>
                </UsersTable>
                        {
                            modalOpened && (
                                <div style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>

                                    <div style={{
                                        backgroundColor: '#fff',
                                        padding: '20px',
                                        borderRadius: '5px',
                                        width: '300px',
                                    }}>
                                        <span style={{
                                                                                    height: '20px',
                                                                                    width: '20px',
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    fontSize: '1rem',
                                        position: 'relative',
                                        top: '-50px',
                                        right: '50px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                        padding: '5px',
                                        borderRadius: '50%',
                                        backgroundColor: '#fff',
                                    }} onClick={() => setModalOpened(false)}>&times;</span>
                                        <Input type="text" placeholder="Introduce el número de la página" style={{width: '100%'}}
                                        onChange={(e) => setNavigateTo(e.target.value)}
                                        />
                                        <Button width="100%" style={{marginTop: '10px'}}
                                            onClick={() => {
                                                if (navigateTo > 0 && navigateTo <= totalPages) {
                                                    setModalOpened(false);
                                                }else{
                                                    toast.error('Número de página inválido');
                                                }
                                            }}
                                        >Ir a la página</Button>
                                    </div>
                                </div>
                            )
                        }
        </div>
        </>

    )
}