import { Loader } from "../../../Screens/Login/style";
import { ButtonContainer } from "./style";

export default function Button(props){
    return (
        <ButtonContainer {...props}>
            {
                props.loading ? <Loader /> : props.children
            }
        </ButtonContainer>
    );
}