import isMobile from "../../Components/Miscellaneous/Utils/isMobile";
import { BarcodeScanner } from "./scanner";

export default function ScannerScreen(){
    return (
        <div>
            {
                isMobile() ? (
                    <BarcodeScanner/>
                ) : (
                    null
                )
            }
        </div>
    );
}