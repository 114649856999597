import React, {useEffect, useState} from 'react';
import { H1, UserContainer, UsersTable, UsersTableBody, UsersTableHead, UsersTableHeadColumn,UsersTableBodyColumn, UsersTableRow, Pill, OrderContainer, OrderName, OrderDate, CardsContainer, CardH2, CardInfo } from './style';
import axios from 'axios';
import AuthService from '../../../AuthService';
import Button from '../../../Components/Miscellaneous/Button';
import { FiEdit } from "react-icons/fi";
import { PaginationButton, PaginationContainer } from '../../Categories/style';
import Modal from '../../../Components/Miscellaneous/Modal';
import { ChartComponent } from './chartComponent';
import { formatDate, formatDateTime } from '../../../Utils/Locale/locale';
import Card from '../../../Components/Miscellaneous/Card';
import { MdOutlineLocalShipping, MdOutlinePending } from 'react-icons/md';


export default function Orders() {

    const [orders, setOrders] = useState([]);
    const [ordersStatistics, setOrdersStatistics] = useState([]);



    useEffect(() => {
        axios.get('https://api.rb.beeapp.es/admin/orders', {
            headers: {
                'Authorization': 'Bearer ' + AuthService.getToken()
            }
        }).then(response => {
            return response.data;
        }
        )
        .then(data => {
            //order by CreationDate
            data.results.sort((a, b) => {
                return new Date(b.CreationDate) - new Date(a.CreationDate);
            });

            setOrders(data.results);
        }
        )
        .catch(error => {
            console.error(error);
        });

        axios.get('https://api.rb.beeapp.es/admin/orders/statistics', {
            headers: {
                'Authorization': 'Bearer ' + AuthService.getToken()
            }
        }).then(response => {
            return response.data;
        }
        )
        .then(data => {
            console.log(data);
        }
        )
        .catch(error => {
            console.error(error);
        });
        axios.get('https://api.rb.beeapp.es/admin/orders/statistics/status', {
            headers: {
                'Authorization': 'Bearer ' + AuthService.getToken()
            }
        }).then(response => {
            return response.data;
        }
        )
        .then(data => {
            setOrdersStatistics(data);
        }
        )
        .catch(error => {
            console.error(error);
        });
    }, []);
    return (
        <UserContainer>
            <H1>Pedidos</H1>
            <CardsContainer>
                <Card xs>
                    <CardH2>Pedidos Totales</CardH2>
                    <CardInfo>{
                            ordersStatistics?.Total
                        }</CardInfo>
                </Card>
                <Card xs>
                    <CardH2>Pedidos Pendientes</CardH2>
                    <CardInfo>{
                        ordersStatistics?.Created ?? 0
                        }</CardInfo>

                </Card>
                <Card xs>
                    <CardH2>Pedidos Enviados</CardH2>
                    <CardInfo>{
                        (ordersStatistics?.Shipped ?? 0) + (ordersStatistics?.Delivered ?? 0)
                        }</CardInfo>

                </Card>
                <Card xs>
                    <CardH2>Pedidos Cancelados</CardH2>
                    <CardInfo>{
                        (ordersStatistics?.Cancelled ?? 0)
                        }</CardInfo>

                </Card>
            </CardsContainer>
            {
                orders.length > 0 && orders.map((order, index) => {
                    return (
                    <OrderContainer
                        key={index}
                        onClick={() =>{
                            window.location.href = `/admin/order/${order._id}`;
                        }}
                    >
                        <div style={{display: 'flex', gap: '10px', flexDirection: 'column', marginRight: 'auto'}}>
                        <OrderName>{order.UserID?.Name} {order.UserID?.Surname}</OrderName>
                        <OrderDate>{order.CreationDate}</OrderDate>
                        <OrderDate>{String(order.TotalAmount.toFixed(3)).replace('.',',')}€</OrderDate>
                        </div>
                        {
                            order.Status === 'Created' ? <MdOutlinePending size={24} color={'#FFA500'}/> : order.Status === 'Sent' ? <MdOutlineLocalShipping size={24} color={'#028322'}/> : <MdOutlineLocalShipping size={24} color={'#028322'}/>
                        }
                    </OrderContainer>
                    )
                })
            }
        </UserContainer>
    )
}