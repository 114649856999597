export const Aviso = `
# Términos y Condiciones de DistribucionesRB

Bienvenido a DistribucionesRB, operada por Distribuciones RBPapel S.L. Antes de utilizar nuestros servicios, por favor, lea detenidamente los siguientes términos y condiciones. Al acceder y utilizar nuestro sitio web, usted acepta cumplir con estos términos y condiciones en su totalidad. Si no está de acuerdo con alguno de estos términos, por favor, absténgase de utilizar nuestros servicios.

## Uso del Sitio Web:
- DistribucionesRB es un ecommerce B2B (Business to Business), exclusivo para uso comercial entre empresas. No está destinado al uso por parte de consumidores finales.
- Usted acepta utilizar este sitio web únicamente para fines legales y de acuerdo con estos términos y condiciones, así como con todas las leyes y regulaciones aplicables.

## Registro y Cuenta:
- Para acceder a ciertas áreas y funciones del sitio web, puede ser necesario que se registre y cree una cuenta.
- Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, y acepta asumir la responsabilidad de todas las actividades que ocurran bajo su cuenta.

## Pedidos y Transacciones:
- Al realizar un pedido a través de DistribucionesRB, usted está realizando una oferta para comprar productos sujetos a estos términos y condiciones.
- Nos reservamos el derecho de rechazar o cancelar pedidos en cualquier momento por cualquier razón.
- Los precios de los productos están sujetos a cambios sin previo aviso.

## Envío y Entrega:
- Los plazos de entrega proporcionados son estimaciones y no garantizamos la entrega en un plazo específico.
- El riesgo de pérdida y daño de los productos se transfiere a usted en el momento en que los productos son entregados a la dirección de envío especificada.

## Devoluciones y Reembolsos:
- Las devoluciones están sujetas a nuestra política de devoluciones, la cual puede estar sujeta a restricciones y condiciones específicas.
- Los reembolsos se procesarán de acuerdo con nuestra política de reembolsos, la cual puede estar sujeta a condiciones específicas.

## Propiedad Intelectual:
- Todo el contenido incluido en este sitio web, como texto, gráficos, logotipos, imágenes, videos y software, está protegido por las leyes de propiedad intelectual y es propiedad de Distribuciones RBPapel S.L. o de sus licenciantes.

## Privacidad y Seguridad:
- DistribucionesRB cumple con una estricta política de privacidad. Los servicios están alojados en AWS (Amazon Web Services), lo que garantiza altos estándares de seguridad y protección de datos.

## Limitación de Responsabilidad:
- En ningún caso Distribuciones RBPapel S.L., sus directores, empleados o afiliados serán responsables por daños directos, indirectos, incidentales, especiales o consecuentes que surjan del uso de este sitio web o de la compra de productos a través de él.

## Modificaciones:
- Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en el sitio web.

## Ley Aplicable:
- Estos términos y condiciones se rigen por las leyes de España. Cualquier disputa relacionada con estos términos y condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de España.

Al utilizar los servicios ofrecidos por DistribucionesRB, usted acepta estos términos y condiciones en su totalidad. Si no está de acuerdo con ellos, por favor, no utilice nuestro sitio web. Si tiene alguna pregunta o inquietud, no dude en ponerse en contacto con nosotros.
`;

export const PoliticaDePrivacidad = `
# Política de Privacidad de DistribucionesRB

DistribucionesRB, operada por Distribuciones RBPapel S.L., se compromete a proteger y respetar su privacidad. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que usted nos proporciona o que recopilamos cuando utiliza nuestro sitio web.

## Información que Recopilamos

Cuando utiliza nuestro sitio web, podemos recopilar y procesar la siguiente información personal sobre usted:

- Información de contacto, como nombre, dirección de correo electrónico, dirección postal y número de teléfono.
- Información de la cuenta, como nombre de usuario y contraseña.
- Información de transacciones, incluidos detalles sobre los productos que ha adquirido.
- Información técnica, como la dirección IP, tipo de dispositivo, navegador web, sistema operativo y otros datos similares sobre su interacción con nuestro sitio web.
- Información de cookies, que recopilamos mediante el uso de cookies y tecnologías similares. Consulte nuestra política de cookies para obtener más información.

## Uso de la Información

Utilizamos la información personal que recopilamos para los siguientes fines:

- Procesar y completar sus pedidos y transacciones.
- Gestionar su cuenta y proporcionarle soporte al cliente.
- Personalizar y mejorar su experiencia en nuestro sitio web.
- Enviarle comunicaciones de marketing y promociones relacionadas con nuestros productos y servicios, siempre que haya dado su consentimiento para recibir dichas comunicaciones.
- Cumplir con nuestras obligaciones legales y regulatorias.

## Compartir Información

No compartimos su información personal con terceros, excepto en las siguientes circunstancias:

- Con proveedores de servicios que nos ayudan a operar nuestro negocio y prestar servicios, como procesadores de pagos, empresas de logística y proveedores de servicios de tecnología de la información.
- Cuando estemos obligados a hacerlo por ley, en respuesta a solicitudes legales válidas o en cumplimiento de una orden judicial.
- En caso de fusión, adquisición o venta de activos, en cuyo caso transferiremos su información personal al adquirente o sucesor legal.
- Con su consentimiento explícito.

## Protección de la Información

Tomamos medidas para proteger la seguridad de su información personal y para garantizar que se utilice de manera adecuada y segura. Sin embargo, la transmisión de información a través de Internet nunca es completamente segura, por lo que no podemos garantizar la seguridad absoluta de su información.

## Sus Derechos

Usted tiene ciertos derechos con respecto a su información personal, incluido el derecho a acceder, corregir, actualizar o eliminar su información personal. Si desea ejercer alguno de estos derechos, comuníquese con nosotros utilizando los datos de contacto que se proporcionan a continuación.

## Cambios en la Política de Privacidad

Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Cualquier cambio entrará en vigor inmediatamente después de su publicación en nuestro sitio web.

## Contacto

Si tiene alguna pregunta sobre nuestra política de privacidad o sobre cómo tratamos su información personal, no dude en ponerse en contacto con nosotros:

Distribuciones RBPapel S.L.  
Dirección: C/ Río Guadalquivir, 11, 41760 El Coronil, Sevilla, España
Teléfono: +34 
Correo electrónico: info@distribucionesrb.com  

Fecha de entrada en vigencia: [Fecha]
`;

export const SobreNosotros = `
# Sobre Nosotros

Bienvenido a DistribucionesRB, su proveedor confiable de productos de papelería y suministros de oficina. En Distribuciones RBPapel S.L., nos enorgullece ofrecer una amplia gama de productos de calidad a precios competitivos, satisfaciendo las necesidades de empresas y profesionales de todos los tamaños.

## Nuestra Empresa

Distribuciones RBPapel S.L. es una empresa líder en el sector de distribución mayorista de productos de papelería, con sede en El Coronil. Desde nuestros inicios, nos hemos comprometido a proporcionar a nuestros clientes una experiencia de compra excepcional, ofreciendo un servicio personalizado y productos de alta calidad.

## Nuestros Productos

En DistribucionesRB, entendemos la importancia de contar con los suministros adecuados para mantener su oficina funcionando sin problemas. Por eso, ofrecemos una amplia selección de productos de papelería, que incluyen:

- Papel y cartulina
- Bolígrafos, lápices y marcadores
- Archivadores y carpetas
- Material de escritorio
- Sobres y etiquetas
- Y mucho más

Trabajamos con los principales fabricantes y proveedores para garantizar que nuestros clientes tengan acceso a los productos más recientes y de la más alta calidad.

## Nuestro Compromiso

En DistribucionesRB, nos esforzamos por brindar un servicio excepcional a nuestros clientes. Nuestro equipo dedicado está aquí para ayudarlo en cada paso del proceso, desde la selección de productos hasta la entrega final. Valoramos la satisfacción del cliente por encima de todo y nos esforzamos por superar sus expectativas en todo momento.

## Contacto

Si tiene alguna pregunta sobre nuestros productos o servicios, no dude en ponerse en contacto con nuestro equipo. Estamos aquí para ayudarlo y esperamos poder servirle en sus necesidades de suministros de oficina.

¡Gracias por elegir DistribucionesRB!
`;

export const EnviosMD = `
# Envío y Devoluciones

En DistribucionesRB nos esforzamos por garantizar una experiencia de compra satisfactoria para nuestros clientes, incluyendo un proceso de envío eficiente y una política de devoluciones clara y justa.

## Envío

Realizamos envíos de pedidos en un plazo máximo de 48 horas desde la confirmación del pedido, excepto en casos donde el pedido se realice en días festivos o fin de semana. En condiciones normales, los pedidos se entregan al día siguiente de su realización. 

## Devoluciones

Nuestra política de devoluciones está diseñada para ofrecerle tranquilidad y satisfacción. Aceptamos devoluciones de productos en un plazo de 14 días a partir de la fecha de entrega, siempre que los productos estén en su estado original y sin usar. Para iniciar el proceso de devolución, por favor contáctenos y estaremos encantados de ayudarlo.

## Proceso de Devolución

1. Contacte con nuestro equipo de atención al cliente para informar sobre su intención de devolver un producto.
2. Embale el producto de forma segura en su embalaje original.
3. Adjunte una copia de la factura original o el comprobante de compra dentro del paquete.
4. Envíe el paquete de vuelta a la dirección que se le proporcionará.
5. Una vez recibido el producto devuelto y comprobado su estado, procederemos al reembolso del importe correspondiente.

## Costos de Envío de Devolución

Los costos de envío de devolución correrán a cargo del cliente, a menos que la devolución sea resultado de un error por parte de DistribucionesRB o del estado defectuoso del producto recibido.

## Contacto

Para cualquier consulta relacionada con el envío, devolución o cualquier otro tema, no dude en ponerse en contacto con nuestro equipo de atención al cliente. Estaremos encantados de ayudarlo.

¡Gracias por confiar en DistribucionesRB!

`;