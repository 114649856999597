import styled from "styled-components";
import { useState } from 'react';
export const CategoriesContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    @media screen and (max-width: 600px) {
        padding: 10px;
    }
    `;

export const CategoriesFilter = styled.div`
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;

    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 0px;
    }
`;

export const CategoriesFilterTitle = styled.span`
    width: 100%;
    height: auto;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

export const CategoriesFilterMaxMin = styled.input`
    width: 20%;
    max-width: 100px;
    height: 30px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 3px;
    font-size: 1rem;
    outline: none;
    transition: all 0.1s ease-in-out;
    &:focus {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border: 0px solid #028322;
    }
`;

export const CategoriesFilterSelector = styled.div`
    width: 100%;
    height: ${props => props.open ? "auto" : "200px"};
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
`;

export const CategoriesFilterSelectorItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
`;

export const CategoriesFilterSelectorItemText = styled.label`
    width: auto;
    height: auto;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
`;

export const CategoriesFilterSelectorItemCheckbox = styled.input`
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    background-color: #028322;
    accent-color: #028322;
    border-radius: 0px;
    padding: 3px;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    transition: all 0.1s ease-in-out;
    &:focus {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border: 0px solid #028322;
    }

`;

export const CategoriesFilterSelectorItemExpand = styled.span`
    font-size: 1rem;
    font-weight: 600;
    color: #028322;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
        color: #000;
    }
`;

export const PaginationContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0px;
    padding: 10px;
    box-sizing: border-box;
`;

export const PaginationButton = styled.button`
    width: 30px;
    height: 30px;
    padding: 5px;
    border: none;
    background-color: #727272;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 0px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
        background-color: #000;
    }
    ${props => props.active && `
        background-color: #028322;
    `}
`;

export const PaginationButtonDisabled = styled.button`
    width: 30px;
    height: 30px;
    padding: 5px;
    border: none;
    background-color: #ddd;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 0px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
        background-color: #ddd;
    }
`;

export const ProductsContainer = styled.div`
    width: calc(100% - 261px);
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    padding-right: 0px;
    box-sizing: border-box;
    @media screen and (max-width: 600px) {
        padding: 10px;
        width: 100%;
    }
`;

export const ProductCard = styled.a`
    width: 250px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    }
    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

 const ProductCardImageComponent = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
`;

export const ProductCardImage = ({ src,onClick }) => {
    const [imageLoadError, setImageLoadError] = useState(false);
    
    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <ProductCardImageComponent 
            src={imageLoadError ? 'https://cdn.beeapp.es/distribucionesrb/NoImage.png' : src} 
            onError={handleImageError} 
            onClick={onClick}
            loading="lazy"
        />
    );
}

export const ProductCardTitle = styled.span`
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

export const ProductCardDiscounts = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: row;
gap: 10px;
padding: 5px;
box-sizing: border-box;
background-color: #028322;
border-radius: 15px;
`;

export const ProductCardDiscountsBar = styled.div`
width: ${props => props.discount ? props.discount : "0"}%;
height: 5px;
background-color: #fff;
border-radius: 5px;
`;


export const ProductCardPrice = styled.span`
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
`;


