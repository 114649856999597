import React, { useEffect, useMemo, useState } from 'react';
import { CardContainer, UsersTable, UsersTableBody, UsersTableBodyColumn, UsersTableHead, UsersTableHeadColumn, UsersTableRow } from './style';
import Card from '../../../Components/Miscellaneous/Card';
import axios from 'axios';
import AuthService from '../../../AuthService';
import Zoom from 'react-medium-image-zoom'

import { BiPlus } from 'react-icons/bi';
import { PaginationButton, PaginationContainer } from '../../Categories/style';
import { Input, Loader } from '../../Login/style';
import Button from '../../../Components/Miscellaneous/Button';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';

export default function Panel(){

    const [total, setTotal] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [navigateTo, setNavigateTo] = useState(null);
    const [modalOpened, setModalOpened] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchData(1); // Fetch data for the first page when component mounts
    }, []);

    // Function to fetch data for a given page number
    const fetchData = (pageNumber) => {
        setLoading(true);
        axios.get(`https://api.rb.beeapp.es/admin/products?page=${pageNumber}`, {
            headers: {
                Authorization: 'Bearer ' + AuthService.getToken()
            }
        })
        .then(response => {
            const data = response.data;
            setProducts(data.results);
            setTotalPages(data.totalPages);
            setCurrentPage(pageNumber);
            setLoading(false);
        })
        .catch(error => {
            console.error(error);
        });
    };

    // Function to handle pagination button click
    const handlePageClick = (pageNumber) => {
        if (pageNumber === '...'){
            setModalOpened(true);
            return;
        }
        fetchData(pageNumber);
    };

    const deleteProduct = (id) => {
        //use an alert to confirm the deletion

        const confirm = window.confirm('¿Estás seguro de que quieres eliminar este producto?');

        if (!confirm) {
            return;
        }else{
        axios.delete(`https://api.rb.beeapp.es/admin/product/${id}`, {
            headers: {
                'Authorization': `Bearer ${AuthService.getToken()}`,
            }
        })
        .then(response => {
            toast.success('Producto eliminado correctamente');
            fetchData(currentPage);
        })
        .catch(error => {
            console.error(error);
        });
        }
    }

    // Pagination component
    const Pagination = () => {
        const maxPagesToShow = 3; // Number of pages to show before and after the current page
        let startPage = Math.max(1, currentPage - maxPagesToShow);
        let endPage = Math.min(totalPages, currentPage + maxPagesToShow);

        // Adjust startPage and endPage if they are close to the beginning or end
        if (startPage === 1) {
            endPage = Math.min(totalPages, startPage + maxPagesToShow * 2);
        } else if (endPage === totalPages) {
            startPage = Math.max(1, endPage - maxPagesToShow * 2);
        }

        const pages = [];
        // Add ellipsis if there are pages before the displayed ones
        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push('...');
            }
        }

        // Add displayed page numbers
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Add ellipsis if there are pages after the displayed ones
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push('...');
            }
            pages.push(totalPages);
        }

        return (
            <div>
                {pages.map((page, index) => (
                    <PaginationButton 
                        key={index} 
                        onClick={() => handlePageClick(page)} 
                        style={{ fontWeight: currentPage === page ? 'bold' : 'normal', width: 'auto', minWidth: 25 }}
                    >
                        {page}
                    </PaginationButton>
                ))}
            </div>
        );
    };

    return (
        <>
        {
            loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 100,
                }}>
                    <div style={{
                        backgroundColor: '#fff',
                        padding: '20px',
                        borderRadius: '5px',
                        width: 'auto',
                        textAlign: 'center',
                    }}>
                        <Loader />
                    </div>
                </div>
            )
        }
        <CardContainer>
            <Card sm
                customStyle={{
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                }}
                onClick={() => {
                    window.location.href = '/admin/product-create';
                }
                }
            >
                <BiPlus style={{fontSize: '2rem'}}/>
                <span style={{fontSize: '1rem', textAlign: 'center'}}>Crear Producto</span>

            </Card>
            <Card sm
                customStyle={{
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                }}
                onClick={() => {
                    window.location.href = '/admin/product-create';
                }
                }
            >
                <BiPlus style={{fontSize: '2rem'}}/>
                <span style={{fontSize: '1rem', textAlign: 'center'}}>Crear Catálogo</span>

            </Card>
            <Card sm
                customStyle={{
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}
                onClick={() => {
                    window.location.href = '/admin/stock';
                }
                }
            >
                <BiPlus style={{fontSize: '2rem'}}/>
                <span style={{fontSize: '1rem', textAlign: 'center'}}>Ver productos sin Stock</span>
            </Card>
        </CardContainer>
        <div style={{
            padding: '20px',
        }}>
                <UsersTable>
                    <UsersTableHead>
                        <UsersTableHeadColumn></UsersTableHeadColumn>
                        <UsersTableHeadColumn>Producto</UsersTableHeadColumn>
                        <UsersTableHeadColumn>Stock</UsersTableHeadColumn>
                        <UsersTableHeadColumn>Precio</UsersTableHeadColumn>
                        <UsersTableHeadColumn>Acciones</UsersTableHeadColumn>
                    </UsersTableHead>
                    <UsersTableBody>
                        {
                            products.length !== 0 && products.map((product, index) => {
                                return (
                                    <UsersTableRow key={index}
                                        
                                    >
                                        <UsersTableBodyColumn>
                                            <Zoom>
                                            {
                                            <img src={product.images[0]} alt={product.name} style={{
                                                width: '50px',
                                                height: '50px',
                                                objectFit: 'contain',
                                                borderRadius: '5px',
                                                marginRight: '10px',
                                            }}/>
                                            }
                                            </Zoom>
                                            </UsersTableBodyColumn>
                                        <UsersTableBodyColumn
                                            onClick={() => {
                                                window.location.href = '/admin/product-edit/' + product._id;
                                            }
                                            }
                                        >{product.name}</UsersTableBodyColumn>
                                        <UsersTableBodyColumn>{product.stock}</UsersTableBodyColumn>
                                        <UsersTableBodyColumn>
                                            <CurrencyFormat value={product.price} displayType={'text'} thousandSeparator={false} suffix={'€'}
                                                decimalSeparator=','
                                                //max decimal 2
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </UsersTableBodyColumn>
                                        <UsersTableBodyColumn>
                                            <button
                                                onClick={() => {
                                                    window.location.href = '/admin/product-edit/' + product._id;
                                                }
                                                }
                                            >Editar</button>
                                        </UsersTableBodyColumn>
                                    </UsersTableRow>
                                )
                            })
                        }
                    </UsersTableBody>
                </UsersTable>
                        {
                            <PaginationContainer>
                                <Pagination />
                            </PaginationContainer>
                        }
                        {
                            modalOpened && (
                                <div style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>

                                    <div style={{
                                        backgroundColor: '#fff',
                                        padding: '20px',
                                        borderRadius: '5px',
                                        width: '300px',
                                    }}>
                                        <span style={{
                                                                                    height: '20px',
                                                                                    width: '20px',
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    fontSize: '1rem',
                                        position: 'relative',
                                        top: '-50px',
                                        right: '50px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                        padding: '5px',
                                        borderRadius: '50%',
                                        backgroundColor: '#fff',
                                    }} onClick={() => setModalOpened(false)}>&times;</span>
                                        <Input type="text" placeholder="Introduce el número de la página" style={{width: '100%'}}
                                        onChange={(e) => setNavigateTo(e.target.value)}
                                        />
                                        <Button width="100%" style={{marginTop: '10px'}}
                                            onClick={() => {
                                                if (navigateTo > 0 && navigateTo <= totalPages) {
                                                    handlePageClick(navigateTo);
                                                    setModalOpened(false);
                                                }else{
                                                    toast.error('Número de página inválido');
                                                }
                                            }}
                                        >Ir a la página</Button>
                                    </div>
                                </div>
                            )
                        }
        </div>
        </>

    )
}