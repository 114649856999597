import React from "react";
import { ProfileContainer, ProfileContent, ProfileContentTitle, ProfileSidebar, SidebarItem } from "./style";
//user icon
import {AiOutlineUser, AiOutlineMail} from 'react-icons/ai';
import {BsBoxSeam} from 'react-icons/bs';
import {FaRegAddressCard} from 'react-icons/fa';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useAuth } from "../../Context/Auth";
import {BiTrash} from 'react-icons/bi';
//get props from router
import { useParams } from "react-router-dom";
import Address from "./address";
import Profile from "./profile";
import DropShipping from "./dropshipping";
import Orders from "./orders";
import Support from "./support";
export default function ProfileScreen() {

    const { user } = useAuth();
    const { tab } = useParams();
    return (
        <ProfileContainer>
            <ProfileSidebar>
                <SidebarItem href="/me/profile" active={
                    tab === undefined || tab === 'profile' ? true : false
                }>
                    <AiOutlineUser size={20}/>
                    Mi Perfil</SidebarItem>
                    <SidebarItem href="/me/orders" active={
                    tab === undefined || tab === 'orders' ? true : false
                }>
                    <BsBoxSeam size={20}/>
                    Mis Pedidos</SidebarItem>
                    <SidebarItem href="/me/address" active={
                    tab === undefined || tab === 'address' ? true : false
                }>
                    <HiOutlineLocationMarker size={20}/>
                    Mis Direcciones</SidebarItem>
                    <SidebarItem href="/me/dropshipping" active={
                    tab === undefined || tab === 'dropshipping' ? true : false
                }>
                    <FaRegAddressCard size={20}/>
                    DropShipping</SidebarItem>
                    <SidebarItem href="/me/help" active={
                    tab === undefined || tab === 'help' ? true : false
                } notification={1}>
                    <AiOutlineMail size={20}/>
                    Atención al cliente</SidebarItem>   
                    <SidebarItem href="/me/help" color="#FF0000" active={
                    tab === undefined || tab === 'delete' ? true : false
                }>
                    <BiTrash size={20} color={tab === undefined || tab === 'delete' ? '#fff' : '#FF0000'}/>
                    Eliminar Cuenta</SidebarItem>        
            </ProfileSidebar>
            <ProfileContent>
                    {
                        tab === undefined || tab === 'profile' ? <Profile/> :
                        tab === 'orders' ? <Orders/> :
                        tab === 'address' ? <Address/> :
                        tab === 'dropshipping' ? <DropShipping/> :
                        tab === 'help' ? <Support/> : ''
                    }
            </ProfileContent>
        </ProfileContainer>
    )

}