import React, { useState, useEffect, useRef } from 'react';
import { SearchInput } from './style';
function InputWithAbortController({ onSearch, onFocus }) {

  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    setSearchTerm(localStorage.getItem('search'));
    const term = localStorage.getItem('search');
    if (term) {
      onSearch(term);
    }
    return () => {
      // Cleanup function to abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    localStorage.setItem('search', event.target.value);
    // Abort any pending requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new abort controller
    const newAbortController = new AbortController();

    abortControllerRef.current = newAbortController;
    // Call onSearch with new search term and abort controller

    setSearching(true);
    onSearch(event.target.value, newAbortController.signal)
      .then(() => {
        setSearching(false);
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error(error);
          setSearching(false);
        }
      });
  };
  return (
      <SearchInput type="text" value={searchTerm} onChange={handleInputChange} 
        onFocus={onFocus}
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='none'
        spellCheck='false'
        placeholder='Buscar productos y usuarios...'
      />
  );
}

export default InputWithAbortController;