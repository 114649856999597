import React from "react";
import Header from "../Header";
import Panel from "./Panel";
import { useParams } from "react-router-dom";
import Users from "./users";
import Orders from "./orders";
import ProductCreator from "./productCreator";
import Order from "./order";
import ProductEdit from "./productedit";
import UserScreen from "./user";
import HomeEditor from "./homeeditor";
import Stocks from "./stocks";
export default function DashBoard() {
    // Get the routes from the router
    const { tab } = useParams();

    let componentToRender;

    switch (tab) {
        case 'users':
            componentToRender = <Users />;
            break;
        case 'sells':
            componentToRender = <Orders />;
            break;
        case 'product-create':
            componentToRender = <ProductCreator />;
            break;
        case 'product-edit':
            componentToRender = <ProductEdit />;
            break;
        case 'order':
            componentToRender = <Order />;
            break;
        case 'user-edit':
            componentToRender = <UserScreen />;
            break;
        case 'home-editor':
            componentToRender = <HomeEditor/>;
            break;
        case 'stock':
            componentToRender = <Stocks />;
            break;
        default:
            componentToRender = <Panel />;
    }

    return (
        <>
            <Header />
            {componentToRender}
        </>
    );
}
