import styled from 'styled-components';


export const SlidingCartContainer = styled.div`
    width: 350px;
    height: calc(100vh - 30px);
    background-color: #fff;
    position: fixed;
    top: 0;
    margin: 10px;
    padding: 20px;
    box-sizing: border-box;
    right: 0;
    z-index: 100;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    transition: all 0.3s ease-in-out;
    transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
    @media screen and (max-width: 600px){
        width: 100vw;
    }
`;

export const SlidingCartBackground = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    
    opacity: 1;
    visibility: visible;
`;
