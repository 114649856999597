import React from "react";
import { useEffect, useState } from "react";
import { Input } from "./productCreator/style";
import { CategoriesFilterSelectorItemCheckbox } from "../../Categories/style";
import { Label } from "../../Login/style";
import { OfferItemAddToCartSelect } from "../../Home/style";
import Button from "../../../Components/Miscellaneous/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import AuthService from "../../../AuthService";
export default function UserScreen() {
  // Scroll to top
  const {id} = useParams();
  const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [cif, setCif] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [equivalenceSurcharge, setEquivalenceSurcharge] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [loader, setLoader] = useState(false);

    function deleteUser() {

      //Show a confirmation dialog
      if (!window.confirm("¿Estás seguro de que quieres eliminar este usuario?")) {
          return;
      }

        axios.delete(`https://api.rb.beeapp.es/admin/users/${id}`, {
            headers: {
                Authorization: `Bearer ${AuthService.getToken()}`,
            },
        })
        .then((response) => {
            window.location.href = "/admin/users";
        })
        .catch((error) => {
            console.error(error);
        });
    }

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`https://api.rb.beeapp.es/admin/users/${id}`, {
        headers: {
            Authorization: `Bearer ${AuthService.getToken()}`,
        },
        })
        .then((response) => {
            console.log(response);
            setName(response.data.Name);
            setSurname(response.data.Surname);
            setCif(response.data.CIF);
            setPhone(response.data.Phone);
            setEmail(response.data.Email);
            setEquivalenceSurcharge(response.data.EquivalenceSurcharge);
            setIsVerified(response.data.isVerified);
        }
        )
        .catch((error) => {
            console.error(error);
        }
        );
  }, []);


  function updateUser() {
    setLoader(true);
    axios.patch(`https://api.rb.beeapp.es/admin/users/${id}`, {
        Name: name,
        Surname: surname,
        Email: email,
        Phone: phone,
        CIF: cif,
        EquivalenceSurcharge: equivalenceSurcharge,
        isVerified: isVerified,
    }, {
        headers: {
            Authorization: `Bearer ${AuthService.getToken()}`,
        },
    })
    .then((response) => {
        setLoader(false);
        window.location.href = "/admin/users";
    })
    .catch((error) => {
        console.error(error);
    });
  }

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <OfferItemAddToCartSelect style={{ display: "flex", gap: "20px", width: 100 }}
        value={isVerified ? 2 : 1}
        onChange={(e) => setIsVerified(e.target.value === "2")}
      >
        <option value="1">Inactivo</option>
        <option value="2">Activo</option>
        </OfferItemAddToCartSelect>
      <Input type="text" placeholder="Nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
    />
      <Input type="text" placeholder="Apellido" 
        value={surname}
        onChange={(e) => setSurname(e.target.value)}
      />
      <Input type="text" placeholder="Email" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}  
    />
      <Input type="text" placeholder="Telefono" 
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <Input type="text" placeholder="CIF" 
        value={cif}
        onChange={(e) => setCif(e.target.value)}
      />
      <div 
        style={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
        }}
      >
      <Label htmlFor="equivalenceSurcharge">Recargo por equivalencia</Label>
      <CategoriesFilterSelectorItemCheckbox
        type="checkbox"
        style={{ display: "flex", gap: "10px" }}
        name="equivalenceSurcharge"
        checked={equivalenceSurcharge}
        onChange={(e) => setEquivalenceSurcharge(e.target.checked)}
      >

        </CategoriesFilterSelectorItemCheckbox>
        </div>
    <Button
        onClick={() => updateUser()}
        loading={loader}
    >Actualizar</Button>
    <Button
      outline
    onClick={() => deleteUser()}
    >Eliminar</Button>
    </div>
  );
}
