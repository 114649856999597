import React from 'react';
import { ProductContainer, ProductDetails, ProductDiscount, ProductImage, ProductName, ProductPrice, ProductPriceContainer } from './style';
import Button from '../Miscellaneous/Button';

export default function Product(props) {
    const { product } = props;
    let size = 1;
    switch (props.size || 'S') {
        case 'S':
            size = 1;
            break;
        case 'M':
            size = 1.25;
            break;
        case 'L':
            size = 1.5;
            break;
        case 'XL':
            size = 1.75;
            break;
        default:
            size = 1;
            break;
    }

    return (
        <ProductContainer
            size={size}
            onClick={() => {
                window.location.href = `/details/${product.productURL}`;
            }}
        >
            <ProductImage imageUrl={product.images[0]}/>
            <ProductDetails>
                <ProductName>{product.name}</ProductName>
                <ProductPriceContainer>
                    <ProductPrice>{product.price ? product.price.toString().replace('.',',')+'€' : 'Inicia Sesión'}</ProductPrice>
                </ProductPriceContainer>
                <Button
                    size="S"
                >
                    Ver Producto
                </Button>
            </ProductDetails>
        </ProductContainer>
    )
}