import styled from 'styled-components';

export const LayoutContainer = styled.div`
    width: auto;
    height: auto;
    
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;

export const Layout80 = styled.div`
    width: calc(100% - 350px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 0px 0 70px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        width: 100%;
        padding: 0px 20px;
    }
`;

export const Layout20 = styled.div`
    width: 250px;
    height: auto;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem 1rem;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        width: 100%;
    }
`;


export const Input = styled.input`
    width: 100%;
    height: auto;
    border: 2px solid #028322;
    border-radius: 0px;
    padding:2rem 1rem;
    font-size: 1rem;
    outline: none;
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;
    &:focus {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border: 0px solid #028322;
    }
`;

export const TextArea = styled.textarea`
    width: 100%;
    height: auto;
    border: 2px solid #028322;
    border-radius: 0px;
    padding:2rem 1rem;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
    transition: box-shadow, border 0.1s ease-in-out;
    &:focus {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        border: 0px solid #028322;
    }
`;

export const CategoryContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0 0px;
    box-sizing: border-box;
    overflow-x: auto;
    scrollbar-width: thin; /* For Firefox */

    &::-webkit-scrollbar {
        width: 6px; /* Adjust the width as needed */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #028322; /* Scrollbar thumb color */
        border-radius: 0px; /* Adjust the border radius for a squared look */
        border: 6px solid #ffffff; /* Optional: border around the thumb */
    }

    &::-webkit-scrollbar-track {
        background-color: #fff; /* Scrollbar track color */
        border-radius: 0px; /* Adjust the border radius to match thumb */
    }

    @media screen and (max-width: 800px) {
        width: 100%;
    }
    @media screen and (max-width: 800px){
        width: 100%;
    }
    > * {
        min-width: 150px; /* Adjust the minimum width as needed */
        flex-shrink: 0;
    }
`;

export const Category = styled.div`
    width: auto;
    height: auto;
    max-width: auto;
    display: flex;
    padding: 10px 5px;
    box-sizing: border-box;
    border: 2px solid #028322;
    border-radius: 0px;
    font-size: 1rem;
    color: #028322;
    cursor: pointer;
    outline: none;
    transition: all 0.1s ease-in-out;

    &:hover {
        background-color: #028322;
        color: #fff;
    }
    ${props => props.selected ? `
        background-color: #028322;
        color: #fff;
    ` : ''}
`;

export const SectionH2 = styled.h2`
    font-size: 1.5rem;
    color: #028322;
    font-weight: 400;
    margin: 0;
`;

export const InputFile = styled.input`
width: 0.1px;
height: 0.1px;
opacity: 0;
overflow: hidden;
position: absolute;
z-index: -1;
    & + label {
        width: 100%;
        height: auto;
        border: 2px solid #028322;
        border-radius: 0px;
        padding:2rem 1rem;
        font-size: 1rem;
        outline: none;
        transition: all 0.1s ease-in-out;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        color: #028322;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: #028322;
            color: #fff;
        }
    }

    &:focus + label,
    & + label:hover {
        background-color: #028322;
        color: #fff;
    }

    & + label {
        cursor: pointer; /* "hand" cursor */
    }

    `;

export const DoubleInput = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
    align-items: center;
    > * {
        width: 50%;
    }
`;

export const InputLikeCategory = styled.input`
    width: auto;
    height: auto;
    max-width: 250px;
    border: 2px solid #028322;
    border-radius: 0px;
    padding:10px 5px;
    font-size: 1rem;
    outline: none;
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;

`;

export const ProductsAlternativeContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem 1rem;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow-y: auto;
    align-items: center;
    scrollbar-width: thin; /* For Firefox */
    `;

export const ProductsAlternative = styled.div`
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem 1rem;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    `;

export const ProductsAlternativeSearch = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0rem 0rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    `;
export const ProductsAlternativeSearchInput = styled.input`
    width: 100%;
    height: 0px;
    border: 0px solid #028322;
    border-radius: 5px;
    padding:2rem 1rem;
    font-size: 1rem;
    outline: none;
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;
    background-color: #ddd;
    `;
    export const ProductsAlternativeAdded = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start; /* Adjusted justify-content */
    overflow-x: auto;
    scrollbar-width: thin; /* For Firefox */
    flex: 1;
    `;

export const ProductsAlternativeAddedPill = styled.div`
    height: 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0rem 1rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background-color: #028322;
    color: #fff;
    border-radius: 20px;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px; /* Added min-width to control width */
    max-width: 200px; /* Added max-width to control width */
    `;



export const ProductsAlternativeSearchResults = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0rem 0rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    border-radius: 5px;
    overflow-y: auto;
    `;
export const ProductsAlternativeSearchResultItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;  
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background-color: #f2f2f2;
    cursor: pointer;
    &:hover {
        background-color: #ddd;
    }
    `;