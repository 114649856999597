import styled from 'styled-components';

export const ModalContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: ${props => props.opened ? 'flex' : 'none'};
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px){

    }
`;

export const ModalContent = styled.div`
    ${props => props.isExpand ? `width: 95%;` : `width: 80%;`}
    ${props => props.isExpand ? `height: 95%;` : `height: auto;`}
    ${props => !props.isExpand ? `max-width: 740px;` : ``}
    ${props => !props.isExpand ? `min-height: 200px;` : ``}
    min-height: 200px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap:20px;
    position: relative;
    @media screen and (max-width: 600px){
        width: 100%;
        height: 100%;
        border-radius: 0px;
        box-shadow: none;
    }
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px;
    border-bottom: 1px solid #ccc;
    @media screen and (max-width: 600px){
        height: 75px;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    }

`;

export const ModalData = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    padding: 20px 20px;
    overflow-y: auto;
    @media screen and (max-width: 600px){
        padding: 10px 10px;
    }
`;

export const ModalHeaderTitle = styled.span`
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    @media screen and (max-width: 600px){
        font-size: 1rem;
    }
`;

export const ModalCloseButton = styled.button`
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    &:hover{
        background-color: #eee;
        border-radius: 5px;
    }
    @media screen and (max-width: 600px){
        width: 30px;
        height: 30px;
    }
`;
