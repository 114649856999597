import styled from 'styled-components';


export const FloatingButton = styled.button`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #028322;
    border: none;
    min-width: 65px;
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    border: 3px solid #028322;

    & > svg {
        width: 24px;
        height: 24px;
    }

    @media screen and (max-width: 800px){
        width: 50px;
        height: 50px;
        border-radius: 50%;
        min-width: 50px;
        min-height: 50px;
        & > svg {
            width: 20px;
            height: 20px;
        }
    }


    @keyframes shake {
        0% {
            transform: rotate(0deg);
        }
        10% {
            transform: rotate(5deg);
        }
        20% {
            transform: rotate(0deg);
        }
        30% {
            transform: rotate(-5deg);
        }
        40% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

    animation: shake 1s ease-in-out infinite;


`;

export const FloatingButtonContainer = styled.div`
    width: ${({ show }) => show ? '400px' : '65px'};
    height: 65px;

    position: fixed;
    bottom: 20px;
    right: 20px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(255,255,255, 0.2);
    backdrop-filter: blur(5px);

    -webkit-backdrop-filter: blur(5px);


    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    z-index: 100;
    color: #028322;
    font-weight: 400;
    transition: all 0.25s ease-in-out;
    & > * {
        margin: 0;
    }

    @media screen and (max-width: 800px){
        width: ${({ show }) => show ? 'calc(100vw - 40px)' : '50px'};
        height: 50px;
    }

    &:hover{
        width: 400px;
        background-color: rgba(255,255,255, 0.5);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.2);
        border-radius: 50px;
        color: #028322;
        font-weight: 400;
    }

    @media screen and (max-width: 800px){
        &:hover{
            width: calc(100vw - 40px);
        }
    }


`;

export const FloatingButtonContent = styled.div`
    width: calc(400px - 65px);
    height: auto;
    min-width: calc(400px - 65px);
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    padding: 5px 10px;
    padding-left: 20px;
    font-size: 0.9rem;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        width: calc(100% - 50px);
        font-size: 0.6rem;
        justify-content: flex-end;
    }
`;