import React, {useState, useEffect} from "react";
import { useAuth } from "../../Context/Auth";
import { InfoBadge, ProfileCardContent, ProfileContentSubTitle, ProfileContentTitle, ProfileIVASelector, ProfileIVASelectorItem } from "./style";
import Card from "../../Components/Miscellaneous/Card";
import OrderService from "../../Utils/API/orders";
import { Button, Input, Loader } from "../Login/style";
import ContentLoader from "react-content-loader";
import Layout from "../../Components/Layout";
const LoaderSkeleton = (props) => (
    <ContentLoader 
      speed={2}
      width={'100%'}
      height={63}
      backgroundColor="#ADADAD"
      foregroundColor="#c7c7c7"
      {...props}
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="63" />
    </ContentLoader>
  )
export default function Profile() {

    const { user } = useAuth();
    const [total, setTotal] = useState(null);
    const [spent, setSpent] = useState(null);
    const [loading, setLoading] = useState(false);

    function handleDownloadInvoices(period) {
        setLoading(true);
        OrderService.getOrdersInvoicesByPeriod(period).then(response => {
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.error(error);
        });
    }


    useEffect(() => {
        OrderService.getTotalOrders().then(response => {
            setTotal(
                response
            );
        }).catch(error => {
            console.error(error);
        });
        OrderService.getTotalOrderAmount().then(response => {
            setSpent(response);
        }
        ).catch(error => {
            console.error(error);
        });

    }
    , []);

    return (
        <div>
            <ProfileContentTitle>
            Hola, {user?.Name + ' ' + user?.Surname}
            </ProfileContentTitle>
            <ProfileCardContent>
            <Card xs loading={total === null ? true : false}>
                <span style={{fontSize: '1rem', textAlign: 'center'}}>Pedidos Totales</span>
                <span style={{fontSize: '2rem', textAlign: 'center', fontWeight:'bold'}}>{total}</span>
            </Card>
            <Card xs loading={spent === null ? true : false}>
                <span style={{fontSize: '1rem', textAlign: 'center'}}>Gasto Total</span>
                <span style={{fontSize: '1.5rem',lineBreak:'anywhere', textAlign: 'center', fontWeight:'bold'}}>{spent}€</span>
            </Card>
            <Card xs>
                <span style={{fontSize: '1rem', textAlign: 'center'}}>Pedidos Enviados</span>
                <span style={{fontSize: '2rem', textAlign: 'center', fontWeight:'bold'}}>0</span>
            </Card>
            <Card xs>
                <span style={{fontSize: '1rem', textAlign: 'center'}}>Facturas Pendientes</span>
                <span style={{fontSize: '2rem', textAlign: 'center', fontWeight:'bold'}}>0</span>
            </Card>
            </ProfileCardContent>
            
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '2rem',
                }}
            >
                <ProfileContentSubTitle>
                    IVA
                </ProfileContentSubTitle>
                <InfoBadge>
                    Obten un conjunto de tus facturas por cada trimestre del año
                </InfoBadge>
                {
                    loading ? <LoaderSkeleton /> : (
                        <ProfileIVASelector>
                        <ProfileIVASelectorItem
                            onClick={()=>handleDownloadInvoices('enero')}
                        >
                                Enero
                            </ProfileIVASelectorItem>
                            <ProfileIVASelectorItem
                                onClick={()=>handleDownloadInvoices('abril')}
                            >
                                Abril
                            </ProfileIVASelectorItem>
                            <ProfileIVASelectorItem
                                onClick={()=>handleDownloadInvoices('julio')}
                            >
                                Julio
                            </ProfileIVASelectorItem>
                            <ProfileIVASelectorItem
                                onClick={()=>handleDownloadInvoices('octubre')}
                            >
                                Octubre
                            </ProfileIVASelectorItem>
         
                        </ProfileIVASelector>
                    )
                }

            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '2rem',
                    gap: '1rem'
                }}
            >
                <ProfileContentSubTitle>
                    Cambiar Contraseña
                </ProfileContentSubTitle>
                <Input type="password" placeholder="Contraseña antigua"/>
                <Input type="password" placeholder="Contraseña nueva"/>
                <Button>
                    Cambiar Contraseña
                </Button>
            </div>

        </div>
    )
}
