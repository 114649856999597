import styled from 'styled-components';

export const PanelContainer = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : 'auto'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '5px'};
    box-shadow: ${props => props.noShadow ? 'none' : '0px 0px 4px 0px rgba(0,0,0,0.25)'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px;
    cursor: ${props => props.cursor ? props.cursor : 'default'};
    ${props => props.expandMobile ? `@media screen and (max-width: 800px){width: 100%;border-radius:0px;right:0;left:0;}` : ''}
    ${props => props.opened ? 'display: flex;' : 'display: none;'}
    position: ${props => props.position ? props.position : 'absolute'};
    ${props => props.customStyle ? props.customStyle : ''}
    ${props => props.ptop ? `top: ${props.ptop};` : ''}
    ${props => props.pbottom ? `bottom: ${props.pbottom};` : ''}
    ${props => props.pleft ? `left: ${props.pleft};` : ''}
    ${props => props.pright ? `right: ${props.pright};` : ''}
    ${props => props.hideMobile ? `@media screen and (max-width: 800px){display: none;}` : ''}
    z-index: ${props => props.zIndex ? props.zIndex : '1'};
    // &::after depending on the props, we can add a pseudo element to the component in top left right or bottom
    ${props => props.tooltip && `
        &:after{
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            ${props.top && `border-width: 0 10px 10px 10px;`}
            ${props.top && `border-color: transparent transparent #b2b2b2 transparent;`}
            ${props.bottom && `border-width: 10px 10px 0 10px;`}
            ${props.bottom && `border-color: #b2b2b2 transparent transparent transparent;`}
            ${props.left && `border-width: 10px 10px 10px 0;`}
            ${props.left && `border-color: transparent #b2b2b2 transparent transparent;`}
            ${props.right && `border-width: 10px 0 10px 10px;`}
            ${props.right && `border-color: transparent transparent transparent #b2b2b2;`}
            ${props.top && `top: -10px; left: 50%; transform: translateX(-50%);`}
            ${props.bottom && `bottom: -10px; left: 50%; transform: translateX(-50%);`}
            ${props.left && `left: -10px; top: 50%; transform: translateY(-50%);`}
            ${props.right && `right: -10px; top: 50%; transform: translateY(-50%);`}
            ${props.moveright && `left: ${props.moveright}%;`}
        }
        `
    }

`;

    