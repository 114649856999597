import styled from "styled-components";
import {useState} from 'react';
const BaseHeight = 200;
const BaseWidth = BaseHeight * 0.8;
 
export const ProductContainer = styled.a`
    //S M L XL
    height: ${props => props.size ? 'auto' : 'auto'};
    width: ${props => props.size ? BaseWidth * props.size : BaseWidth}px;

    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    gap: 10px;
    cursor: pointer;
    position: relative;
    &:hover{
    }
    ${props => props.customStyle ? props.customStyle : ''}
    ${props => props.hideMobile ? `@media screen and (max-width: 800px){display: none;}` : ''}
    z-index: ${props => props.zIndex ? props.zIndex : '1'};
    &:hover{
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.35);
    }
    transition: all 0.2s ease;
    &:hover{
        transform: scale(1.03);
    }

    &:active{
        transform: scale(0.98);
    }
    

    `;

const ProductImageComponent = styled.img`
    width: 100%;
    height: 70%;
    max-height: 200px;
    object-fit: cover; /* Similar to background-size: cover */
    border-radius: 5px;
`;

export const ProductImage = ({ imageUrl }) => {
    const [imageLoadError, setImageLoadError] = useState(false);
    
    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <ProductImageComponent 
            src={imageLoadError ? 'https://cdn.beeapp.es/distribucionesrb/NoImage.png' : imageUrl} 
            onError={handleImageError} 
        />
    );
};


export const ProductDetails = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    `;

export const ProductName = styled.span`
display: block;
max-width: 98%;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
min-height: 20px;
font-size: 14px;
font-weight: 600;
    `;

export const ProductPrice = styled.span`
    font-size: 16px;
    font-weight: 600;
    color: #028322;
    `;

export const ProductPriceContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    `;

export const ProductDiscount = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: #ddd;
    text-decoration: line-through;
    `;