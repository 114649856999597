import React, { useState, useEffect } from 'react';
import './App.css';

import Header from './Components/Header';
import LoginScreen from './Screens/Login';
//React Router Dom
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Components/Footer';
import ScannerScreen from './Screens/Scanner';
import Home from './Screens/Home';
import { addProducts } from './indexedDBService';
import Details from './Screens/Details';
import AuthService from './AuthService';
import ProfileScreen from './Screens/Profile';
import Checkout from './Screens/Checkout';
import Categories from './Screens/Categories';
import ProtectedAdminRoute from './Components/Miscellaneous/Utils/ProtectedAdminRoute';
import DashBoard from './Screens/AdminScreens/DashBoard';
import NotFound from './Screens/404';
import { useAuth } from './Context/Auth';
import Search from './Screens/Search';
import AvisoLegal from './Screens/Legal/aviso';
import Privacidad from './Screens/Legal/privacidad';
import Nosotros from './Screens/Legal/nosotros';
import Envios from './Screens/Legal/envios';
//history react router dom

import ReactGA from "react-ga4";

ReactGA.initialize("G-53TGBNW6PJ");






function App() {
  const [loggedIn2, setLoggedIn] = useState(AuthService.isAuthenticated() ? true : false);
  const user = AuthService.getUser();
  const {loggedIn} = useAuth();
  const ProtectedRoute = ({ user,loggedIn3, children }) => {
    if (loggedIn === false) {
      //get current path
      const path = window.location.pathname;
  
      return <Navigate to={"/login?r="+path} replace />;
    }else{
    return children;
    }
  };

  
  return (
    <>
    <ToastContainer/>
    <Router>
      <Routes>
        <Route path="/login" element={
          !loggedIn2 ? <Header/> :
        <LoginScreen/>
        }>
        </Route>
        <Route path="/scanner" element={<ScannerScreen/>}/>
        <Route path="/" element={
          <>
        <Header/>
        <Home/>
        <Footer/>
        </>
        }>          
        </Route>

        <Route path="/details/:category/:id" element={
          <>
        <Header/>
        <Details/>
        <Footer/>
        </>
        }>

        </Route>
        <Route path='/aviso-legal' element={
          <>
          <Header/>
          <AvisoLegal/>
          <Footer/>
          </>
        }></Route>
        <Route path='/politica-de-privacidad' element={
          <>
          <Header/>
          <Privacidad/>
          <Footer/>
          </>
        }></Route>
        <Route path='/sobre-nosotros' element={
          <>
          <Header/>
          <Nosotros/>
          <Footer/>
          </>
        }></Route>
        <Route path='/envios-y-devoluciones' element={
          <>
          <Header/>
          <Envios/>
          <Footer/>
          </>
        }></Route>
        <Route path="/categories/:category" element={
          <>
            <Header/>
            <Categories/>
            <Footer/>
          </>
        }></Route>
        <Route path="/search" element={
          <>
            <Header/>
            <Search/>
            <Footer/>
          </>
        }></Route>

        <Route path='/admin/:tab' element={
          <ProtectedAdminRoute user={user}>
            <DashBoard/>
          </ProtectedAdminRoute>
        }></Route>
          <Route path='/admin/:tab/:id' element={
          <ProtectedAdminRoute user={user}>
            <DashBoard/>
          </ProtectedAdminRoute>
        }></Route>
        <Route path='/admin' element={
          <ProtectedAdminRoute user={user}>
            <DashBoard/>
          </ProtectedAdminRoute>
        }></Route>


        <Route path="/me/:tab" element={
        <ProtectedRoute user={user} loggedIn>
          <Header/>
        <ProfileScreen/>
        <Footer/>
        </ProtectedRoute>
        }></Route>
        <Route path="/checkout" element={
        <ProtectedRoute user={user} loggedIn>
          <Header/>
          <Checkout/>
        </ProtectedRoute>
        }></Route>

        <Route path="*" element={
          <>
            <Header/>
            <NotFound/>
            <Footer/>
          </>
        }></Route>

      </Routes>
    </Router>
    </>
  );
}

export default App;
