import styled from 'styled-components';

export const CardContainer = styled.a`
    width: ${props => props.xs ? "120px" : props.sm ? "240px" : props.md ? "480px" : props.lg ? "600px" : props.xl ? "720px" : '100%'};
    height: ${props => props.xs ? "120px" : props.sm ? "240px" : props.md ? "480px" : props.lg ? "600px" : props.xl ? "720px" : '100%'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#028322'};
    min-width: ${props => props.xs ? "120px" : props.sm ? "240px" : props.md ? "480px" : props.lg ? "600px" : props.xl ? "720px" : '100%'};
    min-height: ${props => props.xs ? "120px" : props.sm ? "240px" : props.md ? "480px" : props.lg ? "600px" : props.xl ? "720px" : '100%'};

    color: ${props => props.color ? props.color : '#fff'};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.loading ? 'center' : 'flex-start'};
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    text-wrap: anywhere;
    padding: ${props => props.xs ? "10px" : props.sm ? "20px" : props.md ? "40px" : props.lg ? "50px" : props.xl ? "60px" : '20px'};
    &:hover{
        transform: scale(1.05);
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
    }
    &:focus{
        outline: none;
    }
    &:active{
        transform: scale(1);
        box-shadow: none;
    }
    @media screen and (max-width: 600px){
        width: 100%;
        height: 100%;
        min-height: auto;
        min-width: auto;
    }
    ${props => props.customStyle ? props.customStyle : ''}
    `;
