import styled from 'styled-components';
import {useState} from 'react';
import emp from '../../assets/empty-cart.png';
export const DetailsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`;

export const DetailsContentBreadCrumb = styled.div`
    width: 100%;
    height: auto;
    display: inline-block;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 70px;
    box-sizing: border-box;
    @media screen and (max-width: 600px){
        padding: 20px 20px;
    }
`;

export const DetailsContent = styled.div`
    width: 100%;
    height: auto;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 70px;
    box-sizing: border-box;
    @media screen and (max-width: 600px){
        padding: 10px 20px;
        flex-direction: column;
    }
`;

export const DetailsContentImage = styled.div`
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 30vmax;
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.offer && `
        &:before{
            content: "OFERTA";
            position: relative;
            top: 0;
            left: 0;
            width: 50px;
            height: 50px;
            padding: 15px;
            display: flex;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 800;
            color: #fff;
            background-color: #FF0000;
            z-index: 100;
        }
    `}
    @media screen and (max-width: 600px){

        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
    }
`;

 const DetailsContentImageContainerComponent = styled.img`
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 30vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('${props => props.background}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 600px){
        width: 100%;
    }
`;

export const DetailsContentImageContainer = ({src}) => {
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <DetailsContentImageContainerComponent
            src={imageLoadError ? 'https://cdn.beeapp.es/distribucionesrb/NoImage.png' : src}
            onError={handleImageError}
        />
    );
};


export const DetailsContentInfo = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0 40px;
    @media screen and (max-width: 600px){
        width: 100%;
        padding: 0;
    }
`;

export const DetailsContentInfoTitle = styled.h1`
    font-size: 30px;
    font-weight: 800;
    margin: 0;
    margin-bottom: 10px;
    @media screen and (max-width: 600px){
        font-size: 20px;
    }
`;

export const DetailsContentInfoDescription = styled.p`
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
    line-height: 1.6;
    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const DetailsContentInfoPriceContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;

export const DetailsContentInfoPrice = styled.span`
    font-size: 32px;
    font-weight: 800;
    margin: 0;
    &:after{
        content: 'por unidad';
        font-size: 20px;
        font-weight: 400;
        margin-left: 10px;
        color: #858585;
    }
    @media screen and (max-width: 600px){
        font-size: 16px;
    }
`;

export const DetailsContentInfoReference = styled.span`
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    color: #000;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const DetailsContentInfoReferenceSpan = styled.span`
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    color: #000;
    text-transform: uppercase;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const DetailsContentInfoActions = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    flex-direction: column;
`;

export const DetailsLoader = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid #028322;
    border-top: 5px solid #fff;
    animation: spin 0.7s linear infinite;
    margin: 0 auto;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }
`;

export const DetailsContentInfoStock = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: ${props => props.stock ? '#028322' : '#FF0000'};
    font-size: 16px;
    font-weight: 600;
    &:after{
        content: '${props => props.stock ? 'En Stock' : 'Sin Stock'}';
        font-size: 16px;
        font-weight: 600;
        margin-left: 10px;
        color: ${props => props.stock ? '#028322' : '#FF0000'};
    }
`;

export const DetailsContentInfoMustLogin = styled.div`
    width: calc(100% - 40px);
    height: auto;
    margin-top: 10px;
    padding: 10px 20px;
    background: #ddd;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    
`;

export const DetailsContentInfoMustLoginBackdrop = styled.div`
    width: 400px;
    height: auto;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    display: flex;
    justify-content: center;
    color: #000;
    flex-direction: column;
    font-size: 1.5rem;
    -webkit-user-select: none;
    user-select: none;
    `;


export const DetailsContentSimilarProducts = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 80px 70px;
    box-sizing: border-box;
    @media screen and (max-width: 600px){
        padding: 10px 20px;
    }
`;

export const DetailsContentSimilarProductsTitle = styled.h2`
    font-size: 2em;
    font-weight: 600;
    margin: 0;
    margin-right: auto;
    margin-bottom: 20px;
    background: #028322;
    padding: 10px 15vmax 10px 30px;
    clip-path: polygon(0 0, 100% 0, calc(100% - 20px) 100%, 0% 100%);
    color: #fff;
    @media screen and (max-width: 600px){
        font-size: 20px;
    }
`;

export const DetailsContentSimilarProductsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
`;
export const DetailsContentSimilarProductsItemImage = styled.div`
    width: 100%;
    height: 200px;
    background: url('${props => props.background}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease;
    min-height: 50px;
    min-width: 50px;
`;
export const DetailsContentSimilarProductsItem = styled.a`
    width: 20%;
    height: 20vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #ddd;
    padding: 20px;
    color: #000;
    text-decoration: none;
    overflow: hidden;
    
    &:hover{
        border: 1px solid #028322;
        &:before{
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            padding: 0;
            display: flex;
            border-radius: 0%;
            clip-path: polygon(50% 0, 100% 100%, 0 100%);
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 800;
            color: #fff;
            background-color: #028322;
            z-index: 100;
            transform: translateY(calc(10vmax - 10px));
        }
    }
    &:hover ${DetailsContentSimilarProductsItemImage}{
        transform: scale(1.1);
    }
    @media screen and (max-width: 600px){
        width: calc(50% - 40px);
        font-size: 12px;
    }
`;

export const DetailsActionQuantity = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    border-radius: 5px;
    min-width: 150px;
`;

export const DetailsActionQuantityButton = styled.button`
    width: 50px;
    height: 50px;
    border-radius: 0%;
    border: none;
    background: #028322;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 25px;
    &:hover{
        background: #028322;
        color: #fff;
    }
    @media screen and (max-width: 600px){
        font-size: 1.2rem;
    }

`;

export const DetailsActionQuantityInput = styled.input`
    width: 50px;
    height: 50px;
    border-radius: 0px;
    border: none;
    background: #fff;
    color: #028322;
    font-size: 1.2rem;
    font-weight: 800;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;


    &:focus{
        outline: none;
    }
    @media screen and (max-width: 600px){
        font-size: 1rem;
    }
`;


export const DetailsContentSimilarProductsItemTitle = styled.h3`
    font-size: 14px;
    font-weight: 800;
    margin: 0;
    margin-top: 10px;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const DetailsContentSimilarProductsItemPrice = styled.span`
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }
`;

export const DetailsContentVariants = styled.div`
    width: auto;
    height: auto;
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 30px 20px;
    box-sizing: border-box;
    @media screen and (max-width: 600px){
        padding: 10px 20px;
    }
`;

export const DetailsContentVariantsItem = styled.div`
    width: 75px;
    height: 75px;
    display: flex;
    min-width: 75px;
    min-height: 75px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #ddd;
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover{
        border: 3px solid #028322;
    }
    ${props => props.selected && `
        border: 3px solid #028322;
    `}
    ${props => props.disabled && `
        transform: grayscale(100%);
        cursor: not-allowed;
        border: 3px solid #ddd;
        &:hover{
            border: 3px solid #ddd;
        }

        & > *{
            filter: grayscale(100%);
        }
        
    `}
`;

export const DetailsContentVariantsItemColor = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${props => props.color};

`;

export const DetailsContentVariantsItemSize = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #000;
`;
export const DetailsContentComparativePrice = styled.button`
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #028322;
    background: #fff;
    border: 1px solid #028322;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: absolute;
    transform: translateY(-20px);
    &:hover{
        background: #028322;
        color: #fff;
    }
    @media screen and (max-width: 600px){
        font-size: 12px;
        transform: translateY(-10px);
    }
`;

export const DetailsContentComparativePriceContainer = styled.div`
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #028322;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    position: absolute;
    z-index: 1;
    right: 20px;
    background: #fff;
    transform: translateY(10px);
    @media screen and (max-width: 600px){
        width: 300px;
        transform: translateY(10px);
        right: 0;
    }
`;
    
export const DetailsContentComparativePriceItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    
`;

export const DetailsContentComparativePriceItemLoaderThreeDots = styled.div`
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    border: 2px solid #028322;
    border-top: 2px solid #fff;
    animation: spin 0.7s linear infinite;
    margin: 0 auto;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }
`;



 const DetailsContentVariantsItemImageComponent = styled.img`
    width: auto;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
`;

export const DetailsContentVariantsItemImage = ({src}) => {
    const [imageLoadError, setImageLoadError] = useState(false);

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <DetailsContentVariantsItemImageComponent
        src={imageLoadError ? 'https://cdn.beeapp.es/distribucionesrb/NoImage.png' : src}
        onError={handleImageError}
        />
    );
};

export const DetailsAddedBadge = styled.span`
    width: 160px;
    height: 70px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    padding: 10px 20px;
    background: #028322;
    color: #fff;
    font-size: 14px;
    margin-left: auto;
    right: 25px;
    position: absolute;
    cursor: pointer;
    border-radius: 0px 0px 5px 5px;
    transition: all 0.3s ease;
    transform: translateY(-50px);
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (max-width: 600px){
        font-size: 12px;
        width: 100%;
        right: 0;
        display: none;
    }

    &:hover{
        background: #359c4f;
        transform: translateY(0px);
    }
`;

export const DetailsBackdrop = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 600px){
        gap: 10px;
    }
`;

export const DetailsModal = styled.div`
    width: 500px;
    height: auto;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 600px){
        width: 100%;
    }
`;

export const EmptyCart = styled.div`
    width: 128px;
    height: 128px;
    background: url(${emp});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

export const DetailsModalTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    font-family: 'Inter', sans-serif;
    color: #028322;
`;

export const DetailsModalDescription = styled.p`
    font-size: .9rem;
    width: 90%;
    font-weight: 400;
    margin: 0;
    font-family: 'Inter', sans-serif;
    color: #028322;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const DetailsModalFooter = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    gap: 10px;
    `;


export const DetailsSaveMore = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px;
    box-sizing: border-box;
    @media screen and (max-width: 600px){
        padding: 10px 20px;
    }
    background: #359C4F;
    border-radius: 5px;
    & > * {
        color: #fff;
    }
    & > *::after{
        color: #fff;
        @media screen and (max-width: 600px){
            font-size: 14px;
        }
    }

`;

export const DetailsSaveMoreTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0;
    margin-right: auto;
    margin-bottom: 0px;
    display: flex;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: 600px){
        font-size: 16px;
    }
    * {
        font-family: 'Inter', sans-serif;
    }
`;


//Create a div progress bar
export const ProgressBar = styled.div`
    width: 100%;
    height: 20px;
    background: #ddd;
    border-radius: 20px;
    margin-top: 0px;
    position: relative;
    overflow: hidden;
    border: 2px solid #ddd; 
`;

//Create a div progress bar
export const ProgressBarProgress = styled.div`
    width: ${props => props.progress}%;
    height: 100%;
    background: #028322;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
`;

export const ProgressBarContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    @media screen and (max-width: 600px){
        gap: 5px;
    }


`;

export const ProgressPill = styled.div`
    width: auto;
    height: 20px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    font-size: 12px;
    display: ${props => !props.none ? 'flex' : 'none'};
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    color: #028322;
    text-wrap: nowrap;
    overflow: hidden;
    cursor: pointer;
    &:hover{
        background: #028322;
        color: #fff;
    }

    @media screen and (max-width: 600px){
        font-size: 10px;
        padding: 0 10px;
    }
`;


export const ProgressBarProgressPill = styled.div`
    width: 60px;
    height: 100%;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    position: absolute;
    font-size: 12px;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    transform: translateY(-2px) translateX(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #028322;
    @media screen and (max-width: 600px){
        font-size: 10px;
    }
`;

export const DetailsSaveMoreDescription = styled.p`
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    margin-right: auto;
    margin-bottom: 0px;
    margin-top: 10px;
    @media screen and (max-width: 600px){
        font-size: 10px;
    }

`;