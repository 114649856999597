import React from 'react';
import { LinkButton, MegaMenuCategory, MegaMenuCategoryBento, MegaMenuCategoryContainer, MegaMenuCategoryProducts, MegaMenuContainer, MegaMenuContent, Slogan } from './style';

import { UtilsContext } from '../../Context/UtilsContext';
import ofertas from '../../assets/ofertas.svg';
import escrituraycorrecion from '../../assets/Escritura-y-correcion.svg';
import productosdeoficina from '../../assets/productos-de-oficina.svg';
import productosescolares from '../../assets/productos-escolares.svg';
import etiquetasyembalajes from '../../assets/etiquetas-y-embalajes.svg';
import papelcuardernosysobres from '../../assets/papel-cuadernos-y-sobres.svg';
import archivosyclasificacion from '../../assets/archivos-y-clasificacion.svg';
import consumiblesyaccesoriosinformaticos from '../../assets/consumibles-y-accesorios-informaticos.svg';
import mobiliariodeoficina from '../../assets/mobiliario-de-oficina.svg';
import organizacion from '../../assets/organizacion.svg';
import juegos from '../../assets/juegos.svg';
import catalogos from '../../assets/catalogo.svg';
//ChevronDown
import { FaBookOpen } from "react-icons/fa";
import { BiChevronDown } from 'react-icons/bi';
//Menu
import { BiMenu } from 'react-icons/bi';
import { DetailsContentSimilarProductsTitle } from '../../Screens/Details/style';
import Product from '../Product';
import ProductService from '../../Utils/API/products';
import Card from '../Miscellaneous/Card';

export default function MegaMenu() {
    const { isMegaMenuOpen, toggleMegaMenu, windowWidth, windowHeight } = React.useContext(UtilsContext);
    const [hoverCategory, setHoverCategory] = React.useState('ofertas');
    const [products, setProducts] = React.useState([]);

    React.useEffect(() => {
        async function h(){
        await ProductService.getProductsForMenu().then((response) => {
            setProducts(response);
            console.log(response);
        }
        );
    }
    h();
    }, []);
    return (
        <MegaMenuContainer
            isMegaMenuOpen={isMegaMenuOpen}
        >
            <MegaMenuContent
                isMegaMenuOpen={isMegaMenuOpen}

            >
                <MegaMenuCategoryContainer
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                    isMegaMenuOpen={isMegaMenuOpen}
                >
            <LinkButton
                onClick={toggleMegaMenu}
                isMegaMenuOpen={isMegaMenuOpen}
            >
                <BiMenu
                    size={24}
                    color="#000"
                />
            Todas las categorías

            </LinkButton>
            {
                isMegaMenuOpen && (
                    <>
                    <LinkButton
                    special
                    onMouseEnter={() => setHoverCategory('ofertas')}
                    >
                        <img src={ofertas} alt="ofertas" />
                        Ofertas
                    </LinkButton>
                    <LinkButton
                        href='/categories/escritura-y-correccion'
                    onMouseEnter={() => setHoverCategory('escritura-y-correccion')}
                    >
                        <img src={escrituraycorrecion} alt="escritura y correccion" />
                        Escritura y Corrección
                    </LinkButton>
                    <LinkButton
                        href='/categories/productos-de-oficina'
                    onMouseEnter={() => setHoverCategory('productos-de-oficina')}
                    >
                        <img src={productosdeoficina} alt="productos de oficina" />
                        Productos de Oficina
                    </LinkButton>
                    <LinkButton
                        href='/categories/productos-escolares'
                        onMouseEnter={() => setHoverCategory('productos-escolares')}

                    >
                        <img src={productosescolares} alt="productos escolares" />
                        Productos Escolares
                    </LinkButton>
                    <LinkButton
                        href='/categories/etiquetas-y-embalajes'
                        onMouseEnter={() => setHoverCategory('etiquetas-y-embalajes')}
                    >
                        <img src={etiquetasyembalajes} alt="etiquetas y embalajes" />
                        Etiquetas y Embalajes
                    </LinkButton>
                    <LinkButton
                        href='/categories/papel-cuadernos-y-sobres'
                        onMouseEnter={() => setHoverCategory('papel')}
                    >
                        <img src={papelcuardernosysobres} alt="papel, cuadernos y sobres" />
                        Papel, Cuadernos y Sobres
                    </LinkButton>
                    <LinkButton
                        href='/categories/archivo-y-clasificacion'
                        onMouseEnter={() => setHoverCategory('archivo-y-clasificacion')}
                    >
                        <img src={archivosyclasificacion} alt="archivo y clasificacion" />
                        Archivo y Clasificacion
                    </LinkButton>
                    <LinkButton
                        href='/categories/consumibles-y-accesorios-informaticos'
                        onMouseEnter={() => setHoverCategory('consumibles-y-accesorios-informaticos')}
                    >
                        <img src={consumiblesyaccesoriosinformaticos} alt="consumibles y accesorios informaticos" />
                        Consumibles y Accesorios Informaticos
                    </LinkButton>
                    <LinkButton
                        href='/categories/mobiliario-de-oficina'
                        onMouseEnter={() => setHoverCategory('mobiliario-de-oficina')}
                    >
                        <img src={mobiliariodeoficina} alt="mobiliario de oficina" />
                        Mobiliario de Oficina
                    </LinkButton>
                    <LinkButton
                        href='/categories/organizacion'
                        onMouseEnter={() => setHoverCategory('organizacion')}
                    >
                        <img src={organizacion} alt="organizacion" />
                        Organizacion
                    </LinkButton>
                    <LinkButton
                        href='/categories/juegos'
                        onMouseEnter={() => setHoverCategory('juegos')}
                    >
                        <img src={juegos} alt="juegos" />
                        Juegos
                    </LinkButton>

                    </>
                )
            }
                
                
            </MegaMenuCategoryContainer>
            <Slogan
                isMegaMenuOpen={isMegaMenuOpen}
            >
            Envío gratis en compras mayores a 100€ + IVA
            </Slogan>
            <LinkButton
                style={{
                    marginLeft: 'auto',
                }}
                show={isMegaMenuOpen}
                >
                <FaBookOpen
                    size={24}
                    color="#000"
                />
                Catálogos
            </LinkButton>
            </MegaMenuContent>

            <MegaMenuCategory
                isMegaMenuOpen={isMegaMenuOpen}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
            >

                <DetailsContentSimilarProductsTitle>
                    {
                        hoverCategory === 'ofertas' ? 'Ofertas' : hoverCategory === 'escritura-y-correccion' ? 'Escritura y Corrección' : hoverCategory === 'productos-de-oficina' ? 'Productos de Oficina' : hoverCategory === 'productos-escolares' ? 'Productos Escolares' : hoverCategory === 'etiquetas-y-embalajes' ? 'Etiquetas y Embalajes' : hoverCategory === 'papel' ? 'Papel, Cuadernos y Sobres' : hoverCategory === 'archivo-y-clasificacion' ? 'Archivo y Clasificacion' : hoverCategory === 'consumibles-y-accesorios-informaticos' ? 'Consumibles y Accesorios Informaticos' : hoverCategory === 'mobiliario-de-oficina' ? 'Mobiliario de Oficina' : hoverCategory === 'organizacion' ? 'Organizacion' : hoverCategory === 'juegos' ? 'Juegos' : 'Catálogos'
                    }
                </DetailsContentSimilarProductsTitle>
                <MegaMenuCategoryProducts>
                    {
                        products[hoverCategory]?.length > 0 && products[hoverCategory].map((product) => {
                            return (
                                <Product
                                    key={product._id}
                                    product={product}
                                    size="L"
                                />
                            )
                        })
                    }
                    <Card sm
                        onClick={() => {
                            window.location.href = '/categories/'+hoverCategory;
                        }
                        }
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            height: '100%'
                        }}>
                        <span style={{fontWeight: 'bold', fontSize: '1.2em'}}>¿No encuentras lo que buscas?</span>
                        <span>Ver más productos</span>
                        </div>
                    </Card>
                </MegaMenuCategoryProducts>
            </MegaMenuCategory>

        </MegaMenuContainer>
    )
}
