import React, {useState, useEffect} from 'react';
import { OrderCard, OrderCardTitle, ProfileContentSubTitle, ProfileContentTitle, OrderCardItem, OrderCardQuantity } from './style';
import OrderService from '../../Utils/API/orders';
import Modal from '../../Components/Miscellaneous/Modal';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
//Box icon
import { BsFillBoxSeamFill, BsTruck } from 'react-icons/bs';
import {TbNotes} from 'react-icons/tb';
import { Portal } from 'react-portal';
import Button from '../../Components/Miscellaneous/Button';
import AuthService from '../../AuthService';

const token = AuthService.getToken();

export default function Orders() {
    const [orders, setOrders] = useState([]);
    const [opened, setOpened] = useState(false);
    const [orderInfo, setOrderInfo] = useState(null);
    useEffect(() => {
        const getOrders = async () => {
            const orders = await OrderService.getOrders();
            setOrders(orders);
        }

        getOrders();
    }
    , []);

    function close(){
        setOpened(false);
        setOrder(null);
    }
    const MAX_PERCENT = 49;
    const MIN_PERCENT = 0;
    const TIME_DIFFERENCE_THRESHOLD = 20 * 60; // 20 minutes in milliseconds
    
    

    async function open(id){
        setOpened(true);
        setOrder(id);

        const order = await OrderService.getOrder(id._id);
        setOrderInfo(order);
    }

    const [order, setOrder] = useState([]);

    return (
        <>
        <ProfileContentTitle>
            Mis Pedidos
        </ProfileContentTitle>
        {
            orders.length > 0 ? orders.map(order => {
                return (
                    <OrderCard
                    key={order._id}
                    onClick={() => {
                        open(order);
                    }}
                    >
                        <OrderCardTitle>
                        Pedido #{order._id}
                        </OrderCardTitle>
                        <span
                        style={{
                            fontSize: '14px',
                            color: '#aaa',
                        }}
                        >
                            Pedido realizado el {new Date(order.CreationDate).toLocaleDateString()}
                            </span>
                    </OrderCard>
                )
            }) : ''
        }
        {
            opened ? <Portal>
<Modal title={"Pedido #"+ order._id} isExpand opened={opened} close={()=>close}>
            <div
                style={{
                    padding: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                }}
            >
            <ProgressBar
        percent={
            orderInfo?.Status === 'Created' ? 30 : 90
        }
        filledBackground="#028322"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: accomplished ? '#028322' : '#ddd',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '1rem',
                textAlign: 'center',
                
            }}>
                <TbNotes size={20}/>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: accomplished ? '#028322' : '#ddd',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '1rem',
                textAlign: 'center',
                
            }}>
                <BsFillBoxSeamFill size={20}/>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: accomplished ? '#028322' : '#ddd',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '1rem',
                textAlign: 'center',
                
            }}>
                <BsTruck size={20}/>
            </div>
          )}
        </Step>
      </ProgressBar>
            <ProfileContentSubTitle>
                Estado del pedido: {
                        orderInfo?.Status === 'Created' ? 'En preparación' : orderInfo?.Status === 'Sent' ? 'Enviado' : 'Entregado'
                    }
            </ProfileContentSubTitle>
            <Button onClick={() => {
                window.open('https://api.rb.beeapp.es/pdfViewer/'+token+'/'+order._id+'/new', '_blank', 'width=600,height=800,scrollbars=no,resizable=no,toolbar=no,location=no,menubar=no,status=no');
            }}>
                Ver factura
            </Button>
            <ProfileContentSubTitle>
                Productos
            </ProfileContentSubTitle>
            {
                //filter by _id and map
               
                order.Products.map((product, index) => { 
                    return (
                        <OrderCardItem key={index}>
                            <img src={product.product.images[0]} alt={product.product.name} style={{
                                width: '90px',
                                height: '90px',
                                objectFit: 'cover',
                                borderRadius: '5px',
                                marginRight: '10px',
                            }}/>
                            <span style={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                {product.product.name}
                                <OrderCardQuantity>
                                    {product.quantity}
                                </OrderCardQuantity>
                            </span>
                        </OrderCardItem>
                    )
                        })
            }
            
            </div>
        </Modal>
        </Portal> : null
        }
       
        </>
    )
}