import {toast} from 'react-toastify';
import AuthService from '../../AuthService';
import axios from 'axios';
const API_URL = 'https://api.rb.beeapp.es';
const token = AuthService.getToken();

class ChatService {
    static async getChats() {

        const response = await axios.get(`${API_URL}/chat/chats`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return response.data;
    }

    static async closeChat(id) {
        const response = await axios.delete(`${API_URL}/chat/chat/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return response.data;
    }


    static async getChat(id) {

        const response = await axios.get(`${API_URL}/chat/chat/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return response.data;
    }

    static async sendMessage(id, message) {
        const response = await fetch(`${API_URL}/chat/chat/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(message)
        });
        const data = await response.json();
        return data || {};

        }
    static async createChat(chat) {
        axios.post(`${API_URL}/chat/chat`, chat, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(res => {
                const chat = res.data;
                return chat;
            })
            .catch(err => {
                console.error(err);
            })
        }
    
}

export default ChatService;