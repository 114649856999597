import styled from 'styled-components';

export const HeaderContainer = styled.header`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0px;
    box-sizing: border-box;
    background: #028322;
    border-bottom: 1px solid #e0e0e0;
    position: sticky;
    top: 0;
    z-index: 100;
    @media screen and (max-width: 600px){
        padding: 0 0px;
    }
`;

export const GoBackButton = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
        color: #026E1F;
    }
`;

export const ProfileButton = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
        color: #fff;
    }
`;

export const Link = styled.a`

    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 49px;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 0px;

    background-color: #028322;
    
    &:hover{
        background-color: #026E1F;
    }

    ${({ active }) => active ? `
        background-color: #026E1F;
        ` : `
        background-color: #028322;
        `}
`;


export const SearchContainer = styled.div`
    width: 400px;
    height: ${props => props.searchFocus ? '50px' : '50px'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    top: 0px;
    z-index: 100;
    overflow: auto;
    flex-wrap: wrap;
    transition: height 0.25s ease-in-out;
    @media screen and (max-width: 600px){
        padding: 0 20px;
        display: none;
    }

    ${({ searchFocus }) => searchFocus ? `

    box-shadow: 0px 4px 6px -4px rgba(0,0,0,0.25);

        ` : `

        `}
`;

export const SearchInput = styled.input`
    width: 400px;
    height: 50px;
    border: none;
    outline: none;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 1rem;
    position: absolute;
    font-weight: 400;
    color: #212121;
    &::placeholder{
        color: #757575;
    }


`;

export const SearchContent = styled.div`
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 0px;
    box-sizing: border-box;
    overflow-y: scroll;
    &::-webkit-scrollbar{
        width: 5px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #028322;
    }
    &::-webkit-scrollbar-track{
        background-color: #e0e0e0;
    }
`;