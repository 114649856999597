import styled from 'styled-components';

export const PreHeaderContainer = styled.div`
    background-color: #585858;
    width: 100vw;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 70px;
    @media screen and (max-width: 600px){
        display: none;
    }
    box-sizing: border-box;
`;

export const BarCodeContainer = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    margin-left: auto;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    padding: 0 20px;
    cursor: pointer;
    &:hover{
        background-color: #383838;
    }
    `;