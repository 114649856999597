import styled from 'styled-components';

export const LoginContainer = styled.div`
    background-color: #fff;
    width: 100%;
    height: 100vh;
    display: flex;
`;

export const LoginImage = styled.div`
    width: 70%;
    height: 100%;

    @media screen and (max-width: 1100px){
        width: 60%;
    }
    @media screen and (max-width: 800px){
        display: none;
    }
`;

export const BackgroundImage = styled.img`
    width: auto;
    height: 100%;
    object-fit: cover;
    object-position: center;
    max-width: 100%;
    `;
export const BackgroundInfo = styled.div`
    width: auto;
    max-width: 500px;
    height: auto;
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #000;
    border-radius: .25rem;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
    @media screen and (max-width: 1100px){
        max-width: 300px;
    }
    @media screen and (max-width: 800px){
        display: none;
    }
    & h3{
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    & p{
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        text-align: balance;
    }

    & a{
        font-size: 14px;
        font-weight: 600;
        color: #000;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 10px;
        transition: all 0.3s ease-in-out;
        &:hover{
            color: #888;
        }
    }
`;

export const LoginContent = styled.div`
    width: 30%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 60px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
    @media screen and (max-width: 1100px){
        width: 40%;
    }
    @media screen and (max-width: 800px){
        width: 100%;
    }
`;

export const Logo = styled.img`
    width: auto;
    height: 65px;
    object-fit: contain;
    margin-bottom: 4rem;
`;
export const Title = styled.h2`
    font-size: 32px;
    font-weight: black;
    margin: 0;
    margin-bottom: 20px;
    Text-align: left;
    font-family: 'Inter', sans-serif;
`;
export const Form = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: auto;
`;

export const Input = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid #BBBABA;
    border-radius: 5px;
    outline: none;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #F5F5F5;
    transition: all 0.2s ease-in-out;
    &:focus{
        box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
        background-color: #F2F2F2;
    }
    `;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    Text-align: left;
    font-family: 'Inter', sans-serif;
`;

export const Button = styled.button`
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 5px;
    background-color: #028322;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color: #026E1F;
    }
`;

export const ALink = styled.a`
    font-size: 14px;
    margin: 0;
    Text-align: left;
    font-family: 'Inter', sans-serif;
    color: #028322;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
    &:hover{
        color: #026E1F;
    }
`;

export const Loader = styled.div`
    //A circular loading animation
    width: 25px;
    height: 25px;
    border: 3px solid #68B67B;
    border-radius: 50%;
    border-top: 3px solid #fff;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }


`;

export const Separator = styled.span`
//------ o ------
        width: 100%;
        height: auto;
        display: flex;

        &::before{
            content: '';
            width: 50%;
            height: 1px;
            background-color: #BBBABA;
            display: block;
            margin-top: 13px;
        }
        &::after{
            content: '';
            width: 50%;
            height: 1px;
            background-color: #BBBABA;
            display: block;
            margin-top: 13px;
        }

`;

export const Span = styled.span`
    font-size: 14px;
    margin: 0;
    Text-align: left;
    font-family: 'Inter', sans-serif;
    color: #028322;
    transition: all 0.3s ease-in-out;
`;