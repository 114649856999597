import {toast} from 'react-toastify';
import AuthService from '../../AuthService';
const API_URL = 'https://api.rb.beeapp.es';
const token = AuthService.getToken();

class OrderService {

    static async getTotalOrders() {
        const response = await fetch(`${API_URL}/users/orders/count`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        const data = await response.json();
        return data || 0;
    }

    static async getTotalOrderAmount() {
        const response = await fetch(`${API_URL}/users/orders/amount`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        const data = await response.json();
        return data || 0;
    }

    static async createOrder(order) {
        const response = await fetch(`${API_URL}/orders/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
        });
        const data = await response.json();
        return data || [];
    }

    static async orderConfirmation(order) {
        const response = await fetch(`${API_URL}/orders/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
        });
        const data = await response.json();
        return data || [];
    }

    static async getOrders() {
        const response = await fetch(`${API_URL}/orders/user`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        const data = await response.json();
        return data || [];
    }

    static async getOrdersInvoicesByPeriod(period) {
        const response = await fetch(`${API_URL}/orders/invoices/${period}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        //if response is a file zip, download it if not return the json
        if (response.headers.get('content-type') === 'application/zip') {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `invoices-${period}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            return {success: true};
        } else {
            const data = await response.json();
            if (data.message) {
                toast.error(data.message);
            }

            return {success: false};
        }
    }



    static async getOrder(id) {
        const response = await fetch(`${API_URL}/orders/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        const data = await response.json();

        return data || {};
    }

}

export default OrderService;