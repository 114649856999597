import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background-color: #fff;
    width: 100vw;
    height: 400px;
    display: flex;
    flex-direction: column;
    border-top: .5px solid #ddd;
    @media screen and (max-width: 600px){
        height: auto;
        box-shadow: none;
    }
`;

export const FooterTop = styled.div`
    width: 100%;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px){
        height: 50px;
    }

`;

export const FooterTopContent = styled.div`
height: 90px;
    width: 400px;
    background-image: url('${props => props.background}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px){
        width: 200px;
        height: 40px;
    }
`;


export const FooterBottom = styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    padding: 30px 0px;
    box-sizing: border-box;

    justify-content: space-evenly;
    align-items: space-evenly;

    flex-wrap: wrap;
    gap: 100px;
    justify-content: center;
    @media screen and (max-width: 600px){
        flex-direction: column;
        gap: 10px;
        padding: 30px;
    }
    `;

export const FooterBottomItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: 100%;
    & span{
        font-size: 14px;
    }
    `;

export const FooterBottomItem = styled.div`
    width: auto;
    height: auto;
    display: flex;

    flex-direction: column;
    `;

export const FooterBottomItemContactItem = styled.div`
    width: 100%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #000;
    &:nth-child(3){
        border-bottom: none;
    }
    `;

    export const FooterBottomItemA = styled.a`
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover{
        text-decoration: underline;
    }
    `;

    export const FooterBottomPowered = styled.div`
    width: 100%;
    height: auto;
    padding: 5px 0px;
    display: flex;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const FooterBottomPoweredIcon = styled.div`
cursor: pointer;
    width: 25px;
    height: 25px;
    background-image: url('${props => props.background}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
    & span{
        display: none;
        margin-left: -150px;
        font-size: 14px;
        color: #CDB900;
        font-weight: 600;
        width: 200px;
    }
    &:hover{
        & span{
            display: block;
        }
    }
`;

export const FooterWhatisHot = styled.div`
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px 5px 0px 0px;
    gap: 10px;
    border-bottom: 3px solid #028322;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    position: fixed;
    bottom: 0;
    left: 10px;
    z-index: 0;
    @media screen and (max-width: 600px){
        flex-direction: column;
        gap: 5px;
    }
    `;

export const FooterWhatisHotTitle = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    font-size: 20px;
    font-weight: 600;
    background-color: #028322;
    color: #fff;

`;

export const FooterWhatisHotContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    `;