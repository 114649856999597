import React, { useState, useEffect, useRef } from 'react';
import { Search } from './Styles/head';
import Typed from 'react-typed';

function InputWithAbortController({ onSearch, onFocus, onClick, onKeyDown, setSearch }) {

  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    return () => {
      // Cleanup function to abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleInputChange = async (event) => {
    setSearchTerm(event.target.value);

    // Abort any pending requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new abort controller
    const newAbortController = new AbortController();

    abortControllerRef.current = newAbortController;
    // Call onSearch with new search term and abort controller

    setSearching(true);
    await onSearch(event.target.value, newAbortController.signal);
  };
  return (
    <Typed
      strings={[
        'Blister de ceras plastidecor',
        'Ref. 0011423',
        'EAN 8410565051348'
      ]}
      typeSpeed={40}
      backSpeed={50}
      attr="placeholder"
      loop >
      <Search type="text" value={searchTerm} onChange={handleInputChange} 
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            window.location.href = `/search?s=${searchTerm}`;
          }
          setSearch(searchTerm);
        
        }}
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='none'
        spellCheck='false'
        
        onFocus={onFocus}
      />
    </Typed>
  );
}

export default InputWithAbortController;