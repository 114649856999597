import React from "react";

import styled from "styled-components";
import { IoLockClosed } from "react-icons/io5";

const ProtectedLayoutContainer = styled.div`
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    `;

const ProtectedLoader = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid #028322;
    border-top: 5px solid transparent;
    animation: spin 1s linear infinite;
    margin: auto;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg)
        }
    }

    `;

const ProtectedLayout = ({ children }) => {
    return (
        <ProtectedLayoutContainer>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
            }}>
                <IoLockClosed size={32} style={{position: 'absolute'}} color="#028322" />

            <ProtectedLoader>
            </ProtectedLoader>
            </div>
            
            <h2 style={{
                fontSize: '20px',
                fontWeight: '400',
                textAlign: 'center',
                margin: '0',
                marginTop: '0px',
                color: '#028322',
                padding: '20px 20px',
            }}>
               Protected by BeeApp
            </h2>
        </ProtectedLayoutContainer>
    )
}

export default ProtectedLayout;