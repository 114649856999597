
export default class CookieConsentAPI {

    //Cookie Consent Have different types of cookies that can be set, ALL, ESSENTIAL, ANALYTICS, MARKETING
    //The user can select which cookies to accept and which ones to reject
    //The user can change the cookies settings at any time
    //The user can delete the cookies at any time
    //The user can accept all cookies at any time
    //The user can reject all cookies at any time
    //The user can accept only essential cookies at any time
    //The user can reject only essential cookies at any time

    //The cookies are stored in cookies
    
    static setCookieConsent(consent){
        const cookieConsent = {
            analytics: consent.Analytics,
            marketing: consent.Marketing,
            activityFollow: consent.activityFollow,
        }
        localStorage.setItem('cookieConsent', JSON.stringify(cookieConsent));
    }

    static getCookieConsent(){
        const cookieConsent = localStorage.getItem('cookieConsent') || JSON.stringify({
            notSet: true,
        });
        return JSON.parse(cookieConsent);
    }

    static deleteCookieConsent(){
        localStorage.removeItem('cookieConsent');
    }

    static acceptAllCookies(){
        const cookieConsent = {
            analytics: true,
            marketing: true,
            activityFollow: true
        }
        localStorage.setItem('cookieConsent', JSON.stringify(cookieConsent));
    }

    static rejectAllCookies(){
        const cookieConsent = {
            analytics: false,
            marketing: false,
            activityFollow: false
        }
        localStorage.setItem('cookieConsent', JSON.stringify(cookieConsent));
    }

    //Check the cookies consent and return the cookies that are accepted
    static checkCookieConsent(){
        const cookieConsent = this.getCookieConsent();
        const cookies = {
            analytics: cookieConsent.analytics,
            marketing: cookieConsent.marketing,
            activityFollow: cookieConsent.activityFollow
        }
        return cookies;
    }

    static isCookieConsentSet(){
        const cookieConsent = this.getCookieConsent();
        return cookieConsent.notSet ? false : true;
    }

    //Check if the user has accepted all cookies


}