import React from "react"
import ContentLoader from "react-content-loader"

const OfferLoader = (props) => (
  <ContentLoader 
    speed={1}
    width={550}
    height={230}
    viewBox="0 0 550 230"
    backgroundColor="#cfcfcf"
    foregroundColor="#c2c2c2"
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="250" height="230" /> 
    <rect x="260" y="4" rx="5" ry="5" width="288" height="33" /> 
    <rect x="260" y="45" rx="5" ry="5" width="288" height="88" /> 
    <rect x="260" y="141" rx="5" ry="5" width="130" height="23" /> 
    <rect x="258" y="173" rx="5" ry="5" width="269" height="28" />
  </ContentLoader>
)

export default OfferLoader
