import React, {useEffect, useState, useRef} from 'react';
import {
    FooterContainer,
    FooterTop,
    FooterBottom,
    FooterTopContent,
    FooterBottomItem,
    FooterBottomItemContactItem,
    FooterBottomItemContainer,
    FooterBottomItemA,
    FooterBottomPowered,
    FooterBottomPoweredIcon,
    FooterWhatisHot,
    FooterWhatisHotTitle,
    FooterWhatisHotContent
} from './style';
import { MdWhatshot } from "react-icons/md";

import {HiOutlineLocationMarker} from 'react-icons/hi';
import {AiOutlineMail} from 'react-icons/ai';
import {BsTelephone, BsFacebook, BsInstagram} from 'react-icons/bs';
import logo from '../../assets/logo.png';
import beelogo from '../../assets/beelogo.svg';
import Button from '../Miscellaneous/Button';
import AuthService from '../../AuthService';
import { Input } from '../../Screens/Login/style';
import { MdOutlineSupportAgent } from "react-icons/md";
import { FiSettings } from 'react-icons/fi';
import { UtilsContext } from '../../Context/UtilsContext';
import {Tooltip} from 'react-tooltip'
import { useAuth } from '../../Context/Auth';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';

export default function Footer() {
    const {loggedIn} = useAuth()
    const [year, setYear] = useState(0);
    const [code, setCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const {setShowCookieConsent} = React.useContext(UtilsContext);
    const [showWhatsHot, setShowWhatsHot] = useState(true);
    const tel = useRef(null);
    useEffect(() => {
        setYear(new Date().getFullYear());
        //check if localstorage closedWhatsHot is true
        const closedWhatsHot = localStorage.getItem('closedWhatsHot');
        if(closedWhatsHot){
            setShowWhatsHot(false);
        }else{
            setShowWhatsHot(true);
        }
    }, [])

    function generateCode(){

        //simulate tel click

        tel.current.click();



        setLoading(true);
        AuthService.supportCode().then((code) => {
            console.log(code);
            setCode(code.code);
            setLoading(false);
        });

    }

    return (
        <>
        <FooterContainer>
            <a href='tel:601550264' style={{display: 'none'}} id='tel' ref={tel}>601550264</a>
            <FooterTop>
                <FooterTopContent
                    background={logo}
                    />

            </FooterTop>
            <Tooltip
                        anchorSelect="#contacto"
                        clickable={true}
                    >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.141878950449!2d-5.6313490999999996!3d37.0779276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0d7bf621def933%3A0x7ad2deb497f6806b!2sDistribuciones%20RB!5e0!3m2!1ses!2ses!4v1708649770953!5m2!1ses!2ses" width="400" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Tooltip>
            <FooterBottom>
                <FooterBottomItem>
                    <a id='contacto' href='https://maps.app.goo.gl/CsBP3gzzzBrNQ8gh7'
                        target='_blank'
                        rel='noreferrer'
                        style={{
                            textDecoration: 'none',
                            color: 'black'
                        }}
                    >
                    <FooterBottomItemContactItem>
                        <HiOutlineLocationMarker
                            size={30}
                            />
                            <FooterBottomItemContainer>
                                <span>
                                    Distribuciones RB
                                </span>
                                <span>
                                Calle Rio Guadalquivir, 11
                                </span>
                                <span>
                                    41760
                                </span>
                                <span>
                                El Coronil, España
                                </span>
                                </FooterBottomItemContainer>
                    </FooterBottomItemContactItem>
                    </a>

                    <FooterBottomItemContactItem
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <AiOutlineMail
                            size={30}
                            />
                            <span>
                                <a>info@distribucionesrb.com</a>
                            </span>
                    </FooterBottomItemContactItem>
                    <FooterBottomItemContactItem
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                    >
                        <BsTelephone
                            size={30}
                            />
                            <span>
                                601 550 264
                            </span>

                    </FooterBottomItemContactItem>

                    </FooterBottomItem>


                    <FooterBottomItem
                        style={{
                            gap: '10px',
                            padding: '20px 0px'
                        }}
                    >
                    <FooterBottomItemA>Contacto</FooterBottomItemA>
                    <Link to='/envios-y-devoluciones'
                        style={{
                            textDecoration: 'none',
                            color: 'black',
                        }}
                    >
                        <FooterBottomItemA>Envío y devoluciones</FooterBottomItemA>
                    </Link>
                        <Link to='/sobre-nosotros'
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                            }}
                        >
                        <FooterBottomItemA>Sobre Nosotros</FooterBottomItemA>
                        </Link>
                        <Link
                            to='/politica-de-privacidad'
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                            }}
                        >
                        <FooterBottomItemA>Política de Privacidad</FooterBottomItemA>
                        </Link>
                        <Link
                            to='/aviso-legal'
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                            }}
                        >
                        <FooterBottomItemA>Aviso Legal</FooterBottomItemA>
                        </Link>
                        <FooterBottomItemA
                            onClick={() => setShowCookieConsent(true)}
                        ><FiSettings/> Configuración de Cookies</FooterBottomItemA>
                    </FooterBottomItem>
                    <FooterBottomItem
                                            style={{
                                                gap: '10px',
                                                padding: '20px 0px'

                                            }}
                    >
                    <FooterBottomItemA>Nuevos productos</FooterBottomItemA>
                        <FooterBottomItemA>Más vendidos</FooterBottomItemA>
                        <FooterBottomItemA>Novedades</FooterBottomItemA>
                        <FooterBottomItemA>Especiales</FooterBottomItemA>
                    </FooterBottomItem>
                    <FooterBottomItem
                                            style={{
                                                alignItems: 'center',
                                                gap: '20px',
                                            }}
                    >
                        <h2 
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                margin: '0',
                                marginBottom: '0px',
                                textAlign: 'left',
                                fontFamily: 'Inter, sans-serif',

                            }}
                        >
                            NUESTRAS REDES
                        </h2>
                        <div
                            style={{
                                display: 'flex',
                                gap: '40px',
                            }}
                        >
                            <BsFacebook
                                size={30}
                                />
                            <BsInstagram
                                size={30}
                                />
                        </div>
                        {
                            code ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '3px',
                                        width: '150px',
                                    }}
                                >
                                <Input value={code}
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        width: '100px',
                                        textAlign: 'center',
                                    }}
                                    disabled
                                >
                                    
                                </Input>
                                <p
                                    style={{
                                        fontSize: '10px',
                                        margin: '0',
                                        textAlign: 'center',
                                        width: '100px',
                                        color: '#5e5e5e',
                                        fontStyle: 'italic',
                                    
                                    }}
                                >
                                    Dinos este código para recibir ayuda
                                </p>
                                </div>
                            ) : (
                                loggedIn &&
                                <Button
                                boxShadow
                                loading={loading}
                                onClick={() => generateCode()}
                            >
                                <MdOutlineSupportAgent
                                    size={20}
                                    style={{
                                        marginRight: '5px',
                                    }}
                                />
                                Necesito Ayuda
                            </Button>
                            )
                        }

                    </FooterBottomItem>
            </FooterBottom>
            <FooterBottomPowered>
                <span
                    style={{
                        marginLeft: 'auto',
                    }}
                >
                    Copyright {year} - Todos los derechos reservados.
                </span>
                <FooterBottomPoweredIcon
                    background={beelogo}
                    >
                        <span>
                            Powered by BeeApp</span>
                    </FooterBottomPoweredIcon>
            </FooterBottomPowered>
        </FooterContainer>
        {
            loggedIn && showWhatsHot && (
                <motion.div 
                //scale in with transform translatey and opacity animation
                initial={{opacity: 0,}}
                animate={{ opacity: 1}}
                transition={{duration: 0.5}}
                
            >
            <FooterWhatisHot>
                <FooterWhatisHotTitle>
                <MdWhatshot size={48}/>
                ¿Qué hay nuevo?
                </FooterWhatisHotTitle>
                <FooterWhatisHotContent>
                <p
                    style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        gap: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <strong>¡Obten todas tus facturas por periodo de facturación!</strong><br/>
                    Sabemos lo tedioso e importante que es tener todas tus facturas en un solo lugar, por eso hemos creado un sistema que te permite descargar todas tus facturas por periodo de facturación. <br/>
                    Accede a tu perfil y descarga tus facturas.
                </p>
                <Button outline
                    onClick={() => {
                        localStorage.setItem('closedWhatsHot', true);
                        setShowWhatsHot(false);
                    }}
                >
                    Cerrar
                </Button>
                </FooterWhatisHotContent>
            </FooterWhatisHot>
            </motion.div>
            )
        }


        </>
    )
}