import { Loader } from '../../../Screens/Login/style';
import { CardContainer } from './style';
export default function Card(props){
    return (
        <CardContainer {...props}>
            {
                props.loading ? <Loader /> : props.children
            }
        </CardContainer>
        
    );
}