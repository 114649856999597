import styled from 'styled-components';

export const NotFoundContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 70px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 100px 0px;
`;

export const NotFoundImage = styled.img`
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: 500px;
    object-fit: contain;
`;

export const NotFoundText = styled.p`
    font-size: 3rem;
    font-weight: 600;
    color: #028322;
    text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding: 0rem;
`;

export const NotFoundExplanation = styled.p`
    font-size: 1.5rem;
    font-weight: 400;
    color: #028322;
    text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding: 0rem;
`;