import styled from "styled-components";

export const ExplanationContainer = styled.div`

    width: 350px;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    padding: 1rem 1rem;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;
    min-height: 160px;
    border: 1px solid #028322;

    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.25);
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 800px){
        width: 100%;
        min-height: 100px;
    }

    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(0deg);
        
        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
          to right, 
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0.0) 100%
        );
        }
      
      &:hover:after {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }
      
      
      &:active:after {
        opacity: 0;

      }



    &:hover {
        transform: scale(1.05) rotate(-0.5deg);
        box-shadow: none;
    
    }
    @media screen and (max-width: 800px){
        width: 100%;
    }
`;

export const ExplanationIcon = styled.div`
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    background-color: #028322;
    color: #fff;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    right: 0;
    top: 0px;
    @media screen and (max-width: 800px){
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        font-size: 1rem;
    }
`;

export const ExplanationTitle = styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    color: #028322;
    @media screen and (max-width: 800px){
        font-size: 1rem;
    }
`;

export const ExplanationText = styled.p`

    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    color: #028322;
    @media screen and (max-width: 800px){
        font-size: 0.8rem;
    }
`;

export const ExplanationBody = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
`;