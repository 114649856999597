import React, {useEffect, useState} from "react";
import { ALink, Button, Form, Input, Label, LoginContainer, LoginContent,Loader, LoginImage, Logo, Title, Separator, Span, BackgroundImage, BackgroundInfo } from "./style";
import logo from "../../assets/logo.png";
import { toast } from "react-toastify";
import AuthService from "../../AuthService";
import { useNavigate, Navigate } from "react-router-dom";
import { LayoutGroup, motion } from "framer-motion";
//get query string from url
import { useLocation } from "react-router-dom";
import { useAuth } from "../../Context/Auth";
import { GoogleLogin } from '@react-oauth/google';
import Backgrounds from "../../Components/Backgrounds";
import { FaUser } from "react-icons/fa";
import { DetailsBackdrop, DetailsModal, DetailsModalDescription, DetailsModalFooter, DetailsModalTitle } from "../Details/style";
import { IoIosClose } from "react-icons/io";
import Envelope from "../../assets/envelope.png";
import { usePostHog } from 'posthog-js/react'

export default function LoginScreen() {
    const posthog = usePostHog();
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [cif, setCif] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [recovery, setRecovery] = useState(false);
    const history = useNavigate(); // Get the history object
    const { login } = useAuth(); // Get the login function from the AuthContext
    const { search } = useLocation(); // Get the query string from the url
    const r = new URLSearchParams(search).get("r"); // Get the r parameter from the query string
    const l = new URLSearchParams(search).get("l"); // Get the l parameter from the query string
    const [SelectedBackground, setSelectedBackground] = useState(null);//The selected background
    //Hook when the user press enter, it will make the login
    const [islogin, setLogin] = useState(true);
    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            makeLogin();
        }
    }

    useEffect(() => {
        if(l){
            setLogin(false);
        }
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        }
    });

    useEffect(() => {
        //Get a random background
        setSelectedBackground(Backgrounds[Math.floor(Math.random() * Backgrounds.length)]);
    }, []);


   const makeLogin = () => {
        setLoader(true);
        if(islogin){
        login(email, pass).then((user) => {
            toast.success("Bienvenido");

            if(r){
                posthog?.identify(user?.Name);
                //check if r is a valid url from the same domain
                const regex = new RegExp("^(http|https)://");
                if(regex.test(r)){
                    toast.error("La url de redireccion no es valida", {
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: false,
                    }
                        );
                    return;
                }else {
                window.location.href = r;
                return;
                }
            }
            history("/");
        }).catch((err) => {
            console.log(err);
            toast.error(err+"", { 
                hideProgressBar: true,
                closeOnClick: true,
                draggable: false,
                position: toast.POSITION.BOTTOM_CENTER
            }
                );
                

            setLoader(false);
        });
    }else{
        //Register
        AuthService.register(name, surname, email, pass, cif).then((user) => {
            setModal(true);
            setLoader(false);
        }).catch((err) => {
            console.log(err);
            toast.error("Error al registrar el usuario", {
                hideProgressBar: true,
                closeOnClick: true,
                draggable: false,

                position: toast.POSITION.BOTTOM_CENTER
                
            }
                );
            setLoader(false);
        });
    }
    }

    return (<>
        <LoginContainer>
            <LoginImage>
            <BackgroundImage
                srcset={
                    `
                    ${SelectedBackground?.images[0].url} 320w,
                    ${SelectedBackground?.images[1].url} 480w,
                    ${SelectedBackground?.images[2].url} 800w

                    `
                }
                sizes="(max-width: 320px) 280px,
                            (max-width: 480px) 440px,
                            800px"
                src={SelectedBackground?.images[1].url}
                alt={
                    SelectedBackground?.title
                } />
               <BackgroundInfo>
                     <h3>{SelectedBackground?.title}</h3>
                     <p>{SelectedBackground?.description}</p>
                     <a
                        href={SelectedBackground?.author.url}
                     >
                        <FaUser style={{marginRight: "5px"}}/>
                        {SelectedBackground?.author.name}
                     </a>
               </BackgroundInfo>
            </LoginImage>
            <LoginContent>
                <Logo src={logo} alt="Distribuciones RB" />
  
                <LayoutGroup>
                <motion.div
                //Depending on the islogin state, it will show the login or register form
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                style={{display: islogin ? "block" : "none"}}
                layout
                >
                <Title>Iniciar Sesión</Title>
                {/* <GoogleLogin
                  onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  />
                <span style={{textAlign: "center", display: "block", marginBottom: "10px"}}></span> */}
                <Form>
                    <Label htmlFor="email">Correo Electronico</Label>
                    <Input type="text" name="email" placeholder="Introduce su correo electronico" 
                    onChange={(e) => setEmail(e.target.value)}
                    />
                    <Label htmlFor="pass">Contraseña</Label>
                    <Input name="pass" type="password" placeholder="Introduce su contraseña" 
                    onChange={(e) => setPass(e.target.value)}
                    />
                    <Button
                    onClick={() => makeLogin()}
                    >
                        {loader ? <Loader/> : "Iniciar Sesión"}
                    </Button>
                    <ALink 
                    onClick={() => setRecovery(true)}
                    >¿Has olvidado tu contraseña?</ALink>
                    <Separator>&nbsp;&nbsp;o&nbsp;&nbsp;</Separator>
                    <Span>¿No tienes cuenta? <ALink href="#" onClick={() => setLogin(false)}>Crea una dando click aquí</ALink></Span>
                </Form>
                </motion.div>
                <motion.div
                transition={{duration: 0.5}}
                style={{display: islogin ? "none" : "block"}}
                //An animation to show the register form
                layout
                >   
                <Title>Registrarse</Title>
                <Form>
                    <div style={{display: "flex", gap: "10px"}}>
                        <div style={{flex: "1"}}>
                <Label htmlFor="name">Nombre</Label>
                    <Input name="name" type="text" placeholder="Introduce su nombre" 
                    onChange={(e) => setName(e.target.value)}
                    />
                    </div>
                    <div style={{flex: "1"}}>
                    <Label htmlFor="surname">Apellidos</Label>
                    <Input name="surname" type="text" placeholder="Introduce sus apellidos" 
                    onChange={(e) => setSurname(e.target.value)}
                    />
                    </div>
                    </div>
                    <Label htmlFor="phone">Teléfono</Label>
                    <Input type="text" name="phone" placeholder="Introduce su teléfono" 
                    onChange={(e) => setPhone(e.target.value)}
                    /> 
                    <Label htmlFor="email">Correo Electronico</Label>
                    <Input type="text" name="email" placeholder="Introduce su correo electronico" 
                    onChange={(e) => setEmail(e.target.value)}
                    />
                    <Label htmlFor="cif">CIF</Label>
                    <Input name="cif" type="text" placeholder="Introduce su CIF" 
                    onChange={(e) => setCif(e.target.value)}
                    />
                    <Label htmlFor="pass">Contraseña</Label>
                    <Input name="pass" type="password" placeholder="Introduce su contraseña" 
                    onChange={(e) => setPass(e.target.value)}
                    />
                    <Button
                    onClick={() => makeLogin()}
                    >
                        {loader ? <Loader/> : "Registrarse"}
                    </Button>
                    <ALink href="#">¿Has olvidado tu contraseña?</ALink>
                    <Separator>&nbsp;&nbsp;o&nbsp;&nbsp;</Separator>
                    <Span>¿Ya tienes cuenta? <ALink href="#" onClick={() => {
                        setLogin(true);
                        //change the url param &l=0 to delete it
                        history(window.location.pathname);
                    }}>Accede dando click aquí</ALink></Span>
                </Form>
                </motion.div>
                </LayoutGroup>
            </LoginContent>
        </LoginContainer>
        {
            modal && (
        
        <DetailsBackdrop>
            <motion.div
            initial={{opacity: 0, scale: 0.5, top: "-100%"}}
            animate={{opacity: 1, scale: 1, top: "10px"}}
            transition={{duration: 0.5}}
            style={{position: "absolute", top: "10px", right: "10px", zIndex: 1000}}
            >
                <DetailsModal>
                <span style={{
                        float: 'right',
                        marginLeft: 'auto',
                        padding: 10,
                        borderRadius: 50,
                        height: 20,
                        width: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#028322',
                        border: '0px solid #028322',
                        cursor: 'pointer',
                        fontSize: 40,
                    }} onClick={() => window.location.href = '/login'}>
                        <IoIosClose size={42}/>
                    </span>
                    <img src={Envelope} alt="Correo enviado" style={{width: "100px", height: "100px", margin: "0 auto"}}/>
                    <DetailsModalTitle>
                        Se ha recibido su solicitud de registro.
                    </DetailsModalTitle>
                    <DetailsModalDescription>
                        Nuestro equipo revisará su solicitud y en breve se pondrá en contacto con usted, puede llamar al <a style={{
                            color: "#028322",
                            textDecoration: "none",
                            borderBottom: "1px solid #028322",
                            padding: "0 5px",
                            fontWeight: "bold"
                        
                        }} href="tel:+34 601550254">+34 601 550 264</a> si necesita más información.
                    </DetailsModalDescription>
                    <DetailsModalFooter>
                        <Button onClick={() =>{
                            window.location.href = '/login'
                        }}>Cerrar</Button>
                    </DetailsModalFooter>
                </DetailsModal>
            </motion.div>
        </DetailsBackdrop>
            )
        }
                {
            recovery && (
        
        <DetailsBackdrop>
            <motion.div
            initial={{opacity: 0, scale: 0.5, top: "-100%"}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.25}}
            style={{ zIndex: 1000}}
            >
                <DetailsModal>
                <span style={{
                        float: 'right',
                        marginLeft: 'auto',
                        padding: 10,
                        borderRadius: 50,
                        height: 20,
                        width: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#028322',
                        border: '0px solid #028322',
                        cursor: 'pointer',
                        fontSize: 40,
                    }} onClick={() => setRecovery(false)}>
                        <IoIosClose size={42}/>
                    </span>
                    <img src={Envelope} alt="Correo enviado" style={{width: "100px", height: "100px", margin: "0 auto"}}/>
                    <DetailsModalTitle>
                        ¡Parece que tienes problemas para iniciar sesión!
                    </DetailsModalTitle>
                    <DetailsModalDescription>
                        Puede contactar con nosotros al <a style={{
                            color: "#028322",
                            textDecoration: "none",
                            borderBottom: "1px solid #028322",
                            padding: "0 5px",
                            fontWeight: "bold"
                        
                        }} href="tel:+34 601550254">+34 601 550 264</a> o escribirnos a <a style={{
                            color: "#028322",
                            textDecoration: "none",
                            borderBottom: "1px solid #028322",
                            padding: "0 5px",
                            fontWeight: "bold"
                        
                        }} href="mailto:info@distribucionesrb.com">info@distribucionesrb.com</a>.
                        Nuestro equipo le ayudará a recuperar su cuenta lo antes posible para que pueda seguir disfrutando de nuestros servicios.
                    </DetailsModalDescription>
                    <DetailsModalFooter>
                        <Button onClick={() =>{
                            setRecovery(false);
                        }}>Cerrar</Button>
                    </DetailsModalFooter>
                </DetailsModal>
            </motion.div>
        </DetailsBackdrop>
            )
        }
        </>
    );
}

