import React, {useRef, useState, useEffect} from "react";
import Layout from "../../../Components/Layout";
import { Layout20,Layout80, LayoutContainer, Input, TextArea, CategoryContainer, Category, SectionH2, InputFile, DoubleInput, InputLikeCategory, ProductsAlternativeContainer, ProductsAlternative, ProductsAlternativeSearch, ProductsAlternativeSearchInput, ProductsAlternativeSearchResults, ProductsAlternativeSearchResultItem, ProductsAlternativeAdded, ProductsAlternativeAddedPill } from "./productCreator/style";
import { H1 } from "./style";
import { HomeItemContainer, HomeItemImage, HomeItemInfo, HomeItemReference, HomeItemTitle, OfferItemAddToCartButton, OfferItemAddToCartContainer, OfferItemAddToCartSelect, OfferItemDiscount, OfferItemOldPrice, OfferItemPrice, OfferItemPriceContainer } from "../../Home/style";
import { BiSolidCart } from "react-icons/bi";
import { Spacer } from "@chakra-ui/react";
import Button from "../../../Components/Miscellaneous/Button";
import { LiaTimesCircleSolid } from "react-icons/lia";
import { BiPlus } from "react-icons/bi";
import AuthService from "../../../AuthService";
import { Portal } from "react-portal";
import Modal from "../../../Components/Miscellaneous/Modal";
import ProductService from "../../../Utils/API/products";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
export default function ProductEdit() {

    const fileInput = useRef(null);
    const {id} = useParams();
    const [tags, setTags] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [price, setPrice] = useState(0);
    const [stock, setStock] = useState(0);
    const [ean, setEan] = useState('');
    const [reference, setReference] = useState('');
    const [discounts, setDiscounts] = useState([]);
    const [q, setQ] = useState(1);
    const [priceDiscounts, setPriceDiscounts] = useState(null);
    const [quantityDiscounts, setQuantityDiscounts] = useState(null);
    const [searchItems, setSearchItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [search, setSearch] = useState('');
    const [alternativeProducts, setAlternativeProducts] = useState([]);  
    const [aProductShow, setAProductShow] = useState(false);
    /**
     * {
  "name": "",
  "description": "",
  "category": [],
  "productURL": "[category[0]]/name_slashed",
  "price": price,
  "discounted": false,
  "stock": stock,
  "store": "DistribucionesRB",
  "EAN": "EAN",
  "reference": "Reference",
  "tags": [],
  "quantityStep": quantityStep,
  "alternatives": [],
  "discounts": [],
}
     */

    const fetchProduct = async () => {
        fetch('https://api.rb.beeapp.es/admin/product/'+id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + AuthService.getToken()
            }
        })
        .then(response => response.json())
        .then(data => {
            setCategories(data.category);
            setName(data.name);
            console.log(data.name);
            setDescription(data.description);
            setPrice(data.price);
            setStock(data.stock);
            setTags(data.tags);
            setEan(data.EAN);
            setReference(data.reference);
            setQ(data.quantityStep);
            setDiscounts(data.discounts || []);
            setAlternativeProducts(data.alternatives.map(item => item.productID) || []);
            setImage(data.images[0].replace('https://cdn.beeapp.es/', ''));
        })
        .catch(error => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchProduct();
    }, []);

    function makeSearch(search){
        ProductService.searchProducts(search).then(res => {
            //change objectID to _id
            res = res.map((item) => {
                return {
                    ...item,
                    productID: item.objectID,
                    
                    _id: item.objectID
                }
            });
            //delete objectID
            res = res.map((item) => {
                delete item.objectID;
                return item;
            });
            //delete _highlightResult
            res = res.map((item) => {
                delete item._highlightResult;
                return item;
            });

            setSearchItems(res);

            console.log(searchItems);
        }
        ).catch(err => {
            console.log(err);
        });
    }

    const createProduct = () => {
        const product = {
            name,
            description,
            category: categories,
            images: ["https://cdn.beeapp.es/"+image],
            productURL: `${categories[0]}/${name.replaceAll(' ', '-').replaceAll('/','').toLowerCase()}`,
            price,
            discounted: false,
            stock,
            store: 'DistribucionesRB',
            EAN: ean,
            reference: reference,
            tags,
            quantityStep: q,
            alternatives: alternativeProducts.length >= 1 ? alternativeProducts.map((item) => {
                return item?.productID;
            }) : [],
            discounts
        }

        if(categories.length === 0){
            toast.error('Debes seleccionar al menos una categoría');
            return;
        }

        fetch('https://api.rb.beeapp.es/products/'+id, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + AuthService.getToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(product)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            toast.success('Producto actualizado correctamente');
        })
        .catch(error => {
            console.error(error);
        })

    }


    const handleFileInput = (e) => {
        const file = e.target.files[0];

        //upload file to /admin/imageUpload

        const formData = new FormData();
        formData.append('image', file);

        fetch('https://api.rb.beeapp.es/admin/imageUpload', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + AuthService.getToken()
            },
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            setImage(data.imagename);
        })
        .catch(error => {
            console.error(error);
        })


    }


    const spanishPrepositions = new Set([
        'a', 'ante', 'bajo', 'con', 'contra', 'de', 'desde', 'durante', 'en',
        'entre', 'hacia', 'hasta', 'mediante', 'para', 'por', 'según', 'sin',
        'sobre', 'tras', 'aunque', 'como', 'cuando', 'donde', 'mientras',
        'porque', 'si', 'siempre', 'también', 'todavía', 'ya'
      ]);

      const spanishDeterminers = new Set([
        'este', 'esta', 'esto', 'estos', 'estas',
        'ese', 'esa', 'eso', 'eses', 'esas',
        'aquel', 'aquella', 'aquello', 'aquellos', 'aquellas',
        'el', 'la', 'los', 'las',
        'un', 'una', 'unos', 'unas'
      ]);

    function addTag(e){
        if(e.key === 'Enter'){
            setTags([...tags, e.target.value]);
            e.target.value = '';
        }
    }

    const deleteProduct = (id) => {

        const confirm = window.confirm('¿Estás seguro de que quieres eliminar este producto?');
        if(!confirm) return;

        fetch('https://api.rb.beeapp.es/products/'+id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + AuthService.getToken()
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            toast.success('Producto eliminado correctamente');
        })
        .catch(error => {
            console.error(error);
        })
    }

    function addDiscount(){
        /**
         *     {
      minQuantity: {
        type: Number,
        required: true,
        min: 1,
      },
      discountedPrice: {
        type: Number,
        required: true,
      },
    },
         */

        setDiscounts([...discounts, {
            minQuantity: quantityDiscounts,
            discountedPrice: priceDiscounts
        }]);


    }

    function setNameValue(e){
        setName(e.target.value);
        //split by space and add to tags

        //if the word is a preposition, the word before and after are united with dash


        const splitted = e.target.value.split(' ');


        //eliminate determiners
        splitted.filter((item) => {
            return !spanishDeterminers.has(item);
        }
        );

        splitted.map((item, index) => {
            if(spanishPrepositions.has(item)){
                //create a new string with the previous and next word and the preposition with a dash
                //in case the next word is the last one, the dash is not added

                if(index === splitted.length - 1){
                    splitted[index - 1] = splitted[index - 1] + '-' + item;
                    //remove the preposition
                    splitted.splice(index, 1);
                    return;
                }

                splitted[index - 1] = splitted[index - 1] + '-' + item + '-' + splitted[index + 1];
                //remove the next word
                splitted.splice(index + 1, 1);
                //remove the preposition
                splitted.splice(index, 1);

            }
        }
        );

        //filter empty strings
        splitted.filter((item) => {
            return item !== '';
        });

        //filter tags that are already in the array
        splitted.filter((item) => {
            return !tags.includes(item);
        });

        //only remove the tags of name
        splitted.filter((item) => {
            return !name.includes(item);
        });


        setTags([...splitted]);

    }

    return (
        <LayoutContainer>
            <Layout80>
            <H1>Actualizar Producto</H1>
            <Input
                placeholder='Nombre'
                onChange={setNameValue}
                value={name}
                />
            <TextArea placeholder="Descripción"
                value={description}
            onChange={(e) => setDescription(e.target.value)}
            ></TextArea>
            <SectionH2>Categoría</SectionH2>
            <CategoryContainer>
            <Category
                onClick={() => {
                    if(categories.includes('ofertas')){
                        setCategories(categories.filter((item) => {
                            return item !== 'ofertas';
                        }));
                }
                else{
                    setCategories([...categories, 'ofertas']);
                }
            }
                }
                selected={categories.includes('ofertas')}
                >
                    Ofertas
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('escritura-y-correccion')){
                        setCategories(categories.filter((item) => {
                            return item !== 'escritura-y-correccion';
                        }));
                }
                else{
                    setCategories([...categories, 'escritura-y-correccion']);
                }
            }
                }
                selected={categories.includes('escritura-y-correccion')}
                >
                    Escritura y Corrección
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('productos-de-oficina')){
                        setCategories(categories.filter((item) => {
                            return item !== 'productos-de-oficina';
                        }));
                }
                else{
                    setCategories([...categories, 'productos-de-oficina']);
                }
            }
                }
                selected={categories.includes('productos-de-oficina')}
                >
                    Productos de Oficina
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('productos-escolares')){
                        setCategories(categories.filter((item) => {
                            return item !== 'productos-escolares';
                        }));
                }
                else{
                    setCategories([...categories, 'productos-escolares']);
                }
            }
                }
                selected={categories.includes('productos-escolares')}
                >
                    Productos Escolares
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('etiquetas-y-embalajes')){
                        setCategories(categories.filter((item) => {
                            return item !== 'etiquetas-y-embalajes';
                        }));
                }
                else{
                    setCategories([...categories, 'etiquetas-y-embalajes']);
                }
            }
        }
            selected={categories.includes('etiquetas-y-embalajes')}
                >
                Etiquetas y Embalajes
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('papel-cuadernos-y-sobres')){
                        setCategories(categories.filter((item) => {
                            return item !== 'papel-cuadernos-y-sobres';
                        }));
                }
                else{
                    setCategories([...categories, 'papel-cuadernos-y-sobres']);
                }
            }
        }
            selected={categories.includes('papel-cuadernos-y-sobres')}
                >
                Papel, Cuadernos y Sobres
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('archivo-y-clasificacion')){
                        setCategories(categories.filter((item) => {
                            return item !== 'archivo-y-clasificacion';
                        }));
                }
                else{
                    setCategories([...categories, 'archivo-y-clasificacion']);
                }
            }
        }
            selected={categories.includes('archivo-y-clasificacion')}
                >
                Archivo y Clasificacion
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('consumibles-y-accesorios-informaticos')){
                        setCategories(categories.filter((item) => {
                            return item !== 'consumibles-y-accesorios-informaticos';
                        }));
                }
                else{
                    setCategories([...categories, 'consumibles-y-accesorios-informaticos']);
                }
            }
        }
            selected={categories.includes('consumibles-y-accesorios-informaticos')}
                >
                Consumibles y Accesorios Informaticos
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('mobiliario-de-oficina')){
                        setCategories(categories.filter((item) => {
                            return item !== 'mobiliario-de-oficina';
                        }));
                }
                else{
                    setCategories([...categories, 'mobiliario-de-oficina']);
                }
            }
        }
            selected={categories.includes('mobiliario-de-oficina')}
                >
                Mobiliario de Oficina
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('organizacion')){
                        setCategories(categories.filter((item) => {
                            return item !== 'organizacion';
                        }));
                }
                else{
                    setCategories([...categories, 'organizacion']);
                }
            }
        }
                selected={categories.includes('organizacion')}
                >
                Organizacion
                </Category>
                <Category
                onClick={() => {
                    if(categories.includes('juegos')){
                        setCategories(categories.filter((item) => {
                            return item !== 'juegos';
                        }));
                }
                else{
                    setCategories([...categories, 'juegos']);
                }
            }
        }
            selected={categories.includes('juegos')}
                >
                Juegos
                </Category>
            </CategoryContainer>
            <SectionH2>Imagen</SectionH2>
            <InputFile type="file" ref={fileInput}
            onChange={(e) => handleFileInput(e)}

>

            </InputFile>
            <label htmlFor="file" 
                onClick={() => {
                    fileInput.current.click();
                }
                }
            >Seleccionar archivo</label>
            <DoubleInput>
                <Input placeholder="Precio" type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                ></Input>
                <Input placeholder="Stock" type="number"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                ></Input>
            </DoubleInput>
            <SectionH2>Tags</SectionH2>
            <CategoryContainer>
                <InputLikeCategory placeholder="Tag"
                onKeyUp={addTag}
                ></InputLikeCategory>
                {
                    tags.map((tag, index) => {
                        return <Category key={index}>{tag}
                        <button style={{
                            border: 'none',
                            background: 'none',
                            marginLeft: 'auto',
                            cursor: 'pointer',
                            outline: 'none',
                            color: 'currentColor'
                        }}  
                        onClick={() => {
                            setTags(tags.filter((item) => {
                                return item !== tag;
                            }));
                        }}
                        >
                            <LiaTimesCircleSolid size={16}/>
                        </button>
                        </Category>
                    })
                }
            </CategoryContainer>
            <DoubleInput>
                <Input placeholder="EAN" type="text"
                value={ean}
                onChange={(e) => setEan(e.target.value)}
                ></Input>
                <Input placeholder="Referencia" type="text"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                ></Input>
                <Input placeholder="Cantidad Mínima" type="number"
                value={q}
                onChange={(e) => setQ(e.target.value)}
                ></Input>
            </DoubleInput>
            <SectionH2>Descuentos</SectionH2>
                {
                    discounts.length === 0 ? (
                        null
                    ) : (
                        
                        discounts.map((discount, index) => {
                            return (
                                <DoubleInput key={index}>
                                    <Input placeholder="Precio" type="number"
                                    value={discount.discountedPrice}
                                    onChange={(e) => {
                                        setDiscounts(discounts.map((item, i) => {
                                            if(i === index){
                                                return {
                                                    ...item,
                                                    discountedPrice: e.target.value
                                                }
                                            }
                                            return item;
                                        }));
                                    }}
                                    ></Input>
                                    <Input placeholder="Cantidad" type="number"
                                    value={discount.minQuantity}
                                    onChange={(e) => {
                                        setDiscounts(discounts.map((item, i) => {
                                            if(i === index){
                                                return {
                                                    ...item,
                                                    minQuantity: e.target.value
                                                }
                                            }
                                            return item;
                                        }));
                                    }}
                                    ></Input>
                                    <Button primary
                                        customStyle={{
                                            height: 'auto',
                                            width: '50px !important'
                                        }}
                                        onClick={() => {
                                            setDiscounts(discounts.filter((item, i) => {
                                                return i !== index;
                                            }));
                                        }}
                                    >
                                        <LiaTimesCircleSolid size={24}/>
                                    </Button>
                                </DoubleInput>
                            )
                        }
                        )
                        
                    )
                }
                                        <DoubleInput>
                        <Input placeholder="Precio" type="number"
                        value={priceDiscounts}
                        onChange={(e) => {
                            setPriceDiscounts(e.target.value);
                        }
                        }
                        />
                        <Input placeholder="Cantidad" type="number"
                            value={quantityDiscounts}
                            onChange={(e) => {
                                setQuantityDiscounts(e.target.value);
                            }
                            }
                        />
                        <Button primary
                            customStyle={{
                                height: 'auto',
                                width: '50px !important'
                            }}
                            onClick={addDiscount}
                        >
                            <BiPlus size={24}/>
                        </Button>
                    </DoubleInput>
                    <Button primary onClick={() => {
                        setAProductShow(true);
                    }
                    }>Añadir productos relacionados</Button>
            <Spacer h="20px"/>
            </Layout80>
            <Layout20>
            <HomeItemContainer>
                <HomeItemImage
                    src={image === null ? "https://marlu.tpvlinker.com/thumbs/img/products/9/3/2/932_home_default.jpg" : `https://cdn.beeapp.es/${image}`}
                >
                </HomeItemImage>
                <HomeItemInfo>
                    <HomeItemTitle>{name}</HomeItemTitle>
                    <HomeItemReference> Ref. {reference}</HomeItemReference>
                    <OfferItemPriceContainer>
                        <OfferItemDiscount>
                           {price} €
                        </OfferItemDiscount>
                    </OfferItemPriceContainer>

                    <OfferItemAddToCartContainer>
                        <OfferItemAddToCartButton>
                            <BiSolidCart size={24}/>
                            Añadir
                        </OfferItemAddToCartButton>
                        <OfferItemAddToCartSelect>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            </OfferItemAddToCartSelect>
                    </OfferItemAddToCartContainer>
                </HomeItemInfo>
            </HomeItemContainer>
            <Button primary
                customStyle={{
                    marginTop: 'auto'
                }}
                disabled={name === '' || ean === '' || reference === ''}
                onClick={() => createProduct()}
            >Actualizar</Button>
            <Button outline onClick={() => deleteProduct(id)}>Eliminar</Button>
            </Layout20>
            {
                aProductShow && (
                    <ProductsAlternativeContainer>
                        <ProductsAlternative>
                            <ProductsAlternativeSearch>
                                <ProductsAlternativeSearchInput placeholder="Buscar producto"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    makeSearch(e.target.value);
                                }
                                }
                                ></ProductsAlternativeSearchInput>

                            </ProductsAlternativeSearch>
                            <ProductsAlternativeAdded>
                                    {
                                        alternativeProducts.map((item, index) => {
                                            return (
                                                <ProductsAlternativeAddedPill key={index}
                                                    onClick={() => {
                                                        setAlternativeProducts(alternativeProducts.filter((item, i) => {
                                                            return i !== index;
                                                        }));
                                                    }
                                                    }
                                                >
                                                    {item.name}
                                                </ProductsAlternativeAddedPill>
                                            )
                                        }
                                        )
                                    }
                                </ProductsAlternativeAdded>
                            <ProductsAlternativeSearchResults>
                                {
                                  searchItems.map((item, index) => {
                                    console.log(item);
                                        return (
                                            <ProductsAlternativeSearchResultItem key={index}
                                            onClick={() => {
                                                if(alternativeProducts.includes(item)) return;
                                                setAlternativeProducts([...alternativeProducts, {
                                                    productID: item._id,
                                                    name: item.name
                                                }]);
                                                console.log(alternativeProducts);
                                            }
                                            }
                                            >
                                                {item.name}
                                            </ProductsAlternativeSearchResultItem>
                                        )
                                    }
                                    )
                                }

                            </ProductsAlternativeSearchResults>
                        <Button outline onClick={() => setAProductShow(false)}>Cerrar</Button>
                        </ProductsAlternative>
                    </ProductsAlternativeContainer>
                )
            }

        </LayoutContainer>


    )
}

