import React, {useEffect, useState, Suspense, useContext} from 'react';
import { CategoryTitle, HomeContainer, HomeItemContainer,HomeItemTitle,HomeItemReference, HomeItemImage, HomeItemInfo, OfferContainer, OfferItemAddToCartButton, OfferItemAddToCartContainer, OfferItemAddToCartSelect, OfferItemContainer, OfferItemDescription,OfferItemDiscount, OfferItemImage, OfferItemImageButton, OfferItemInfo, OfferItemOldPrice, OfferItemPrice, OfferItemPriceContainer, OfferItemReference, OfferItemTitle, HomeImageCarousel, ExplanationContent, HomeCarouselLikeAmazon, HomeCarouselLikeAmazonHome, HomeCarouselLikeAmazonSeparator } from './style';
import { BiSolidCart, BiChevronLeft, BiChevronRight, BiPhone } from 'react-icons/bi';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

//API access
import OfferLoader from './OfferLoader';
import ProductService from '../../Utils/API/products';
import AuthService from '../../AuthService';
import { DetailsContentSimilarProducts, DetailsContentSimilarProductsContainer, DetailsContentSimilarProductsItem, DetailsContentSimilarProductsItemImage, DetailsContentSimilarProductsItemTitle, DetailsContentSimilarProductsTitle } from '../Details/style';
import { Tooltip } from 'react-tooltip'
//Help icon from react-icons
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import ExplanationItem from '../../Components/Miscellaneous/Explanation';
import { FaRegAddressCard } from 'react-icons/fa';
import { TbTruckDelivery } from 'react-icons/tb';
import { LiaUserFriendsSolid } from 'react-icons/lia';
import FloatingActionButton from '../../Components/Miscellaneous/FloatingActionButton';
import { BsTelephone } from 'react-icons/bs';

import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import CarouselLoader from './CarouselLoader';
import { Loader } from '../Login/style';
import CartService from '../../Utils/API/cart';
import Flicking from '@egjs/react-flicking';
import { UtilsContext } from '../../Context/UtilsContext';
import { useAuth } from '../../Context/Auth';
import { MdEuro } from 'react-icons/md';
import ReactGA from 'react-ga4';


function getScreenWidth(){
    return window.innerWidth;
}

export default function Home() {

    const {loggedIn} = useAuth();


    useEffect(() => {
        ProductService.getOfferProducts().then(data => {
            setItems(data);
        });

        fetch('https://api.rb.beeapp.es/home', {
            'Content-Type': 'application/json',
    }).then(response => {
        response.json().then(data => {
            setHomePage(data);
        }
        );
    }
    ).catch(error => {
        console.log(error);
    });


        fetch('https://api.rb.beeapp.es/analytics/interested/'+AuthService.getUser()?._id, {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AuthService.getToken(),
        }).then(response => {
            response.json().then(data => {
                setRecomendation(data);
            }
            );
        }
        ).catch(error => {
            console.log(error);
        });

        
    }, []);


    async function addToCart(productID, quantity){
        let o = {
            id: productID,
            quantity: quantity,
        }
        await CartService.addProductToCart(o);
    }


        //create a form with hidden input pr

    const [homePage , setHomePage] = useState([]);
    const [items, setItems] = useState([]);
    const [recomendation, setRecomendation] = useState([]);
    const [screenWidth, setScreenWidth] = useState(null);
    const {isMegaMenuOpen} = useContext(UtilsContext);

    //hook for screenresize event listener
    useEffect(() => {
        setScreenWidth(window.innerWidth);
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth);
        });
    }, []);

    function analytics(ID, x, y){
        fetch('https://api.rb.beeapp.es/analytics/track', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userID: AuthService.getUser() ? AuthService.getUser()._id : null,
                eventType: 'click',
                Date: new Date(),
                Referer: window.location.href,
                cursorPosition: {
                    x: x,
                    y: y,
                },
                productID: ID,

            })
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
    }



    return (
        <HomeContainer>
            <HomeCarouselLikeAmazon
            height={screenWidth}
            >
            <Flicking
                tag="div"
                viewportTag="div"
                cameraTag="div"
                duration={1000}
                gap={10}
                circular={true}

            >
            {
                  homePage?.slider?.length > 0 &&  homePage.slider.map((item, index) => (
                        <div>
                            <Link to={item.link}>
                            <HomeCarouselLikeAmazonHome src={item.image}/>
                            </Link>
                        </div>
                    ))
                }
</Flicking>
            </HomeCarouselLikeAmazon>
                <HomeCarouselLikeAmazonSeparator height={screenWidth}>

                </HomeCarouselLikeAmazonSeparator>
                <ExplanationContent
                    zIndex={isMegaMenuOpen ? 0 : 0}
                >
                    <Link to="/sobre-nosotros"
                    style={{
                        textDecoration: 'none',
                    }}
                    >
            <ExplanationItem title="Quienes Somos" icon={<FaRegAddressCard/>}>
                Somos una distribuidora de productos de papelería, material escolar y de oficina. Nuestro objetivo es ofrecerte los mejores productos al mejor precio.

            </ExplanationItem>
            </Link>
            <ExplanationItem title="Entregas Rápidas" icon={<TbTruckDelivery/>}>
                Para nosotros es muy importante que recibas tus pedidos lo antes posible, por eso ofrecemos entregas en 24/48 horas<sup>*</sup>.
            </ExplanationItem>
            <ExplanationItem title="¿Por qué nosotros?" icon={<LiaUserFriendsSolid/>}>
                La distribución de productos de papelería puede ser tediosa, por eso te ofrecemos un servicio personalizado y adaptado a tus necesidades.
            </ExplanationItem>
            <ExplanationItem title="Precios Competitivos" icon={<MdEuro/>}>
                Nuestros precios son los más competitivos del mercado, ofreciéndote la mejor relación calidad-precio.
            </ExplanationItem>
            </ExplanationContent>


                    <DetailsContentSimilarProductsTitle>Ofertas</DetailsContentSimilarProductsTitle>

                <CarouselProvider
                    isIntrinsicHeight={true}
                    naturalSlideWidth={
                        getScreenWidth() > 800 ? 400 : getScreenWidth() - 50
                    }
                    totalSlides={
                        getScreenWidth() > 800 ? items?.length : items?.length
                    }
                    visibleSlides={
                        getScreenWidth() > 800 ? 2 : 1
                    }
                    infinite={true}
                    step={1}
                    //spacing={10}
                    dragEnabled={false}
                    touchEnabled={false}
                    isPlaying={true}
                    interval={5000}

                >

           <Slider
                
                >
    
    {
      // Filtra los elementos que están en la categoría "OFFER" y devuelve JSX para cada uno.
    
      items?.length > 0 ?
    
      items
        .filter(item => item)
        .map((item, index) => (
          <Slide index={index} key={index}
            //On mouse enter, track the position of the cursor
    
        
          >
            <OfferItemContainer>
    
    
              <OfferItemImage
                      onClick={(e) => {
                        analytics(item._id, e.clientX, e.clientY);
                        window.location.href = '/details/'+item.productURL;
                    }}
              src={item.images[0]} discount={12} stock>
                <OfferItemImageButton>Ver oferta</OfferItemImageButton>
              </OfferItemImage>
     
              <OfferItemInfo>
                <Link to={'/details/'+item.productURL}
                                style={{
                                    textDecoration: 'none',
                                }
                                }
                >
                <OfferItemTitle
                        onClick={(e) => {
                            analytics(item._id, e.clientX, e.clientY);
                        }}
                >{item.name}</OfferItemTitle>
                </Link>
                <OfferItemReference>Ref. {item.reference}</OfferItemReference>
                <span style={{ display: "flex", justifyContent: "space-between", color: '#828282' }}>
                    Unid. mínima de venta {item.quantityStep}
                </span>
                <OfferItemPriceContainer>
                  <OfferItemPrice>
                    Precio S/IVA: 
                  </OfferItemPrice>
                  <OfferItemDiscount>{item.price && item.price.toString().replace(".",",")} €</OfferItemDiscount>
                </OfferItemPriceContainer>
                <OfferItemAddToCartContainer
                //On submit, add the item to the cart
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();

                    ReactGA.event({
                        name: 'add_to_cart',
                        currency: 'EUR',
                        category: "ecommerce",
                        action: "add_to_cart",
                        value: item.price,
                        items: [
                            {
                                id: item._id,
                                name: item.name,
                                quantity: e.target.quantity.value,
                                price: item.price,
                            }
                        ]
                    });

                    addToCart(e.target.productID.value, e.target.quantity.value);
                }}
            >
              <OfferItemAddToCartButton
                type="submit"
              >
                <BiSolidCart size={24} />
                Añadir
              </OfferItemAddToCartButton>
              <input type="hidden" value={item._id} name="productID" />
              <OfferItemAddToCartSelect
                name="quantity"
              >
                {
                    Array.from({length: 25}, (v, k) => k+1).map((i, index) => (
                        <option value={i*item.quantityStep} key={index}>{i*item.quantityStep}</option>
                    ))
                }
              </OfferItemAddToCartSelect>
            </OfferItemAddToCartContainer>
              </OfferItemInfo>
            </OfferItemContainer>
          </Slide>
        ))
        : <>
        <Slide index={0}>
            <OfferLoader/>
        </Slide>
        <Slide index={1}>
            <OfferLoader/>
        </Slide>
        </>
    }
    
    
                </Slider>

            <ButtonBack
                style={{
                    position: 'relative',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    height: '50px',
                    top: -150,
                    width: '50px',
                    display: 'flex',
                    right: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: "50%",
                    background: "transparent",
                }}
            >
                <BiChevronLeft size={48}/>
            </ButtonBack>
            <ButtonNext
                         style={{
                            background: "transparent",
                            position: 'relative',
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            height: '50px',
                            top: -200,
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: "50%",
                            //align to the right
                            right: "calc(-100% + 00px)",
                            
                        }}   
            >
                <BiChevronRight size={48}/>
            </ButtonNext>
            </CarouselProvider>
            
            {/* <DetailsContentSimilarProductsTitle>Lo más vendido</DetailsContentSimilarProductsTitle>
            <CarouselProvider
                    isIntrinsicHeight={true}
                    naturalSlideWidth={
                        getScreenWidth() > 800 ? 200 : getScreenWidth()
                    }
                    totalSlides={1}
                    visibleSlides={
                        getScreenWidth() > 800 ? 4 : 1
                    }
                    infinite={true}
                    step={1}
                    //spacing={10}
                    dragEnabled={true}
                    touchEnabled={true}
                    isPlaying={true}
                    interval={5000}
                    style={{
                        height: getScreenWidth() > 800 ? 400 : 'auto',
                    }}
                >
            <Slider

            >
                {
                    
                        
                }
            <Slide index={0}>
            <HomeItemContainer>
                <HomeItemImage
                    src="https://marlu.tpvlinker.com/thumbs/img/products/9/3/2/932_home_default.jpg"
                >
                </HomeItemImage>
                <HomeItemInfo>
                    <HomeItemTitle>MOCHILA SAFTA CLEO&CUQUIN GOOD NIGHT 34X26 L23</HomeItemTitle>
                    <HomeItemReference>Ref. 327648324</HomeItemReference>
                    <OfferItemPriceContainer>
                        <OfferItemPrice>
                        Precio S/IVA: <OfferItemOldPrice>2,97 €</OfferItemOldPrice>

                        </OfferItemPrice>
                        <OfferItemDiscount>
                            2,52 €
                        </OfferItemDiscount>
                    </OfferItemPriceContainer>

                    <OfferItemAddToCartContainer>
                        <OfferItemAddToCartButton>
                            <BiSolidCart size={24}/>
                            Añadir
                        </OfferItemAddToCartButton>
                        <OfferItemAddToCartSelect>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            </OfferItemAddToCartSelect>
                    </OfferItemAddToCartContainer>
                </HomeItemInfo>
            </HomeItemContainer>
            </Slide>
            </Slider>
            <ButtonBack
                style={{
                    position: 'relative',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    height: '50px',
                    top: -250,
                    width: '50px',
                    display: 'flex',
                    right: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: "50%",
                    background: "transparent",
                }}
            >
                <BiChevronLeft size={48}/>
            </ButtonBack>
            <ButtonNext
                         style={{
                            background: "transparent",
                            position: 'relative',
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            height: '50px',
                            top: -300,
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: "50%",
                            //align to the right
                            right: "calc(-100% + 00px)",
                            
                        }}   
            >
                <BiChevronRight size={48}/>
            </ButtonNext>
            </CarouselProvider> */}
                <DetailsContentSimilarProductsTitle>Creemos que te puede intersar
                    <a data-tooltip-id="my-tooltip" data-tooltip-content="Basándonos en tu comportamiento te ofrecemos las siguientes sugerencias">
                        <AiOutlineQuestionCircle size={22} style={{marginLeft: 5}}/>
                    </a>
                </DetailsContentSimilarProductsTitle>
                <Tooltip id="my-tooltip"
                    style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        borderRadius: 5,
                        padding: 10,
                        fontSize: 14,
                        fontWeight: 400,
                        width: 300,
                    }}
                />

                <DetailsContentSimilarProductsContainer>
                    {
                        recomendation?.length > 0 ? recomendation.map((item, index) => (
                            <DetailsContentSimilarProductsItem key={index} href={'details/'+item.productURL}>
                                <DetailsContentSimilarProductsItemImage background={item.images[0]}></DetailsContentSimilarProductsItemImage>
                                <DetailsContentSimilarProductsItemTitle>
                                    {item.name}
                                </DetailsContentSimilarProductsItemTitle>
                            </DetailsContentSimilarProductsItem>
                        )) : <> </>
                    }
                </DetailsContentSimilarProductsContainer>
                {
                    loggedIn ? <> </> : (
                        <FloatingActionButton
                            text={`Hola, soy *${Math.random() > 0.5 ? "Luis Alberto" : "Alejandro"}*. ¿Por qué no me llamas y hablamos?`}
                        >
                        <BsTelephone color='#fff'
                            
                        />
                    </FloatingActionButton>
                    )
                }

        </HomeContainer>
    );
}