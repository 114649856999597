import React, { createContext, useState, useEffect } from 'react';
import CartService from '../Utils/API/cart';
import { el } from 'date-fns/locale';

// Create the CartContext
export const CartContext = createContext();

// Create the CartProvider component
export const CartProvider = ({ children }) => {
    // State to store the cart items
    const [cartItems, setCartItems] = useState([]);

    // Function to add an item to the cart
    const addToCart = async (item) => {
        const updatedCart = await CartService.addProductToCart(item)
        setCartItems(updatedCart);
    };

    // Function to remove an item from the cart
    const removeFromCart = async (itemId) => {
        const updatedCart = await CartService.deleteProductFromCart(itemId);
        setCartItems(updatedCart);
    };

    // Function to update an item in the cart
    const updateCartItem = async (itemId, quantity) => {
        const updatedCart = await CartService.addProductQuantity(itemId, quantity);
        setCartItems(updatedCart);
    };
    const addProductQuantity = async (id, quantity) => {
        const cart = await CartService.addProductQuantity(id, quantity);
        setCartItems(cart);
    }
    // Function to get the cart
    const getCart = async () => {
        const cart = await CartService.getCart();
        setCartItems(cart);
        getTotalItems();
    };

    // Load the cart on component mount
    useEffect(() => {
        getCart();
    }, []);

    const getTotalItems = () => {
        /**
         * {
    "_id": "65e68094afed3fd9f90a6619",
    "userID": "65128c695aa4127257b3995c",
    "items": [
        {
            "productID": "65bc09fd87aa59009d7e136a",
            "quantity": 6,
            "_id": "65e68094afed3fd9f90a661a",
            "price": 3.509,
            "quantityStep": 1,
            "images": [
                "https://cdn.beeapp.es/distribucionesrb/paquete-10-estuches-cd-plastico-colores.jpg"
            ],
            "productURL": "consumibles-y-accesorios/paquete-10-estuches-cd-plastico-colores",
            "name": "PAQUETE 10 ESTUCHES CD PLASTICO COLORES",
            "discounts": []
        },
        {
            "productID": "65ba9487465d4623912dd3e3",
            "quantity": 4,
            "_id": "65e6821c283b26d0c6a97e3b",
            "price": 11.556,
            "discounted": true,
            "quantityStep": 1,
            "images": [
                "https://cdn.beeapp.es/distribucionesrb/100-juegos-reunidos-falomir.jpg"
            ],
            "productURL": "juegos/100-juegos-reunidos-falomir",
            "name": "100 JUEGOS REUNIDOS FALOMIR",
            "discounts": []
        }
    ],
    "createdAt": "2024-03-05T02:16:52.983Z",
    "__v": 1
}
         */

        let totalItems = 0;
        if(cartItems.items){
            cartItems.items.forEach(item => {
                totalItems += item.quantity;
            });
            console.log('totalItems', totalItems);
        }else{
            console.log('totalItems', cartItems);
        }
        return totalItems;
    };

    // Value object to be passed to consumers of the CartContext
    const cartContextValue = {
        cartItems,
        addToCart,
        removeFromCart,
        updateCartItem,
        addProductQuantity,
        getTotalItems,
    };

    // Render the CartProvider with the cartContextValue and children components
    return (
        <CartContext.Provider value={cartContextValue}>
            {children}
        </CartContext.Provider>
    );
};
