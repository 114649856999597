import React, {useEffect, useState} from "react";

import {useAuth} from "../../Context/Auth";
import { SupportChat, SupportChatContent, SupportChatHeader, SupportChatMessage, SupportChatSend, SupportChatSendButton, SupportChatSendInput } from "./style";
import { FiSend } from "react-icons/fi";
import Button from "../../Components/Miscellaneous/Button";
import {Portal} from 'react-portal';
import Modal from "../../Components/Miscellaneous/Modal";
import { CSSTransition } from "react-transition-group";
import OrderService from "../../Utils/API/orders";
import Card from "../../Components/Miscellaneous/Card";
import ChatService from "../../Utils/API/chat";
//circular progress bar
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default function Support() {
    
        const {user} = useAuth();
        const [support, setSupport] = useState(null);
        const [modal, setModal] = useState(false);
        const [message, setMessage] = useState('');
        const [timeLeft, setTimeLeft] = useState(15);

        const [orders, setOrders] = useState([]);
        const [chats, setChats] = useState([]);
        const [selectedChat, setSelectedChat] = useState(null);
        const [chat, setChat] = useState(null);
        useEffect(() => {
            const getOrders = async () => {
                const orders = await OrderService.getOrders();
                setOrders(orders);
            }
            const getChats = async () => {
                ChatService.getChats().then(response => {
                    setChats(response);
                }).catch(error => {
                    console.error(error);
                });

            }
            getChats();
            getOrders();



        }, []);


        useEffect(() => {
            if (timeLeft === 0) {
                setTimeLeft(15);
                if (selectedChat){
                    loadChat(selectedChat._id);
                }
            }
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }, [timeLeft]);


        function close(){
            setModal(false);
        }

        function createChat(){
            ChatService.createChat().then(response => {
                const getChats = async () => {
                    ChatService.getChats().then(response => {
                        setChats(response);
                        console.log(response);
                    }).catch(error => {
                        console.error(error);
                    });
    
                }
                getChats();
            }
            ).catch(error => {
                console.error(error);
            });

        }

        function loadChat(id){
            ChatService.getChat(id).then(response => {
                setChat(response);
                console.log(response);
            }
            ).catch(error => {
                console.error(error);
            });

        }
//handle enter key
        function handleEnter(e){
            if (e.key === 'Enter'){
                sendMessage();
            }
        }
        function sendMessage(type, order = null){
            if (type === 'order'){
            ChatService.sendMessage(chat._id,{
                message: message,
                order: order
            }).then(response => {

                const newChat = chat;
                newChat.chats.push({
                    id: Math.random(),
                    from: user._id,
                    to: null,
                    message: "Hola, necesito información sobre el pedido #"+order
                });
            }
            ).catch(error => {
                console.error(error);
            });
        } else {
            if (message === '') return;
            ChatService.sendMessage(chat._id,{
                message: message,
                order: null
            }).then(response => {

            }
            ).then(data => {
                //add to chat.chats array message
                const newChat = chat;
                newChat.chats.push({
                    id: Math.random(),
                    from: user._id,
                    to: null,
                    message: message
                });
                setChat(newChat);
            }
            )
            .catch(error => {
                console.error(error);
            });
        }
        setMessage('');
        }

        return (<>
                <Button onClick={createChat}>Crear Chat</Button>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: 'auto', padding: 10, gap: 10, overflow: 'auto'}}>

                    {
                     chats?.map(chat => {
                       return ( <Card  xs key={chat._id} onClick={() => {
                            setSelectedChat(chat);
                            loadChat(chat._id);
                    }}
                    backgroundColor={
                        chat.supportID === selectedChat?.supportID ? '#028322' : '#fff'
                    }
                    color={
                        chat.supportID === selectedChat?.supportID ? '#fff' : '#028322'
                    }
                        >
                            <span>Chat {chat.supportID}</span>
                            <span>
                                Creado el {new Date(chat.chats[0].date).toLocaleDateString()}
                            </span>
                        </Card>)
                    }
                        )
                    }
                </div>
            {
                selectedChat && (
                    <SupportChat>
                    <SupportChatHeader>
                        <h2>Atención al Cliente</h2>
                       {
                            !chat?.closed && (
                                <Button width="80px" height="35px" onClick={() => {
                                    ChatService.closeChat(selectedChat._id).then(response => {
                                        setSelectedChat(null);
                                    }
                                    ).catch(error => {
                                        console.error(error);
                                    });
                                
                                }}>Finalizar</Button>
                            )
                       } 
                       <div style={{width: '35px', height: '35px', marginLeft: 'auto'}}>
                                                           <CircularProgressbar
                                        value={15-timeLeft}
                                        text={`${timeLeft}s`}
                                        maxValue={15}
                                        styles={buildStyles({
                                            width: '50px',
                                            rotation: 0,
                                            strokeLinecap: 'round',
                                            pathTransitionDuration: 0.5,
                                            pathColor: `#028322`,
                                            textColor: '#028322',
                                            textSize: '34px',
                                            fontWeight: '600',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#028322',
                                        })}
                                    />
                          </div>

                    </SupportChatHeader>
                    <SupportChatContent>
                        
                        <SupportChatMessage>
                            <span>Hola, {user?.Name} ¿En qué podemos ayudarte?</span>
                        </SupportChatMessage>
                        {
                            chat?.chats.length < 2 ? (
                                <SupportChatMessage outline>
                                <span>
                                    ¿Su consulta está relacionada con un pedido o un producto dentro de un pedido?
                                </span>
                                <Button outline onClick={() => setModal(true)}>Seleccionar Pedido</Button>
                            </SupportChatMessage>
                            ) : null
                        }


                        {
                            chat?.chats.map((chat,i) => {
                                //skip first message
                                if (i === 0) return;
                                return (
                                    <SupportChatMessage key={chat._id} me={chat.from === user._id}>
                                        <span>{chat.message}</span>
                                    </SupportChatMessage>
                                )
                            }
                            )
                        }
                    </SupportChatContent>
                    <SupportChatSend>
                        {
                           chat?.closed ? <span
                            style={{
                                padding: 10,
                                width: '100%',
                                textAlign: 'center',
                            }}
                           >Chat Finalizado</span> : (
                            <>
                        <SupportChatSendInput placeholder="Escribe tu mensaje..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        />
                        <SupportChatSendButton onClick={() => sendMessage()}><FiSend/></SupportChatSendButton>
                            </>
                           )
                        }

                    </SupportChatSend>
                </SupportChat>
                )
            }    

            <CSSTransition in={modal} timeout={300} classNames="fade" unmountOnExit>
                <Modal title="Seleccionar Pedido" opened={true} close={() => close}>
                    {
                        orders.map(order => {
                            return (
                                <Card sm key={order._id} onClick={() => {
                                    setSupport(order);
                                    close();
                                    sendMessage("order", order._id);
                                }}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%', flexDirection: 'column', padding: 20}}>
                                        <span
                                        style={{
                                            textWrap: 'wrap',
                                            width: 200,
                                            fontWeight: 'bold'
                                        }}
                                        >Pedido #{
                                            //max length 10 characters, if more, cut the string and add ...
                                            order._id.length > 10 ? order._id.substring(0, 10) + '...' : order._id
                                        }</span>
                                        <span
                                        style={{
                                            fontSize: 12,
                                            color: '#fff'
                                        }}
                                        >Realizado el {new Date(order.CreationDate).toLocaleDateString()}</span>
                                        <span>Seleccionar</span>
                                    </div>
                                </Card>
                            )
                        })
                    }
                </Modal>
            </CSSTransition>

            </>
        )
    }