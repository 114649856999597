import styled from 'styled-components';

export const LayoutContainer = styled.div`
    width: auto;
    height: auto;
    
    display: flex;
    flex-direction: column;
`;

export const GridLayout = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 10px;
    padding: 0 70px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
    }
`;

export const GridItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
`;


//GridLayout 20% 80%
export const GridLayout20 = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto;
    gap: 10px;
    padding: 0 70px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
    }
`;

export const GridItem20 = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
`;

//GridLayout 80% 20%

export const GridLayout80 = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto;
    gap: 10px;
    padding: 0 70px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
    }
`;

