import styled from 'styled-components';

export const MenuContainer = styled.div`
    background-color: #359C4F;
    width: 100vw;
    height: 95px;
    flex: 1 1 0px;
    align-items: center;
    padding: 0 3rem;
    flex-direction: row;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    box-sizing: border-box;
    @media screen and (max-width: 600px){
        display: none;
    }
`;
export const ItemText = styled.p`
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    transition: all 0.3s ease-in-out;
    max-width: 170px;
    margin: 0;
    text-align: center;
    white-space: wrap;
    @media screen and (max-width: 1200px){
       font-size: 10px;
    }

    @media screen and (max-width: 800px){
         font-size: 8px;
    }

`
export const ItemIcon = styled.img`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 1200px){
         width: 25px;
            height: 25px;
    }
    @media screen and (max-width: 800px){
            width: 20px;
            height: 20px;
    }
`
export const Item = styled.div`
    width: 70px;
    height: 95px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    min-width: 70px;
    transition: all 0.3s ease-in-out;
    &:hover ${ItemText}{
        text-decoration: underline;
    }

    &:hover ${ItemIcon}{
        transform: scale(1.2);
        transform:translateY(-5px);
    }
    @media screen and (max-width: 1200px){
        min-width: 50px;
    }
    @media screen and (max-width: 800px){
        min-width: 40px;
    }
    @media screen and (max-width: 600px){
        display: none;
    }
`



