import React, { useEffect, useRef, useState } from "react";
import { CategoriesContainer, CategoriesFilter, CategoriesFilterMaxMin, CategoriesFilterSelector, CategoriesFilterSelectorItem, CategoriesFilterSelectorItemCheckbox, CategoriesFilterSelectorItemExpand, CategoriesFilterSelectorItemText, CategoriesFilterTitle, PaginationButton, PaginationButtonDisabled, PaginationContainer, ProductCard, ProductCardImage, ProductCardPrice, ProductCardTitle, ProductsContainer } from "./style";
import RangeSlider from 'react-range-slider-input';
import { DetailsContentSimilarProductsTitle } from "../Details/style";
import 'react-range-slider-input/dist/style.css';
import { useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../Context/Auth";
//URL add params
import DinoGame from 'react-chrome-dino-ts'
import 'react-chrome-dino-ts/index.css'

import ProductService from "../../Utils/API/products";
import ReactGA from "react-ga4";

import ContentLoader from "react-content-loader"
import { CheckoutItemInfoQuantity, CheckoutItemInfoQuantityButton, CheckoutItemInfoQuantityContainer } from "../Checkout/style";
import { BiSolidCart, BiSolidShoppingBag, BiTrash } from "react-icons/bi";
import Button from "../../Components/Miscellaneous/Button";
import { BsCart3 } from "react-icons/bs";
import { OfferItemAddToCartButton, OfferItemAddToCartContainer, OfferItemAddToCartSelect } from "../Home/style";
import CartService from "../../Utils/API/cart";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import { ProductCardDiscounts, ProductCardDiscountsBar } from "../Search/style";
import { usePostHog } from 'posthog-js/react'

const LoaderCategories = (props) => (
  <ContentLoader 
    speed={2}
    width={200}
    height={400}
    viewBox="0 0 200 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="200" height="400" />
  </ContentLoader>
)

const LoaderCategoriesItem = (props) => (
    <ContentLoader 
      speed={2}
      width={300}
      height={300}
      viewBox="0 0 300 250"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="5" ry="5" width="250" height="300" />
    </ContentLoader>
  )

export default function Categories() {
    const posthog = usePostHog();
    const [maxMin, setMaxMin] = useState([0, 0]);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const {loggedIn} = useAuth();
    //get category from url
    const [searchParams, setSearchParams] = useSearchParams();

    let { category } = useParams();
    const [products, setProducts] = useState([]);
    const [tags, setTags] = useState([]);
    const [expand, setExpand] = useState(false);
    const [loading, setLoading] = useState(true);
    console.log(category);
    const [query, setQuery] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [actualPage, setActualPage] = useState(1);
    const [discounts, setDiscounts] = useState(Array(products.length).fill(0)); // Initialize discounts state
    const refDiscounts = useRef([]);
    const initialFilter = {
        tags: searchParams.get('filter') || '',
        sort: searchParams.get('sort') || '',
        letter: searchParams.get('letter') || '',
    }
    console.log('initialFilter', initialFilter);
    useEffect(() => {
        setPage(searchParams.get('page') || 1);
        setMaxMin([searchParams.get('min') || 0, searchParams.get('max') || Infinity]);
        setMax(searchParams.get('max') || Infinity);
        setMin(searchParams.get('min') || 0);
        console.log(searchParams.get('filter'));

        const filter = {
            tags: searchParams.get('filter') || '',
            sort: searchParams.get('sort') || '',
            letter: searchParams.get('letter') || '',
        }
        ProductService.getProductsByCategory(category,searchParams.get('page'),min,max,page,initialFilter).then(response => {
            if(searchParams.get('min') || searchParams.get('max')){
                setMaxMin([searchParams.get('min'), searchParams.get('max')]);
            }else{
                setMaxMin([response.minPrice, response.maxPrice]);
            }
            setMin(response.minPrice);
            setMax(response.maxPrice);
            //sort tags first by length and then alphabetically
            setTags(response.tags.sort((a, b) => {
                return a.length - b.length || a.localeCompare(b);
            }));
            setLoading(false);
            setProducts(response.products);
            setTotalPages(response.totalPages);
            setActualPage(response.currentPage);
        }
        ).catch(error => {
            console.error(error);
        });

        //if filter param is present, set it

    }
    , []);
    const handlePageClick = (pageNumber) => {
        updateSearchParams({ page: pageNumber });
        setLoading(true);
        const filter = {
            tags: searchParams.get('filter') || '',
            sort: searchParams.get('sort') || '',
            letter: searchParams.get('letter') || '',
        }
        // Fetch products based on the clicked page number
        ProductService.getProductsByCategory(category, pageNumber, maxMin[0], maxMin[1], filter)
            .then(response => {

                // Sort tags first by length and then alphabetically
                setTags(response.tags.sort((a, b) => {
                    return a.length - b.length || a.localeCompare(b);
                }));
                setLoading(false);
                setProducts(response.products);
                setActualPage(response.currentPage);
                setTotalPages(response.totalPages);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .catch(error => {
                console.error(error);
            });
    };
    
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
      const handleQuantityChange = (index, value) => {
        const newDiscounts = [...discounts]; // Create a copy of the discounts array
        newDiscounts[index] = value; // Update the discount value at the specified index
        setDiscounts(newDiscounts); // Update the discounts state
    };
      //const change values of setSearchParams, recieve a map and values, this search the values in searchParams and change it
    const updateSearchParams = (values) => {
        //use searchParams to get the current values
        const currentParams = Object.fromEntries(searchParams);
        //merge the current values with the new ones
        const newParams = {...currentParams, ...values};
        //set the new values
        setSearchParams(new URLSearchParams(newParams));
    };
  

    const rangeSlideChange = (value) => {
        //add max min to searchParams
        updateSearchParams({min: value[0], max: value[1]});
        const filter = {
            tags: searchParams.get('filter') || '',
            sort: searchParams.get('sort') || '',
            letter: searchParams.get('letter') || '',
        }
        ProductService.getProductsByCategory(category,page, value[0], value[1], filter).then(response => {

            //sort tags first by length and then alphabetically
            setTags(response.tags.sort((a, b) => {
                return a.length - b.length || a.localeCompare(b);
            }));
            setLoading(false);
            setProducts(response.products);
            setTotalPages(response.totalPages);
            setActualPage(1);
        }
        ).catch(error => {
            console.error(error);
        });
        
    }

    const handleFilterChange = (add) => {
        //add filter to searchParams
        updateSearchParams(add)
        
        //if add is a change in the filter, update
        const filter = {
            tags: searchParams.get('filter') || '',
            sort: searchParams.get('sort') || '',
            letter: searchParams.get('letter') || '',
        };
            console.log('filter', add);
        // If add.sort, add.filter, or add.letter exists, update filter
        if (add.sort || add.filter || add.letter) {
            if (add.filter) {
                console.log('filter', add.filter);
                filter.tags = add.filter;
            }
            if (add.sort) {
                filter.sort = add.sort;
            }
            if (add.letter) {
                filter.letter = add.letter;
            }
        }
        

        ProductService.getProductsByCategory(category,page, maxMin[0], maxMin[1], filter).then(response => {

            //sort tags first by length and then alphabetically
            setTags(response.tags.sort((a, b) => {
                return a.length - b.length || a.localeCompare(b);
            }));
            setLoading(false);
            setProducts(response.products);
            setTotalPages(response.totalPages);
            setActualPage(1);
        }
        ).catch(error => {
            console.error(error);
        });
    }

    //Wait for query to be updated
    async function addQuery(item){
        let newQuery = query;
        newQuery.push(item);
        setQuery(newQuery);
    }

    function removeQuery(item){
        let newQuery = query;
        newQuery.splice(newQuery.indexOf(item), 1);
        setQuery(newQuery);
    }

    async function queryComposer(action, item){
        switch(action){
            case 'add':
                await addQuery(item);
            //url add param ?filter=tags.join('|')
                break;
            case 'remove':
                removeQuery(item);
                break;
            default:
                break;
        }

        //set url
        window.history.replaceState(null, null, "?filter="+query.join('|'));
    }

    async function addToCart(productID, quantity){
        const o = {
            id: productID,
            quantity: quantity,
        };

        posthog?.capture({
            distinct_id: posthog?.get_distinct_id(),
            event: 'add_to_cart',
            properties: {
                product_id: productID,
                quantity: quantity,
            }
        });
        


        CartService.addProductToCart(o).then(response => {
        }).catch(error => {
        });
    }


    const categoryMappings = {
        escritura: 'Escritura',
        correccion: 'Corrección',
        'escritura-y-correccion': 'Escritura y Corrección',
        ofertas: 'Ofertas',
        'productos-de-oficina': 'Productos de Oficina',
        'productos-escolares': 'Productos Escolares',
        'etiquetas-y-embalajes': 'Etiquetas y Embalajes',
        papel: 'Papel',
        'cuadernos-y-sobres': 'Cuadernos y Sobres',
        'archivo-y-clasificacion': 'Archivo y Clasificación',
        'consumibles-y-accesorios-informaticos': 'Consumibles y Accesorios Informáticos',
        organizacion: 'Organización',
        'papel-cuadernos-y-sobres': 'Papel, Cuadernos y Sobres',
        'mobiliario-de-oficina': 'Mobiliario de Oficina',
        'juegos': 'Juegos',
      };
      
      const getCategoryName = (category) => categoryMappings[category] || category;
      const options = [
        { value: 'priceASC', label: 'Precio ASC.' },
        { value: 'priceDSC', label: 'Precio DSC' },
        { value: 'name', label: 'Nombre' },
      ];
    return (
        <>
            <DetailsContentSimilarProductsTitle
                style={{
                    margin: '0rem',
                    clipPath: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding:'10px 30px 10px 30px'
                }}
            >
                {
                    getCategoryName(category)
                }
                <Select
                    options={options}
                    onChange={(e) => {
                        
                        updateSearchParams({sort: e.value});
                        handleFilterChange({sort: e.value});
                    }}

                    placeholder="Ordenar por"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '150px',
                            height: '40px',
                            margin: '0',
                            padding: '0',
                            border: '1px solid #f3f3f3',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            fontSize: '14px',
                            marginLeft: 'auto',
                        }),
                        container: (provided) => ({
                            ...provided,
                            width: '150px',
                            height: '40px',
                            margin: '0',
                            padding: '0',
                            border: '1px solid #f3f3f3',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            fontSize: '14px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: '150px',
                            height: 'auto',
                            margin: '0',
                            padding: '0',
                            border: '1px solid #f3f3f3',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            fontSize: '14px',
                            color: '#028322'
                        }),

                    }}
                ></Select>
            </DetailsContentSimilarProductsTitle>
            <CategoriesContainer>
            <CategoriesFilter>
            <CategoriesFilterTitle>
                    Filtros
                </CategoriesFilterTitle>
                {
                    loading ? <LoaderCategories/> : (
                        <>
                <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '0',
                            margin: '0',
                            gap: '10px',
                            flexWrap: 'wrap',
                        }}
                >
                        {
                            "A,B,C,D,E,F,G,H,I,J,K,L,M,N,Ñ,O,P,Q,R,S,T,U,V,W,X,Y,Z".split(',').map((le, id) => {
                                return (
                                    <span key={id} style={{
                                        cursor: 'pointer',
                                        color: searchParams?.get('letter')?.includes(le) ? '#028322' : 'black',
                                        fontWeight: searchParams?.get('letter')?.includes(le) ? 'bold' : 'normal',
                                    }}
                                    onClick={() => {
                                        if(
                                            searchParams?.get('letter')?.includes(le)
                                        ){
                                            updateSearchParams({letter: searchParams.get('letter').replace(le, '')});
                                            handleFilterChange({letter: searchParams.get('letter').replace(le, '')});
                                        }else{
                                            updateSearchParams({letter: searchParams.get('letter') + le})
                                            handleFilterChange({letter: searchParams.get('letter') + le});
                                        }
                                    }}
                                    >{le}</span>
                                )
                            }
                            )
                        }    
                    </div>
                <div style={{padding: '0', display:'flex', justifyContent:'space-between'}}>
                    <CategoriesFilterMaxMin type="text" value={maxMin[0]+"€"} onChange={(e) => {
                        //Parse to int, remove € and set the value
                        let value = e.target.value.split("€")[0];
                        
                        setMaxMin([parseInt(value), maxMin[1]]);

                        //add param to url ?min=value
                    }
                    }></CategoriesFilterMaxMin>
                    <CategoriesFilterMaxMin type="text" value={maxMin[1]+"€"} onChange={(e) => {
                        let value = e.target.value.split("€")[0];
                        
                        setMaxMin([maxMin[0], parseInt(value)]);

                        
                    }
                    }></CategoriesFilterMaxMin>

                </div>

                <RangeSlider
                    min={min}
                    max={max}
                    onInput={(value) => {
                        //add param to url &min=value[0]&max=value[1]

                        
                        setMaxMin(value);
                        console.log(value);
                        



                    }}
                    onThumbDragEnd={ () => {rangeSlideChange(maxMin)}}
                    step={
                        max - min < 15 ? (max - min)/30 : 1
                    }
                    defaultValue={maxMin}
                    style={{
                        zIndex: 0
                    }}
                ></RangeSlider>
                <Select
                    isMulti
                    options={tags.map((tag) => {
                        return {value: tag, label: tag};
                    }  
                    )}
                    defaultValue={searchParams.get('filter') ? searchParams.get('filter').split('|').map((tag) => ({ value: tag, label: tag })) : []}

                    onChange={async (e) => {
                        let values = e.map((tag) => tag.value);
                        updateSearchParams({filter: values.join('|')});
                        //if values is empty, filter: []
                        if (values.length === 0) {
                            handleFilterChange({filter: ''});
                            console.log('empty');
                        }else{
                        handleFilterChange({filter: values.join('|')});
                        }
                    }}
                    placeholder="Filtrar por etiquetas"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '100%',
                            height: 'auto',
                            margin: '0',
                            padding: '0',
                            border: '1px solid #f3f3f3',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            fontSize: '14px',
                        }),
                        container: (provided) => ({
                            ...provided,
                            width: '100%',
                            height: 'auto',
                            margin: '0',
                            padding: '0',
                            border: '1px solid #f3f3f3',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            fontSize: '14px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: '100%',
                            height: 'auto',
                            margin: '0',
                            padding: '0',
                            border: '1px solid #f3f3f3',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            fontSize: '14px',
                            color: '#028322'
                        }),

                    }}
                ></Select>
                {/* <CategoriesFilterSelector open={expand}>
                    {
                        tags.map((tag,id) => {
                            return (
                                <CategoriesFilterSelectorItem key={id}>
                                    <CategoriesFilterSelectorItemCheckbox type="checkbox"
                                    onChange={(e) => {
                                        if(e.target.checked){
                                            queryComposer('add', tag);
                                        }else{
                                            queryComposer('remove', tag);
                                        }
                                    }
                                    }
                                    id={id}
                                    ></CategoriesFilterSelectorItemCheckbox>
                                    <CategoriesFilterSelectorItemText htmlFor={id} >{tag}</CategoriesFilterSelectorItemText>
                                </CategoriesFilterSelectorItem>
                            )
                        }
                        )
                    }

                </CategoriesFilterSelector>
                <CategoriesFilterSelectorItemExpand onClick={() => setExpand(!expand)}>
                        {
                            expand ? "Ver menos" : "Ver más"
                        }
                    </CategoriesFilterSelectorItemExpand> */}

                {/* <CategoriesFilterSelectorItem>
                                    <CategoriesFilterSelectorItemCheckbox type="checkbox"></CategoriesFilterSelectorItemCheckbox>
                                    <CategoriesFilterSelectorItemText>En promoción</CategoriesFilterSelectorItemText>
                </CategoriesFilterSelectorItem> */}
                        </>
                    )
                }

            </CategoriesFilter>
                <ProductsContainer>
                    {
                        loading ? (
                            
                                Array(8).fill().map((item, id) => {
                                    return (
                                        <LoaderCategoriesItem key={id}></LoaderCategoriesItem>
                                    )
                                })
                        ) : (
                            
                                
                                
                            <>
                            {
                                products.length === 0 && (
                                    <DinoGame style={{width: '100%', height: 'auto'}}></DinoGame>
                                )
                            }
                            {
                                products.map((product, index) => {
                                    return (
                                        <ProductCard
                                            key={product.objectID}
                                        >
                                            <ProductCardImage src={product.images[0]}
                                                onClick={() => { window.location.href = `/details/${product.productURL}`; }}
                                            ></ProductCardImage>
                                            <ProductCardTitle
                                            onClick={() => { window.location.href = `/details/${product.productURL}`; }}
                                            >{product.name}</ProductCardTitle>
                                            <span style={{
                                                color: 'gray',
                                                fontSize: '0.8em',
                                            }}>
                                               Cantidad mínima {product.quantityStep > 1 ? product.quantityStep + ' unidades' : product.quantityStep + ' unidad'} 
                                            </span>

                                            {
                                                !loggedIn ? (
                                                    <ProductCardPrice
                                                    style={{
                                                        color: '#028322',
                                                    }}
                                                    >Inicia Sesión</ProductCardPrice>
                                                ) : (
                                                    product.discounts && (product.discounts.length > 0) ? (
                                                        <div style={{
                                                            display: 'flex'
                                                        }}
                                                        >
                                                        <ProductCardPrice
                                                            style={{
                                                                color: '#028322',
                                                            }}
                                                        >{
                                                            product.price.toFixed(2).toString().replace('.', ',') + '€'
                                                            }</ProductCardPrice>

                                                                                                                    <ProductCardPrice
                                                            style={{
                                                                color: '#028322',
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            {
                                                                //last discount
                                                                product.discounts[product.discounts.length - 1].discountedPrice.toString().replace('.', ',') + '€'
                                                            }
                                                        </ProductCardPrice>
                                                            </div>
                                                    ) : (
                                                        <ProductCardPrice
                                                        style={{
                                                            color: '#028322',
                                                        }}
                                                        >{product.price.toFixed(2).toString().replace('.', ',')}€</ProductCardPrice>
                                                    )
                                                )
                                            }

                                            {
                                                loggedIn && product.discounts && product.discounts.length > 0 && (
                                                    <ProductCardDiscounts>
                                                    <ProductCardDiscountsBar discount={
                                                        //product.discounts is an array of discounts, [
                                                            //   {
                                                            //     "minQuantity": "240",
                                                            //     "discountedPrice": "2.69"
                                                            //   }
                                                            // ]
                                                            //Calculate percentage discount based on minQuantity/discounts[index], get the last product discounts
                                                            product.discounts.length > 0 ? (discounts[index] / product.discounts[product.discounts.length - 1].minQuantity) *100 : 0 
                                                    } />
                                                </ProductCardDiscounts>
                                                )
                                            }
                                            {
                                                loggedIn && (
                                                    <OfferItemAddToCartContainer
                                                    //On submit, add the item to the cart
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();
                                                        addToCart(e.target.productID.value, e.target.quantity.value);
                                                    }}
                                                >
                                                  <OfferItemAddToCartButton
                                                    type="submit"
                                                  >
                                                    <BiSolidCart size={24} />
                                                    Añadir
                                                  </OfferItemAddToCartButton>
                                                  <input type="hidden" value={product._id} name="productID" />
                                                  <OfferItemAddToCartSelect
                                                    name="quantity"
                                                    //assign a ref to the select, refDiscounts is an array of refs to the selects, the index is the same as the product index
                                                    ref={(ref) => {
                                                        // Store the ref in the array using the index
                                                        refDiscounts.current[index] = ref;
                                                    }}

                                                    onChange={(e) => {
                                                        const value = e.target.value; // Get the value of the selected quantity
                                                        handleQuantityChange(index, value); // Update discounts bar
                                                    }}
                                                    
                                                  >
                                                    {
                                                        Array.from({length: 60}, (v, k) => k+1).map((i, index) => (
                                                            <option
                                                            value={i*product.quantityStep} key={index}>{i*product.quantityStep}</option>
                                                        ))
                                                    }
                                                  </OfferItemAddToCartSelect>
                                                </OfferItemAddToCartContainer>
                                                )
                                            }

                                        </ProductCard>
                                    )
                                })
                            }
                            </>
                        )
                    }

                </ProductsContainer>
            </CategoriesContainer>

            <PaginationContainer>
            {
    Array.from(Array(totalPages).keys()).map((_, i) => {
        // Display first page
        if (i === 0 || i === actualPage - 1 || i === totalPages - 1) {
            return (
                <PaginationButton
                    key={i}
                    active={i + 1 === actualPage}
                    onClick={() => handlePageClick(i + 1)}
                >
                    {i + 1}
                </PaginationButton>
            );
        }
        // Display "..." if not within range of 1, current page, or last page
        else if (
            (i === 1 && actualPage > 4) || // Show "..." after 1st page if current page > 4
            (i === totalPages - 2 && actualPage < totalPages - 3) // Show "..." before last page if current page < totalPages - 3
        ) {
            return <PaginationButton key={i} disabled>...</PaginationButton>;
        }
        // Display page numbers within range of current page
        else if (
            (i >= actualPage - 2 && i <= actualPage + 2) || // Show pages around current page
            (totalPages <= 5 && i !== 1 && i !== totalPages - 2) // Show all pages if total pages <= 5
        ) {
            return (
                <PaginationButton
                    key={i}
                    active={i + 1 === actualPage}
                    onClick={() => handlePageClick(i + 1)}
                >
                    {i + 1}
                </PaginationButton>
            );
        }
        // Display "..." between current page and last page
        else if (i === totalPages - 2 && actualPage < totalPages - 2) {
            return <PaginationButton key={i} disabled>...</PaginationButton>;
        }
        // Hide other pages
        return null;
    })
}



            </PaginationContainer>

        </>
    );
}