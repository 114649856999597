import {toast} from 'react-toastify';
import AuthService from '../../AuthService';
const API_URL = 'https://api.rb.beeapp.es';
const token = AuthService.getToken();

class AddressService {

    static async getDropshippingAddresses() {
        try{
           const response = await fetch(`${API_URL}/addresses/dropshipping`, {
                
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            return data || [];
            
        }
        catch(error){

            return [];
        }
    }

    static async addDropshippingAddress(address) {
        try {
            const response = await fetch(`${API_URL}/addresses/dropshipping`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(address),
            });
            const data = await response.json();
            toast.success('Dirección agregada');
            return data || [];

        }
        catch (error) {
            console.error(error);
        }
    }

    static async deleteDropshippingAddress(id) {
        try {
            const response = await fetch(`${API_URL}/addresses/dropshipping/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            toast.success('Dirección eliminada');
            return data || [];
        }
        catch (error) {
            console.error(error);
        }
    }

    static async deleteAddress(id) {
        try {
            const response = await fetch(`${API_URL}/addresses/address/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            toast.success('Dirección eliminada');
            return data || [];
        }
        catch (error) {
            console.error(error);
        }
    }


    static async addAddress(address) {
        try {
            const response = await fetch(`${API_URL}/addresses/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify(address),
            });
            const data = await response.json();
            toast.success('Dirección agregada');
            return data || [];
        }
        catch (error) {
            console.error(error);
            toast.error('Error al agregar la dirección');
        }
    }

    static async getAddresses() {
        try {
            const response = await fetch(`${API_URL}/addresses/all`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            return data || [];

        } catch (error) {
            console.error(error);
        }
    }

}

export default AddressService;

