import styled from "styled-components";


export const MegaMenuContainer = styled.section`

    width: 100%;
    height: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? '100vmax' : '50px'};
    display: flex;
    background-color: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? 'rgba(0, 0, 0, 0.2)' : '#fff'};
    border-top: ${({ isMegaMenuOpen }) => !isMegaMenuOpen ? '1px solid #e0e0e0' : 'none'};
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;

    ${({ isMegaMenuOpen }) => isMegaMenuOpen ? `
        position: fixed;
        top: 0;
        left: 0;
        ` : `
        position: relative;
        `}
    z-index: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? '1000' : '0'};
    & * {
        margin: 0 0px;
    }

    @media screen and (max-width: 600px){
        display: none;
    }


`;

export const LinkButton = styled.a`
    text-decoration: none;
    color: #212121;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 0px;
    gap: 15px;
    font-weight: 700;
    transition: all 0.1s ease-in-out;
    min-height: 50px;
    display: ${({ show }) => show ? 'none' : 'flex'};
    ${({ isMegaMenuOpen }) => isMegaMenuOpen ? `
        background-color: #fff;
        ` : `
        background-color: transparent;
        `}
    


    &:hover{
        background-color: #f5f5f5;
    }

    
    & > svg {
        stroke: #212121;
    }

    &:active{
        outline: 1px solid #66bb6a;
        box-shadow: 0 0 2px 2px #66bb6a;
    }
    ${({ special }) => special ? `
    background-color: #028322;
    color: #fff;
    &:hover{
        background-color: #01741e;
    }
    ` : `
    `}
    max-width: 300px;
    height: 50px;

`;

export const Slogan = styled.span`
    font-size: 16px;
    color: #212121;
    display: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? 'none' : 'flex'};
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 0px;
    gap: 15px;
    font-weight: 700;
    transition: all 0.1s ease-in-out;
    min-height: 50px;
    margin: 0 auto;
    `;

export const MegaMenuContent = styled.div`
    width: ${({ isMegaMenuOpen }) => !isMegaMenuOpen ? '100%' : '500px'};
    height: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? '100vmax' : '50px'};
    display: flex;
    flex-direction: row;
    gap: 0px;
    padding: 10px 0;
    background-color: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? 'transparent' : '#fff'};
    box-sizing: border-box;
    border-bottom: ${({ isMegaMenuOpen }) => !isMegaMenuOpen ? '1px solid #e0e0e0' : 'none'};
    overflow-y: hidden;
    padding: 0 70px;
    z-index: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? '1000' : '0'};
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #e0e0e0;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }
    position: absolute;
    margin-top: ${({ isMegaMenuOpen }) => isMegaMenuOpen ? '75px' : '0'};
    left: 0;
`;

export const MegaMenuCategoryContainer = styled.div`
    width: 300px;
    height: ${({ isMegaMenuOpen, windowHeight }) => isMegaMenuOpen ? windowHeight/1.2+'px' : '50px'};
    ${({ isMegaMenuOpen }) => isMegaMenuOpen ? `
    background-color: #fff;
    ` : `
    background-color: transparent;
    `}
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    overflow-y: auto;

    ${({ isMegaMenuOpen }) => isMegaMenuOpen ? `
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #028322;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }
    ` : `
    `}


`;

export const MegaMenuCategory = styled.div`

    ${({ isMegaMenuOpen, windowHeight, windowWidth }) => isMegaMenuOpen ? `

    width: ${windowWidth/1.5+'px'};
    height: ${windowHeight/1.2+'px'};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    position: absolute;
    top: 75px;
    left: 370px;
    flex-direction: column;
    border-radius: 0px 5px 5px 0px;
    ` : `
    display: none;
    `}

    

`;

export const MegaMenuCategoryProducts = styled.div`
    width: auto;
    height: auto;
    display: flex;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
    overflow-y: auto;
    `;



export const MegaMenuCategoryBento = styled.div`
    padding: 20px 50px;

    background-color: #028322;
    color: #fff;
    border-radius: 16px;
    min-width: 350px;
    height: 130px;
    box-sizing: border-box;
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

`;