import { ExplanationBody, ExplanationContainer, ExplanationIcon, ExplanationText, ExplanationTitle } from "./style";

export default function ExplanationItem(props){
    return (
        <ExplanationContainer {...props}>
            <ExplanationIcon>
                {props.icon}
            </ExplanationIcon>
            <ExplanationBody>
            <ExplanationTitle>
                {props.title}
            </ExplanationTitle>
            <ExplanationText>
                {props.children}
            </ExplanationText>
            </ExplanationBody>
        </ExplanationContainer>
    )
}