import React, {useEffect, useState} from 'react';
import { DividedCardContainer, DividedContainerRow, H1, H2, ProductCard, ProductImage, ProductInfo, ProductName, ProductsContainer, UserContainer } from './style';
import { DividedContainer } from './style';
import Card from '../../../Components/Miscellaneous/Card';
import { useParams } from 'react-router-dom';
import AuthService from '../../../AuthService';
import { ProductCardImage, ProductCardTitle } from '../../Categories/style';
import Button from '../../../Components/Miscellaneous/Button';
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { FileUploader } from "react-drag-drop-files";
import { toast } from 'react-toastify';

export default function Order() {

    const [order, setOrder] = useState([]);

    const {id} = useParams();
    const getOrder = async () => {
        fetch(`https://api.rb.beeapp.es/admin/orders/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthService.getToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setOrder(data);
            });
    }
    useEffect(() => {

        getOrder();

    }
    , [id]);
    const handleChange = (file) => {
        const formData = new FormData();
        formData.append('invoice', file);
        formData.append('id', id);
        fetch(`https://api.rb.beeapp.es/admin/invoice`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${AuthService.getToken()}`,
            },
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                toast.success('Factura subida correctamente');
            }
            );
    };
    const updateStatus = async (status) => {
        fetch(`https://api.rb.beeapp.es/admin/orders/${id}/status`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthService.getToken()}`,
            },
            body: JSON.stringify({
                Status: status,
            }),
        })
            .then(response => response.json())
            .then(data => {
                window.location.reload();
            }
            );
    }

    const status = {
        'CREATED': 'Creado',
        'PREPARING': 'Preparando',
        'SHIPPED': 'Enviado',
        'DELIVERED': 'Entregado',
        'CANCELLED': 'Cancelado',
    }
    return (
        <UserContainer>
            <H1>Pedido #{id}</H1>
            <H2>
                Estado: {status[order?.Status?.toUpperCase()]}
            </H2>
            <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
            <H2>Subtotal: {order.TotalAmount && order?.TotalAmount.toFixed(2).toString().replace('.',',')} €</H2>
            <H2>Gastos de envío: {order?.ShippingCosts}€</H2>
            <H2>Total sin iva: {(order?.TotalAmount + order?.ShippingCosts).toFixed(2)} €</H2>
            <H2>Total con iva: {((order?.TotalAmount + order?.ShippingCosts)*1.21).toFixed(2)} €</H2>
            </div>
            <DividedContainer>
                <DividedContainerRow primary>
                    <H2>Información del pedido</H2>
                    <DividedCardContainer>
                    <Card
                        sm
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                        <span
                            style={{
                                fontWeight: 'bold',
                                fontSize: '1.2em',
                            }}
                        >{order?.UserID?.Name} {order?.UserID?.Surname}</span>
                        <span>{order?.UserID?.Email}</span>
                        <span>{order?.UserID?.Phone}</span>
                        <span>¿Recargo por equivalencia? {order?.UserID?.EquivalenceSurcharge ? 'Si' : 'No'}</span>
                        </div>
                    </Card>
                    {
                        order?.Address && order?.Address.map((address, index) => {
                            return (
                                <Card
                                    key={index}
                                    sm
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                        }}
                                    >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2em',
                                        }}
                                    >Dirección de entrega</span>
                                    <span>{address.Address}</span>
                                    <span>{address.City}</span>
                                    <span>{address.PostalCode}</span>
                                    </div>
                                </Card>
                            )
                        }
                        )
                        
                    }
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            width: 250
                        }}
                    >
                    {
                        order.Billings && order.Billings.length > 0 && order.Billings.map((billing, index) => {
                            return (
                                <Button primary  key={index}
                                    onClick={() => {
                                        window.open(billing.BillURL);
                                    }}
                                >
                                    Descargar factura #{index + 1}
                                </Button>
                            )
                                })
                    }
                            <FileUploader handleChange={handleChange}

                            name="invoice" types={['PDF']} label="Suelta la factura aquí"/>
                    </div>
                    </DividedCardContainer>
                </DividedContainerRow>
                <DividedContainerRow>
                    <H2>Acciones</H2>
                    {
                        order?.Status?.toUpperCase() !== 'SHIPPED' ? (
                            <>
                                                <Button primary
                        onClick={() => {
                            updateStatus('Shipped');
                        }}
                    >Enviar</Button>
                    
                    <Button outline
                        onClick={() => {
                            updateStatus('Preparing');
                        }}
                    >Preparar</Button>
                            </>
                        )
                        : null
                    }
                    
                    <Button outline 
                        onClick={() => {
                            updateStatus('Cancelled');
                        }}
                    >Cancelar</Button>
                </DividedContainerRow>
            </DividedContainer>
            <H2>Productos</H2>
            <ProductsContainer>
                {order.Products && order.Products.map((product, index) => {
                    return (
                        <ProductCard>
                            <ProductImage src={product.product.images[0]} alt={product.product.name} />
                            <ProductInfo>
                                <ProductName>{product.product.name}</ProductName>
                                <span>{product.quantity > 1 ? product.quantity + ' unidades' : product.quantity + ' unidad' }</span>
                                <span>Precio: {product.price.toFixed(2).toString().replace(".", ",")} €</span>
                                <span>Subtotal: {(product.price * product.quantity).toFixed(2).toString().replace(".", ",")} €</span>
                            </ProductInfo>
                            <Button primary width="50px">
                                {product.prepared ? <TiTick/> : <RxCross2/>}
                            </Button>
                        </ProductCard>

                    )
                })}
            </ProductsContainer>

            
        </UserContainer>
    )
}
