import React, {useEffect, useState} from 'react';
import { H1, UserContainer, UsersTable, UsersTableBody, UsersTableHead, UsersTableHeadColumn,UsersTableBodyColumn, UsersTableRow, Pill } from './style';
import axios from 'axios';
import AuthService from '../../../AuthService';
import Button from '../../../Components/Miscellaneous/Button';
import { FiEdit } from "react-icons/fi";
import { PaginationButton, PaginationContainer } from '../../Categories/style';
import Modal from '../../../Components/Miscellaneous/Modal';
export default function Users() {

    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingUser, setEditingUser] = useState();
    const [modalOpened, setModalOpened] = useState(false);
    useEffect(() => {
        axios.get('https://api.rb.beeapp.es/admin/users?limit=5000', {
            headers: {
                'Authorization': 'Bearer ' + AuthService.getToken()
            }
        }).then(response => {
            return response.data;
        }
        )
        .then(data => {
            setUsers(data.results);
            setTotalPages(data.totalPages);
            setCurrentPage(data.currentPage);
        }
        )
        .catch(error => {
            console.error(error);
        });
    }, []);
    return (
        <UserContainer>
            <H1>Usuarios</H1>
            <UsersTable>
                <UsersTableHead>
                    <UsersTableRow>
                    <UsersTableHeadColumn>CIF</UsersTableHeadColumn>
                    <UsersTableHeadColumn>Nombre</UsersTableHeadColumn>
                    <UsersTableHeadColumn>Apellido</UsersTableHeadColumn>
                    <UsersTableHeadColumn>Email</UsersTableHeadColumn>
                    <UsersTableHeadColumn>Telefono</UsersTableHeadColumn>
                    <UsersTableHeadColumn>Estado</UsersTableHeadColumn>
                    <UsersTableHeadColumn>Rol</UsersTableHeadColumn>
                    <UsersTableHeadColumn>Acciones</UsersTableHeadColumn>
                    </UsersTableRow>
                </UsersTableHead>
                <UsersTableBody>
                {
                   users.length !== 0 && users.map((user, index) => {
                        return (
                            <UsersTableRow key={index}>
                                <UsersTableBodyColumn>{user.CIF}</UsersTableBodyColumn>
                                <UsersTableBodyColumn>{user.Name}</UsersTableBodyColumn>
                                <UsersTableBodyColumn>{user.Surname}</UsersTableBodyColumn>
                                <UsersTableBodyColumn>{user.Email}</UsersTableBodyColumn>
                                <UsersTableBodyColumn>{user.Phone}</UsersTableBodyColumn>
                                <UsersTableBodyColumn><Pill color={user.isVerified ? "#028322" : "#ff8f00"}>{user.isVerified ? 'Activo' : 'Inactivo'}</Pill></UsersTableBodyColumn>
                                <UsersTableBodyColumn>{user.role}</UsersTableBodyColumn>
                                <UsersTableBodyColumn>
                                    <Button outline width="40px" height="40px"
                                        onClick={() => {
                                            setEditingUser(user._id);
                                            window.location.href = `/admin/user-edit/${user._id}`;
                                        }}
                                    >
                                        <FiEdit size="1rem" />
                                    </Button>
                                </UsersTableBodyColumn>
                            </UsersTableRow>
                        )
                    })
                }
                </UsersTableBody>
            </UsersTable>
            <PaginationContainer>
                {
                    Array.from(Array(totalPages).keys()).map((page, index) => {
                        return (
                            <PaginationButton key={index} active={currentPage === page + 1 ? true : false}>{page + 1}</PaginationButton>
                        )
                    }
                    )
                }
            </PaginationContainer>
            <Modal
                close={() => {setModalOpened(false)}}
                opened={modalOpened}
                title="Editar Usuario"                
            >
                <div>
                    <h1>Modal</h1>
                </div>
            </Modal>
        </UserContainer>
    )
}