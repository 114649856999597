import { PanelContainer } from './style';
import isMobile from '../Utils/isMobile';
import { Loader } from '../../../Screens/Login/style';
export default function Panel(props){

    if (props.loading) {
        return (
            <PanelContainer {...props}>
                <Loader/>
            </PanelContainer>
        );
    }

    return (
        <PanelContainer {...props}
            {
                ...props.ptop && isMobile() ? {ptop: '50px'} : {}
            }
        >
            {props.children}
        </PanelContainer>
    );
}