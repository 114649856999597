import React, { useState, useEffect } from "react";
import { FloatingButton, FloatingButtonContainer, FloatingButtonContent } from "./style";

export default function FloatingActionButton(props){

    //put a timeout to hide the button after 5 seconds
    const [show, setShow] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 5000);
    }, []);


    function parseText(text){

        //Parse * * to <b></b> and _ _ to <i></i> and return the parsed text
        let parsedText = text;

        parsedText = parsedText.replace(/\*([^\*]+)\*/g, '<b>$1</b>');
        parsedText = parsedText.replace(/_([^_]+)_/g, '<i>$1</i>');

        return parsedText;

    }

    return (
        <FloatingButtonContainer show={show}>
            <FloatingButtonContent show={show}>
                <span dangerouslySetInnerHTML={{__html: parseText(props.text)}}></span>
            </FloatingButtonContent>
        <FloatingButton {...props}>
            {props.children}
        </FloatingButton>
        </FloatingButtonContainer>
        );
}