import React, {useState} from "react";
import { GoBackButton, HeaderContainer, Link, ProfileButton, SearchContainer, SearchContent, SearchInput } from "./style";

import { BiChevronLeft } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { useParams } from "react-router-dom";
import AuthService from "../../../AuthService";
import InputWithAbortController from "./input";
export default function Header() {

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const [searchFocus, setSearchFocus] = useState(false);

    const {tab} = useParams();
    const searchProducts = async (searchTerm, signal) => {
        return fetch(`https://api.rb.beeapp.es/admin/search?s=${searchTerm}`, {
            signal: signal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthService.getToken()}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setSearchResults(data);
            });

        }

    
    
    return (
        <HeaderContainer>
            <GoBackButton
                onClick={() => window.location.href = '/'}
            >
                <BiChevronLeft size={24} color="#026E1F" />
                
            </GoBackButton>
            <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0px',
                marginRight: 'auto',
                marginLeft: '10px',
            }}
            >
            <Link href="/admin" active={tab === '' || tab === null || tab === undefined} >
                Productos
            </Link>

            <Link href="/admin/users" active={tab === 'users'} >
                Usuarios
            </Link>

            <Link href="/admin/sells" active={tab === 'sells'}>
                Ventas
            </Link>
            <Link href="/admin/home-editor" active={tab === 'home-editor'}>
                Home
            </Link>
            </div>

            <SearchContainer
                searchFocus={searchFocus}
            >
                <InputWithAbortController onSearch={searchProducts}                 onFocus={() => {
                    setSearchFocus(true);
                    document.body.style.overflow = 'hidden';
                }} />

                <div style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: 'calc(100vh - 50px)',
                    position: 'absolute',
                    top: '50px',
                    left: '0',
                    backgroundColor: '#fff',
                    zIndex: '100',
                    display: searchFocus ? 'block' : 'none',
                    overflow: 'auto',
                    boxShadow: '0px 4px 6px -4px rgba(0,0,0,0.25)',
                }}>
                    <span style={{
                        display: 'flex',
                        padding: '10px',
                        borderBottom: '1px solid #026E1F',
                        backgroundColor: '#fff',
                        fontSize: '1.5rem',
                        gap: '10px',
                        alignItems: 'center',
                        

                    }}>
                        Productos
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'normal',
                            color: '#666',
                        }}>
                            {searchResults?.products?.totalSearchedProducts || 0} resultados
                            </span>
                        
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'normal',
                            color: '#028322',
                            marginLeft: 'auto',
                            cursor: 'pointer',
                        }}>
                            Ver todos
                            </span>

                    </span>
                    {
                        searchResults?.products?.productsSearch?.length !== 0 && searchResults?.products?.productsSearch?.map((product, index) => {
                            return (
                                <span key={index} style={{
                                    display: 'block',
                                    padding: '10px',
                                    borderBottom: '1px solid #026E1F',
                                    cursor: 'pointer',
                                    backgroundColor: '#fff',
                                }}
                                    onClick={() => {
                                        window.location.href = '/admin/product-edit/' + product._id;
                                    }}
                                >
                                    <img src={product.images[0]} alt={product.name} style={{
                                        width: '20px',
                                        height: '20px',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                        marginRight: '10px',
                                    }}/>
                                    {product.name}
                                </span>
                            )
                        })
                    }
                    <span style={{
                        display: 'flex',
                        padding: '10px',
                        borderBottom: '1px solid #026E1F',
                        backgroundColor: '#fff',
                        fontSize: '1.5rem',
                        gap: '10px',
                        alignItems: 'center',
                    }}>
                        Usuarios
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'normal',
                            color: '#666',
                        }}>
                            {searchResults?.users?.totalSearchedUsers || 0} resultados
                            </span>
                        
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'normal',
                            color: '#028322',
                            marginLeft: 'auto',
                            cursor: 'pointer',
                        }}>
                            Ver todos
                            </span>
                        </span>
                    {
                        searchResults?.users?.usersSearch?.length !== 0 && searchResults?.users?.usersSearch?.map((user, index) => {
                            return (
                                <span key={index} style={{
                                    display: 'block',
                                    padding: '10px',
                                    borderBottom: '1px solid #026E1F',
                                    cursor: 'pointer',
                                    backgroundColor: '#fff',
                                }}
                                    onClick={() => {
                                        window.location.href = '/admin/user-edit/' + user._id;
                                    }}
                                >
                                    {user.Name} {user.Surname} ({user.Email}) 
                                </span>
                            )
                        })
                    }
                </div>
            </SearchContainer>
            
            <ProfileButton>
                <BiUser size={24} color="#fff" />
            </ProfileButton>
        </HeaderContainer>
        )
    }
