import styled from 'styled-components';
import React from 'react';
export const CardContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
    padding: 0.5rem 2rem;

`;

export const UserContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    padding: 0.5rem 2rem;
`;


export const H1 = styled.h1`
    font-size: 3rem;
    font-weight: 500;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #028322;
`;

export const UsersTable = styled.table`
    width: 100%;
    height: auto;
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
`;

export const UsersTableHead = styled.thead` 
    width: 100%;
    height: auto;
    background-color: #028322;
    color: #fff;
    box-sizing: border-box;
`;

export const UsersTableBody = styled.tbody`
    width: 100%;
    height: auto;
    box-sizing: border-box;
`;

export const UsersTableRow = styled.tr`
    width: 100%;
    height: auto;
    box-sizing: border-box;
    &:nth-child(even){
        background-color: #f5f5f5;
    }
`;

export const UsersTableHeadColumn = styled.th`
    height: 50px;
    box-sizing: border-box;
    padding: 0 1rem;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    font-family: Inter, sans-serif;
    text-transform: uppercase;
`;

export const UsersTableBodyColumn = styled.td`
    height: 50px;
    box-sizing: border-box;
    padding: 0 1rem;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
`;

export const Pill = styled.div`
    width: auto;
    height: auto;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: ${({ color }) => color};
    font-size: 1rem;
    font-weight: 500;
    font-family: Inter, sans-serif;
    color: #fff;
    box-sizing: border-box;
`;

export const OrderContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
    padding: 0.5rem 2rem;
    border: 1px solid #e0e0e0;
    &:nth-child(even){
        background-color: #f5f5f5;
    }
    cursor: pointer;
    &:hover{
        background-color: #e0e0e0;
    }
`;

export const OrderName = styled.h2`
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #028322;
`;

export const OrderDate = styled.p`
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #028322;
`;


export const CardsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    gap: 10px;
    box-sizing: border-box;
    padding: 0.5rem 2rem;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;

export const CardH2 = styled.h2`
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #fff;
    text-align: center;
`;

export const CardInfo = styled.p`
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #fff;
    text-align: center;
`;

export const DividedContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
`;

export const DividedContainerRow = styled.div`
    width: ${props => props.primary ? '70%' : '30%'};
    height: auto;
    display: flex;
    gap: 10px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
`;

export const DividedCardContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;

export const H2 = styled.h2`
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #028322;
`;

export const ProductsContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    padding: 0.5rem 2rem;
`;

export const ProductCard = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
        background-color: #f5f5f5;
    }
`;

 const ProductImageComponent = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
`;

export const ProductImage  = ({src, alt}) => {
    const [error, setError] = React.useState(false);

    const handleError = () => {
        setError(true);
    }

    return <ProductImageComponent src={error ? 'https://cdn.beeapp.es/distribucionesrb/NoImage.png' : src} alt={alt} onError={handleError} />
}

export const ProductInfo = styled.div`
    width: calc(100% - 100px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
`;

export const ProductName = styled.h2`
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #000;
`;

export const ProductPrice = styled.p`
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #000;
`;

export const ProductQuantity = styled.p`
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    font-family: Inter, sans-serif;
    color: #028322;
`;

export const HomeItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    padding: 0.5rem 2rem;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
`;

export const HomeItemImage = styled.img`
    width: auto;
    height: 250px;
    object-fit: cover;
    border-radius: 5px;
`;