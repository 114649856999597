import React, { createContext, useContext, useState, useEffect } from 'react';
import AuthService from '../AuthService'; // Import your AuthService

// Create the AuthContext
const AuthContext = createContext();

// Create a custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  useEffect(() => {
    // Check if the user is already authenticated on component mount
    async function checkAuth() {
      try {
        const user = await AuthService.isAuthenticated();
        const userProfile = await AuthService.getUser();
        if (!user) {
          setUser(null);
          setLoggedIn(false);
          setLoading(false);
          return;
        }
        setUser(userProfile);
        setLoggedIn(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }

    checkAuth();
    
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const user = await AuthService.login(email, password);
      setUser(user);
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await AuthService.logout();
      setUser(null);
      setLoggedIn(false);
    } catch (error) {
      throw error;
    }
  };

  const contextValue = {
    user,
    login,
    logout,
    loggedIn,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
