import React, { useState, useEffect } from 'react';
import Headroom from 'react-headroom';
import Head from './head';
import PreHead from './prehead';
import Menu from './menu';
import { MobileSearchDrawer, SearchLink, SearchMobile, SearchMobileButton, SearchMobileContainer } from './Styles/head';
import {AiOutlineSearch} from 'react-icons/ai';
import Typed from 'react-typed';
import SlidingCart from '../SlidingCart';
import MegaMenu from '../MegaMenu';
import ProductService from '../../Utils/API/products';
import MaterialDesignProgressBar from './Styles/loader';
export default function Header() {
    const [openCart, setOpenCart] = useState(false);
    const [openSearch, setOpenSearch] = useState(true);
    const [search, setSearch] = useState('');
    const [products, setProducts] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const makeSearch = (search) => {
        setSearchLoading(true);
        ProductService.searchProducts(search).then(res => {
            setProducts(res);
            setSearchLoading(false);
        }
        );
    }

    function boldSubstringInString(inputString) {
        // Create a regular expression pattern that matches all search terms
        const searchPattern = new RegExp(`(${search.split(' ').map(escapeRegExp).join('|')})`, 'gi');
      
        // Replace matching substrings with <b> tags
        const resultString = inputString.replace(searchPattern, '<b>$&</b>');
      
        return (
          <div dangerouslySetInnerHTML={{ __html: resultString }} />
        );
      }
      
      // Helper function to escape regular expression special characters
      function escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
      
    
    return (
        <>
        <Headroom>
        <Head openCart={openCart} setOpenCart={setOpenCart}/>
        {/* <Menu/> */}
        <MegaMenu/>
        <SearchMobileContainer openSearch={openSearch}>
            <div style={{
                height: 50,
                width: '100%',
                display: 'flex',
            }}>
        <Typed
                strings={[
                    'Blister de ceras plastidecor',
                    'Ref. 0011423',
                    'EAN 8410565051348']}
                    typeSpeed={40}
                    backSpeed={50}
                    attr="placeholder"
                    loop >
                <SearchMobile
                    onClick={
                        () => {
                            if (search.length > 0) {
                                setOpenSearch(false);
                            }else{
                                setOpenSearch(true);
                            }
                        }
                    }
                    type="text"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        if (e.target.value.length > 0) {
                            setOpenSearch(false);
                            makeSearch(e.target.value);
                        } else {
                            setOpenSearch(true);
                        }
                    }
                    }
            ></SearchMobile>
            </Typed>
            <SearchMobileButton
                onClick={() => {
                    window.location.href = `/search?s=${search}`;
                }}
            >
                <AiOutlineSearch
                    size={24}
                    color="#fff"
                />
            </SearchMobileButton>
            </div>
            <div
                style={{
                    height: 'calc(100% - 50px)',
                    width: '100%',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                    overflowX: 'hidden',
                }}
            >
                <>
                {
                    searchLoading && <MaterialDesignProgressBar/>
                }
                                        
            {
                products.map((product, index) => {
                    return (
                        <SearchLink key={index}
                            href={'/details/'+product.productURL}
                        >
                            {boldSubstringInString(product.name)}
                        </SearchLink>
                    )
                }
                )
            }
            </>
            </div>
        </SearchMobileContainer>
        </Headroom>
        </>
    );
}
