import React, {useState, useEffect, useContext} from 'react';
import { DetailsContainer, DetailsContentBreadCrumb, DetailsContent, DetailsContentImage, DetailsContentImageContainer, DetailsContentInfo, DetailsContentInfoTitle, DetailsContentInfoDescription,DetailsContentComparativePrice, DetailsContentInfoPriceContainer, DetailsContentInfoPrice, DetailsContentInfoReference, DetailsContentInfoReferenceSpan, DetailsContentInfoActions, DetailsContentInfoStock, DetailsLoader, DetailsContentInfoMustLogin, DetailsContentSimilarProducts, DetailsContentSimilarProductsTitle, DetailsContentSimilarProductsContainer, DetailsContentSimilarProductsItem, DetailsContentSimilarProductsItemImage, DetailsContentSimilarProductsItemTitle, DetailsContentSimilarProductsItemPrice, DetailsContentVariants, DetailsContentVariantsItem, DetailsContentVariantsItemColor, DetailsContentVariantsItemImage, DetailsContentVariantsItemSize, DetailsActionQuantity, DetailsActionQuantityButton, DetailsActionQuantityInput, DetailsContentInfoMustLoginBackdrop, DetailsSaveMore, DetailsSaveMoreTitle, DetailsSaveMoreDescription, ProgressBar, ProgressBarProgress, ProgressBarProgressPill, ProgressBarContainer, ProgressPill, DetailsAddedBadge, DetailsBackdrop, DetailsModal, EmptyCart, DetailsModalTitle, DetailsModalDescription, DetailsModalFooter, DetailsContentComparativePriceContainer, DetailsContentComparativePriceItem, DetailsContentComparativePriceItemLoaderThreeDots } from './style';
import Button from '../../Components/Miscellaneous/Button';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { Link, useParams } from 'react-router-dom';
import { Loader } from '../Login/style';

import { BsBox2 } from 'react-icons/bs';
import { motion } from 'framer-motion';
import AuthService from '../../AuthService';
import ProductService from '../../Utils/API/products';
import CartService from '../../Utils/API/cart';
import { BiLock } from 'react-icons/bi';
import { useAuth } from '../../Context/Auth';
import Barcode from 'react-barcode';
import { Tooltip } from 'react-tooltip'
import { IoMdInformationCircleOutline,IoIosClose } from "react-icons/io";
import ReactGA from "react-ga4";
import { usePostHog } from 'posthog-js/react'

import { MdCompareArrows } from 'react-icons/md';

export default function Details(props) {

    let { category,id } = useParams();
    const posthog = usePostHog();
    const [product, setProduct] = useState({});
    const [loader, setLoader] = useState(true);
    const [cartLoader, setCartLoader] = useState(false);
    const {loggedIn} = useAuth();
    const [similarProducts, setSimilarProducts] = useState([{}]);
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [added, setAdded] = useState(false);
    const [unids, setUnids] = useState(0);
    const [modal, setModal] = useState(false);
    const [loadAzeta, setLoadAzeta] = useState(false);
    const [azetaPrice, setAzetaPrice] = useState("");
    const [showComparativePrice, setShowComparativePrice] = useState(false);
    useEffect(() => {

        if(loggedIn){
            CartService.getCart().then(response => {
                if (response.message) {
                    return;
                }
                response.items.forEach(product => {
                    if(product.productURL === category+"/"+id){
                        setAdded(true);
                        setUnids(product.quantity);
                    }
                }
                );
            }
            );
        }

        posthog?.capture({type: 'view_item', properties: {productID: id, category: category}});

            fetch('https://api.rb.beeapp.es/analytics/track', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + AuthService.getToken(),
                },
                body: JSON.stringify({
                    eventType: 'view',
                    Date: new Date(),
                    Referer: window.location.href,
                    productID: id
    
                })
            }).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
    }, [category, id, loggedIn]);


    useEffect(() => {
        console.log(props);
        ReactGA.event({
            name: "view_item",
            category: "ecommerce",
            action: "view_item",
            currency: 'EUR',
            value: price,
            items: [
                {
                    id: product._id,
                    name: product.name,
                    category: category,
                    price: price,
                    quantity: quantity,
                }
            ]
        });
        const getProduct = async () => {
            const product = await ProductService.getProduct("/details/"+category+"/"+id);
            if(product.message){
                window.location.href = '/404';
                return;
            }
            setPrice(product.price);
            setProduct(product);
            setQuantity(product.quantityStep);
            setSimilarProducts(product.similarProducts);
            setLoader(false);
        }

        getProduct();
    }, [id, category, props]);

    const compareAzeta = () => {
        setLoadAzeta(true);
        //URL http://localhost:8888/azeta?ean=&price=quantityStep*price
        fetch('http://localhost:8888/azeta?ean='+product.EAN+'&price='+product.price*product.quantityStep, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        ).then(response => {
            //response second element is the price
             response.json().then(data => {
                setAzetaPrice(data.price);
            });

            setLoadAzeta(false);
        }).catch(error => {
            console.log(error);
        });

    }


    const addQuantity = () => {
        setQuantity(quantity + product.quantityStep);
        //if product have discounts, check quantity and if it greater to product.discounts.minQuantity change price to product.discounts.discountedPrice
        //make a search in product.discounts array for the discount that match the quantity
        product.discounts && product.discounts.forEach(discount => {
            if (quantity+1 >= discount.minQuantity) {
                setPrice(discount.discountedPrice);
            }
        }
        );
    }

    const removeQuantity = () => {
        if (quantity - product.quantityStep < 1) {
            return;
        }
        setQuantity(quantity - product.quantityStep);

        //if product have discounts, check quantity and if it lower to product.discounts.minQuantity change price to the previous price or the original price
        //make a search in product.discounts array for the discount that match the quantity search the lower discount and set the price to the previous price or the original price
        let previousPrice = product.price;
        product.discounts && product.discounts.forEach(discount => {
            if (quantity - product.quantityStep >= discount.minQuantity) {
                previousPrice = discount.discountedPrice;
            }
        }
        );
        setPrice(previousPrice);



    }

    const addProductToCart = () => {
        if (loggedIn) {
            setCartLoader(true);

            //Add product to cart for ReactGA
            ReactGA.event({
                name: 'add_to_cart',
                category: "ecommerce",
                action: "add_to_cart",
                currency: 'EUR',
                value: price,
                items: [
                    {
                        id: product._id,
                        name: product.name,
                        category: category,
                        quantity: quantity,
                        price: price,
                    }
                ]
            });

            posthog?.capture({type: 'add_to_cart', properties: {productID: product._id, category: category, quantity: quantity, price: price}});

            CartService.addProductToCart({
                id: product._id,
                quantity: quantity,
            }).then(response => {
                setCartLoader(false);
            }).catch(error => {
                setCartLoader(false);
            }
            );
        } else {
            props.history.push('/login');
        }
    }

    const addProductToCart2 = (quantity) => {
        if (loggedIn) {
            setCartLoader(true);
            CartService.addProductToCart({
                id: product._id,
                quantity: quantity,
            }).then(response => {
                setCartLoader(false);
            }).catch(error => {
                setCartLoader(false);
            }
            );
        } else {
            props.history.push('/login');
        }
    }

    const addUnids = () => {
        if(added){
            setModal(true);
        }else{
            setUnids(quantity);
            addProductToCart();
            setAdded(true);
        }
    }

    const onChangeQuantity = (e) => {
        //clump quantity to the nearest multiple of quantityStep
        //wait until stop typing to set quantity
        

        let value = e;
        let remainder = value % product.quantityStep;
        let newValue = value - remainder;
        if (remainder > product.quantityStep / 2) {
            newValue += product.quantityStep;
        }

        setQuantity(newValue);


        //check if quantity is in range of discounts and set the price to the correct price
        let previousPrice = product.price;
        product.discounts && product.discounts.forEach(discount => {
            if (newValue >= discount.minQuantity) {
                previousPrice = discount.discountedPrice;
            }
        }
        );
        setPrice(previousPrice);


    }

    const SanitizeCategory = (category) => {
        //Remove - and replace with space, then capitalize first letter of each word
        let categoryArray = category.split('-');
        let categoryString = '';
        for (let i = 0; i < categoryArray.length; i++) {
            categoryString += categoryArray[i].charAt(0).toUpperCase() + categoryArray[i].slice(1) + ' ';
        }
        return categoryString;
    }


    const breadcrumbgenerator = (array) => {
        let breadcrumb = [];
        for (let i = 0; i < array.length; i++) {
            if (i === array.length - 1) {
                breadcrumb.push(
                    <span
                        key={i}
                        style={{
                            color: '#359C4F',
                            fontWeight: 600,
                        }}
                    >
                        {array[i]}
                    </span>
                )
            } else {
                breadcrumb.push(
                    <Link
                        key={i}
                        style={{
                            color: '#000',
                            fontWeight: 400,
                            cursor: 'pointer',
                            textDecoration: 'none',
                        }}
                        to={'/categories/' + array[i].trim().replace(/ /g, '-').toLowerCase()}
                        
                    >
                        {array[i]} /&nbsp;
                    </Link>
                )
            }
        }
        return breadcrumb;
    }


    return (
        <>
        <DetailsContainer>
            {
                 loader && Object.keys(product).length === 0  ? (
                    <div style={{display: 'flex', padding: 40, justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box', height: '100vh'}}>
                        <DetailsLoader/>
                    </div>
                 ) : (
                    <>
                                <DetailsContentBreadCrumb>
                {breadcrumbgenerator([SanitizeCategory(category), product.name])}
                {/* {loggedIn && product.EAN !== "" && (
                                            <DetailsContentInfoActions
                                                style={{
                                                    marginTop: 10,
                                                    justifyContent: 'center',
                                                }}
                                            >
                        <DetailsContentComparativePrice
                                                                        onClick={() => setShowComparativePrice(!showComparativePrice)}

                        >
                            <MdCompareArrows size={20} color="inherit"/>
                            Comparar con otros vendedores
                        </DetailsContentComparativePrice>
                        <motion.div 
                            //animation show depending on showComparativePrice
                            initial={{height: 0, opacity: 0, display: 'none'}}
                            animate={{height: showComparativePrice ? 'auto' : 0, opacity: showComparativePrice ? 1 : 0, display: showComparativePrice ? 'block' : 'none', top: showComparativePrice ? 30 : -10}}
                            transition={{duration: 0.3}}

                            


                        >
                            <DetailsContentComparativePriceContainer>
                                <DetailsContentComparativePriceItem>
                                    <img src="https://www.edicionesdelserbal.com/img/cms/logo-azeta.jpg" alt="Azeta" style={{width: 'auto', height: 40}}/>
                                    {
                                        loadAzeta ? (
                                            <DetailsContentComparativePriceItemLoaderThreeDots/>
                                        ) : (
                                            azetaPrice ? (
                                                <span
                                                    style={{
                                                        fontWeight: 600,
                                                        fontSize: '1.2rem',
                                                        color: `
                                                            ${azetaPrice.toString().includes("+") ? '#ff0000' : '#028322'}
                                                        `,
                                                        width: '100%',
                                                        textAlign: 'center',
                                                    }}
                                                >{azetaPrice}</span>
                                                ) : (
                                                <Button outline fontSize="16px" fontWeight="600" width="100%" expandMobile onClick={() => compareAzeta()}>Ver precio</Button>
                                            )
                                        )
                                    }
                                </DetailsContentComparativePriceItem>

                            </DetailsContentComparativePriceContainer>
                        </motion.div>
                        </DetailsContentInfoActions>
                    )} */}
            </DetailsContentBreadCrumb>
            {
                        loggedIn && added && (
                            <DetailsAddedBadge>
                                <span style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    gap: 2,
                                    fontWeight: 600,
                                    fontSize: '2rem',

                                }}>
                                    {unids}
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            fontSize: '1rem',
                                        }}
                                    >unids.
                                    </span>
                                </span>
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 10,
                                    }}
                                >
                                <IoMdInformationCircleOutline
                                    size={20}
                                /> Añadido al carrito
                                </span>
                            </DetailsAddedBadge>
                        )
                    }
            <DetailsContent>
                <DetailsContentImage>
                    <Zoom>
                    <DetailsContentImageContainer src={product.images[0]}/>
                    </Zoom>
                </DetailsContentImage>
                <DetailsContentInfo>
                    <DetailsContentInfoTitle>{product.name}</DetailsContentInfoTitle>

                    <DetailsContentInfoPriceContainer>
                        {
                            loggedIn ? (
                                
                                    product.discounts && product.discounts.length > 0 ? (
                                        <DetailsSaveMore>
                                        <DetailsSaveMoreTitle>
                                            <BsBox2 size={20} color="#fff"
                                                style={{
                                                    minWidth: 20,
                                                }}
                                            />
                                            ¡Ahorra más comprando más!</DetailsSaveMoreTitle>
    
                                    <DetailsContentInfoPrice>{
                                        //depending on the quantity, show the price
                                        quantity >= product.discounts[product.discounts.length - 1].minQuantity ? (
                                            product.discounts[product.discounts.length - 1].discountedPrice.toFixed(2).toString().replace('.',',') + " €"
                                        ) : (
                                            price.toFixed(2).toString().replace('.',',') + " €"
                                        )
                                        }</DetailsContentInfoPrice>
                                    <ProgressBarContainer>
                                    <ProgressBar>
                                        <ProgressBarProgress progress={
                                            //return the next discount minQuantity
                                            
                                            quantity / (product.discounts.map(discount => discount.minQuantity).filter(minQuantity => minQuantity > quantity)[0] || quantity) * 100

                                        }>
                                            <ProgressBarProgressPill>{quantity}/{
                                                //depending on the quantity show the price of the next discount
                                                product.discounts.map(discount => discount.minQuantity).filter(minQuantity => minQuantity > quantity)[0] ? (
                                                    product.discounts.map(discount => discount.minQuantity).filter(minQuantity => minQuantity > quantity)[0]
                                                ) : (
                                                    product.discounts[product.discounts.length - 1].discountedPrice.toFixed(2).toString().replace('.',',') + "€"
                                                )

                                            }

                                            </ProgressBarProgressPill>
                                        </ProgressBarProgress>
                                    </ProgressBar>
                                        {
                                            product.discounts.map((discount, index) => {
                                                return (
                                                    <ProgressPill
                                                        key={index}
                                                        none={quantity >= discount.minQuantity}
                                                        onClick={() => {
                                                            onChangeQuantity(discount.minQuantity);
                                                        }}
                                                    >{discount.minQuantity}/{
                                                        discount.discountedPrice.toFixed(2).toString().replace('.',',')
                                                    }€</ProgressPill>
                                                )
                                            }
                                            )
                                        }
                                    </ProgressBarContainer>
                                    <DetailsSaveMoreDescription>
                                        {
                                            product.discounts.map(discount => discount.minQuantity).filter(minQuantity => minQuantity > quantity)[0] ? (
                                                <>
                                                Compra <b>{product.discounts.map(discount => discount.minQuantity).filter(minQuantity => minQuantity > quantity)[0] - quantity}</b> más para obtener un descuento.<br></br>
                                                </>
                                            ) : (
                                                <>
                                                Has alcanzado el máximo descuento.<br></br>
                                                </>
                                            )
                                        }

                                    </DetailsSaveMoreDescription>
                                    </DetailsSaveMore>
                                    ) : (
                                        <DetailsContentInfoPrice>{product.price && product.price.toFixed(2).toString().replace('.',',')} €</DetailsContentInfoPrice>
                                    )
                                

                            ) : (
                                <DetailsContentInfoMustLogin>
                                    <span style={{display: 'flex', alignItems: 'center', gap: 10,position: 'absolute', zIndex:100, width: 'auto', textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                                    <BiLock size={30} color="#000"
                                    
                                    />
                                    <span style={{fontWeight: 600, fontSize: '1.2rem', color: '#000'}}>Inicia sesión para ver el precio</span>
                                    </span>
                                    <DetailsContentInfoMustLoginBackdrop>
                                    0,00 €
                                    <DetailsContentVariants>
                        <DetailsContentVariantsItem selected>
                        <DetailsContentVariantsItemImage background={product.images[0]}></DetailsContentVariantsItemImage>
                        </DetailsContentVariantsItem>
                        {
                            product.alternatives.map((alternative, index) => {
                                return (
                                    <DetailsContentVariantsItem key={index}>
                                        <DetailsContentVariantsItemImage background={alternative.productID?.images[0]}></DetailsContentVariantsItemImage>
                                    </DetailsContentVariantsItem>
                                
                                )
                            }
                            )

                        }


                    </DetailsContentVariants>
                                    </DetailsContentInfoMustLoginBackdrop></DetailsContentInfoMustLogin>
                            )
                        }
                    </DetailsContentInfoPriceContainer>
                    {
                        loggedIn && (
                            <>
                    <a id="reference">
                    <DetailsContentInfoReference>Referencia. <DetailsContentInfoReferenceSpan>
                            {product.reference}
                         </DetailsContentInfoReferenceSpan></DetailsContentInfoReference>
                    </a>
                    <Tooltip anchorSelect='#reference'
                    place="left"
                    style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        borderRadius: 5,
                        padding: 10,
                        fontSize: 14,
                        fontWeight: 400,
                        width: 'auto',
                    }}
                >
                    <Barcode value={product.reference} />
                </Tooltip>
                <a id="ean">
                    <DetailsContentInfoReference>EAN. <DetailsContentInfoReferenceSpan>
                            {product.EAN}
                         </DetailsContentInfoReferenceSpan></DetailsContentInfoReference>
                    </a>
                    <Tooltip anchorSelect='#ean'
                    place="left"
                    style={{
                        backgroundColor: '#000',
                        color: '#fff',
                        borderRadius: 5,
                        padding: 10,
                        fontSize: 14,
                        fontWeight: 400,
                        width: 'auto',
                    }}
                >
                    <Barcode value={product.EAN} />
                </Tooltip>
                    <DetailsContentVariants>
                        <DetailsContentVariantsItem selected>
                        <DetailsContentVariantsItemImage src={product.images[0]}></DetailsContentVariantsItemImage>
                        </DetailsContentVariantsItem>
                        {
                            product.alternatives.map((alternative, index) => {
                                return (<>
                                    <a href={'/details/'+alternative.productID.productURL} id={`variant-${index}`}>
                                    <DetailsContentVariantsItem key={index}>
                                        <DetailsContentVariantsItemImage src={alternative.productID.images[0]}></DetailsContentVariantsItemImage>
                                    </DetailsContentVariantsItem>
                                    </a>
                                    <Tooltip anchorSelect={`#variant-${index}`}
                                    place="top"
                                    style={{
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        borderRadius: 5,
                                        padding: 10,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        width: 'auto',
                                    }}>
                                        {alternative.productID.name}
                                    </Tooltip>
                                    </>
                                )
                            }
                            )

                        }


                    </DetailsContentVariants>
                    <DetailsContentInfoDescription>
                        {product.description}
                    </DetailsContentInfoDescription>
                    
                    <DetailsContentInfoActions>
                        <DetailsContentInfoStock stock={product.inStock}/>
                        <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: 10,}}>
                        <DetailsActionQuantity>
                            <DetailsActionQuantityButton
                                onClick={() => removeQuantity()}
                            >-</DetailsActionQuantityButton>
                            <DetailsActionQuantityInput value={quantity} onChange={(e) => onChangeQuantity(e.target.value)}></DetailsActionQuantityInput>
                            <DetailsActionQuantityButton
                                onClick={() => addQuantity()}
                            >+</DetailsActionQuantityButton>
                        </DetailsActionQuantity>
                        <Button
                            fontWeight="600"
                            fontSize="16px"
                            width="50%"
                            expandMobile
                            disabled={!product.inStock}
                            onClick={() => {
                                addUnids();
                            }
                            }
                        >{
                            cartLoader ? (
                                <Loader/>
                            ) : (
                                'Añadir al carrito'
                            )
                        }
                            </Button>
                            </div>
                    </DetailsContentInfoActions>
                    </>
                        )
                    }

                    {
                        !loggedIn && (
                            <>
                                                                            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: 10, flexDirection: 'column'}}>

                                                <DetailsContentInfoReference>Referencia. <DetailsContentInfoReferenceSpan>{product.reference} </DetailsContentInfoReferenceSpan></DetailsContentInfoReference>
                                                <DetailsContentInfoReference>EAN. <DetailsContentInfoReferenceSpan>{product.EAN} </DetailsContentInfoReferenceSpan></DetailsContentInfoReference>
                                                <DetailsContentInfoDescription>
                        {product.description}
                    </DetailsContentInfoDescription>
                            <Link to={`/login?r=/details/${category}/${id}`}
                            style={{textDecoration: 'none'}}
                            >
                            <Button
                            fontWeight="600"
                            fontSize="16px"
                            width="100%"
                            expandMobile
                            customStyle={{textDecoration: 'none'}}
                            >Inicia sesión para ver el precio</Button>
                            </Link>
                            </div>
                            </>
                        )
                    }

                </DetailsContentInfo>

            </DetailsContent>
            <DetailsContentSimilarProducts>
                    <DetailsContentSimilarProductsTitle>Productos similares</DetailsContentSimilarProductsTitle>
                    <DetailsContentSimilarProductsContainer>
                        {
                            similarProducts.map((product, index) => {
                                return (
                                    <DetailsContentSimilarProductsItem key={index} href={'/details/'+product.productURL}>
                                        <DetailsContentSimilarProductsItemImage background={product.images[0]}/>
                                        <DetailsContentSimilarProductsItemTitle>{product.name}</DetailsContentSimilarProductsItemTitle>
                                        <DetailsContentSimilarProductsItemPrice>{product.price && product.price.toFixed(2).toString().replace('.',',')} €</DetailsContentSimilarProductsItemPrice>
                                    </DetailsContentSimilarProductsItem>
                                )
                            }
                            )
                        }

                    </DetailsContentSimilarProductsContainer>
                </DetailsContentSimilarProducts>
                    </>
                 )
            }

        </DetailsContainer>
        {
            modal && (
                <DetailsBackdrop>
                <DetailsModal>
                    <span style={{
                        float: 'right',
                        marginLeft: 'auto',
                        padding: 10,
                        borderRadius: 50,
                        height: 20,
                        width: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#028322',
                        border: '1px solid #028322',
                        cursor: 'pointer',
                    }} onClick={() => setModal(false)}>
                        <IoIosClose size={20}/>
                    </span>
                    <EmptyCart/>
                    <DetailsModalTitle>¿Desea añadir la cantidad o ajustarla?</DetailsModalTitle>
                    <DetailsModalDescription>
                        Actualmente tiene {unids} unidades en el carrito de este producto y ha seleccionado {quantity} unidades. Queremos asegurarnos de que la cantidad sea la correcta.
                    </DetailsModalDescription>
                    <DetailsModalFooter>
                        <Button
                        outline
                            fontSize="16px"
                            width="50%"
                            expandMobile
                            onClick={() => {
                                setQuantity(quantity);
                                setAdded(true);
                                addProductToCart();
                                setModal(false);
                                setUnids(quantity);
                            }
                            }
                            style={{
                                gap: 3,
                            }}
                        >Ajustar a <strong>{quantity}</strong> unids.</Button>
                        <Button
                            fontSize="16px"
                            width="50%"
                            expandMobile
                            onClick={() => {
                                setUnids(unids + quantity);                                
                                setAdded(true);
                                addProductToCart2(quantity + unids);
                                setModal(false);
                            }
                            }
                            
                            style={{
                                gap: 3,
                            }}
                        >Añadir <strong> {quantity} </strong> unids.</Button>
                    </DetailsModalFooter>
                    </DetailsModal>
            </DetailsBackdrop>
            )
        }

        </>
    );
}
