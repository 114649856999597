import React,{useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import {Aviso} from './Avisos';
const AvisoLegal = () => {
    useEffect (() => {
        window.scrollTo(0, 0);
      }
      , []);
  return (
    <div
        style={{
            padding: '20px',
            maxWidth: '800px',
            margin: 'auto',
        }}
    >
        <ReactMarkdown>
            {Aviso}
        </ReactMarkdown>
    </div>
  );
};

export default AvisoLegal;
