import React, { useEffect, useRef } from "react";
import { ModalContainer, ModalContent, ModalHeader, ModalData, ModalHeaderTitle, ModalCloseButton } from "./style";

//times icon
import { FaTimes } from "react-icons/fa";

export default function Modal(props){
    const componentRef = useRef(null);

    //If props.opened, disable scroll on body

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
          // Clicked outside the component, so close it
          props.close();
        }
      };
        //Close modal on escape key
        const handleEscape = (e) => {
            if(e.key === 'Escape'){
                props.close();
            }
        }
    useEffect(() => {
        // Attach a click event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleEscape);

        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
          document.addEventListener('keydown', handleEscape);

        };
      }, []);
    



    //Add event listeners
    

    return (
        <ModalContainer {...props}>
           <ModalContent ref={componentRef} {...props}>
                <ModalHeader>
                    <ModalHeaderTitle>{props.title}</ModalHeaderTitle>
                    <ModalCloseButton  onClick={props.close()}><FaTimes size={15}/></ModalCloseButton>
                </ModalHeader>
                <ModalData>{props.children}</ModalData>
                
           </ModalContent>
        </ModalContainer>
    )
}