import styled from 'styled-components';
import { useState } from 'react';
export const CategoryTitle = styled.h2`
    font-size: 2rem;
    font-weight: 800;
    margin: 0;
    margin-bottom: 20px;
    Text-align: left;
    font-family: 'Inter', sans-serif;
    color: #000;
    `;


export const HomeContainer = styled.div`
    background-color: #fff;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 70px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        padding: 50px;
        padding-top: 20px;
    }
`;
export const OfferContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;


export const OfferItemContainer = styled.div`
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    @media screen and (max-width: 800px){
        width: 100%;
        flex-direction: column;
        height: auto;
        gap: 0px;
    }
`;
export const OfferItemImageButton = styled.button`
    width: 100%;
    height: 30px;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    font-weight: 600;
    display: none;
    &:hover{
        background-color: #028322;
        color: #fff;
    }
    @media screen and (max-width: 800px){
        display: none;
    }
    margin-top: auto;
    float: bottom;
`;
const OfferItemImageComponent = styled.img`
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    aspect-ratio: 1/1;
    @media screen and (max-width: 800px){
        height: 200px;
    }
    transition: all 0.125s ease-in-out;
    display: flex;


    cursor: pointer;
    //before for put the offer percentage ia a octogon

    &:after{
        content: ${props => props.stock ? "'En Stock'" : "'Sin Stock'"};
        position: relative;
        margin-top: 5px;
        margin-left: 200px;
        width: 100px;
        height: 30px;
        min-width: 100px;
        min-height: 30px;
        color: ${props => props.stock ? "#028322" : "#FF0000"};
        display: flex;
        transition: all 0.125s ease-in-out;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        @media screen and (max-width: 800px){
            width: 80px;
            height: 20px;
            font-size: 12px;
        }
    }
    @media screen and (max-width: 800px){
        &:after{
            margin-left: 150px;
        }
    }
`;

export const OfferItemImage = ({src, stock, discount, onClick}) => {
    const [imageLoaded,setImageLoaded] = useState(true);


    const handleImageLoaded = () => {
        setImageLoaded(false);
    }

    return (
        <OfferItemImageComponent src={imageLoaded ? src : 'https://cdn.beeapp.es/distribucionesrb/NoImage.png'} stock={stock} discount={discount} onError={handleImageLoaded}
        onClick={onClick}


        />
    );
};

export const OfferItemInfo = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 800px){
        width: 100%;
        height: auto;
    }
`;

export const OfferItemTitle = styled.h2`
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #000;
    line-height: 1.2;
    text-transform: uppercase;
    @media screen and (max-width: 800px){
        font-size: 16px;
    }
`;

export const OfferItemDescription = styled.p`
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    margin-top: 0px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #000;
    line-height: 1.2;
    @media screen and (max-width: 800px){
        font-size: 10px;
    }
`;

export const OfferItemReference = styled.span`
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 0px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #000;
    line-height: 1.2;
    @media screen and (max-width: 800px){
        font-size: 12px;
    }
`;
export const OfferItemPriceContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;

`;
export const OfferItemPrice = styled.span`
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-top: 0px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #858585;
    line-height: 1.2;
    @media screen and (max-width: 800px){
        font-size: 14px;
    }
`;
export const OfferItemOldPrice = styled.span`
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 0px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #585858;
    line-height: 1.2;
    text-decoration: line-through;
    @media screen and (max-width: 800px){
        font-size: 12px;
    }
`;
export const OfferItemDiscount = styled.span`
    font-size: 20px;
    font-weight: 800;
    margin: 0;
    margin-top: 0px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #028322;
    line-height: 1.2;
    @media screen and (max-width: 800px){
        font-size: 18px;
    }
`;

export const OfferItemAddToCartContainer = styled.form`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 800px){
        
    }
`;

export const OfferItemAddToCartButton = styled.button`
    width: 100px;
    height: 30px;
    background-color: transparent;
    padding: 5px;
    margin: 0px;
    border:none;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    outline: none;
    background-color: #ddd;
    border: 1px solid #ddd;
    font-weight: 500;

    &:hover{
        background-color: #028322;
        color: #fff;
        border: 1px solid #028322;
    }
    
    `;

export const OfferItemAddToCartSelect = styled.select`
    width: 65px;
    height: 30px;
    background-color: #fff;

    border:1px solid #ddd;
    border-radius: 5px;
    outline: none;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 0px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #000;
    line-height: 1.2;
    `;

export const HomeItemContainer = styled.div`
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.125s ease-in-out;
    word-wrap: break-word;
`;

export const HomeItemImage = styled.div`
    width: 100%;
    height: 200px;
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    transition: all 0.125s ease-in-out;
    display: flex;
    &:after{
        content: ${props => props.stock ? "'En Stock'" : "'Sin Stock'"};
        position: absolute;
        margin-top: 5px;
        margin-left: 200px;
        width: 100px;
        height: 30px;
        min-width: 100px;
        min-height: 30px;
        color: ${props => props.stock ? "#028322" : "#FF0000"};
        display: flex;
        transition: all 0.125s ease-in-out;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        @media screen and (max-width: 800px){
            width: 80px;
            height: 20px;
            font-size: 12px;
            margin-left: 150px;
        }
    }
    &:before{
        content: '${props => props.discount}%';
        position: absolute;
        margin-top: 5px;
        margin-left: 5px;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        background-color: red;
        color: #fff;
        display: flex;
        transition: all 0.125s ease-in-out;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
        @media screen and (max-width: 800px){
            width: 30px;
            height: 30px;
            font-size: 12px;
        }
    }
    `;

export const HomeItemInfo = styled.div`
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const HomeItemTitle = styled.h2`
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #000;
    line-height: 1.2;
    text-transform: uppercase;
`;

export const HomeItemReference = styled.span`
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 0px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: #000;
    line-height: 1.2;
`;

export const HomeImageCarousel = styled.div`
    width: 100%;
    height: 400px;
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 800px){
        height: 150px;
    }
`;

export const HomeCarouselLikeAmazon = styled.div`
    width: 100%;
    height: ${props => props.height/2}px;
    display: flex;
    position: absolute;
    left: 0;
    top: 125px;
    @media screen and (max-width: 800px){
        top: 120px;
    }
    background: linear-gradient(180deg, rgba(2, 131, 34, 1) 0%, rgba(2, 131, 34, 0) 100%);
    z-index: 0;
    `;

export const HomeCarouselLikeAmazonSeparator = styled.div`
    width: 100%;
    height: ${props => props.height/3.5}px;
    `;

export const HomeCarouselLikeAmazonHome = styled.img`
    width: 100vw;
    height: auto;
    z-index: -1;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 85%, rgba(0,0,0,0));
    `;

export const ExplanationContent = styled.div`
z-index: ${props => props.zIndex};
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    @media screen and (max-width: 800px){
        flex-direction: column;
        padding: 1rem 0rem;
    }
`;