import React from "react";
import { NotFoundContainer, NotFoundExplanation, NotFoundText } from "./style";
import Button from "../../Components/Miscellaneous/Button";

export default function NotFound() {
    return (
        <NotFoundContainer>
            <NotFoundText>
                404
            </NotFoundText>
            <NotFoundExplanation>
                Página no encontrada
            </NotFoundExplanation>
            <Button onClick={() => window.history.back()} style={{marginTop: '20px'}} width={'300px'} >
                Volver a la página anterior
            </Button>
        </NotFoundContainer>

    )
}