import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../../../AuthService';
import ProtectedLayout from './ProtectedLogin';

const ProtectedAdminRoute = ({ user, children }) => {
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const isAuthenticated = AuthService.isAuthenticated();
        if (isAuthenticated) {
          const adminStatus = await AuthService.isAdmin();
          setIsAdmin(adminStatus.isAdmin);
        }
      } catch (error) {
        // Handle error, e.g., redirect to login page
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  if (loading) {
    // Display loader while checking authentication and admin status
    return <ProtectedLayout/>;
  }

  if (isAdmin) {
    // If authenticated and admin, render the children
    return children;
  } else {
    // If not authenticated or not an admin, navigate to the login page
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedAdminRoute;
