// authService.js

import { toast } from "react-toastify";

class AuthService {
    static async login(email, password) {
      try {
        const response = await fetch('https://api.rb.beeapp.es/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            Password: password,
            Email: email,
           }),
        });
  
        if (response.status === 401) {
          throw new Error("Usuario o contraseña incorrecto.");
        }
        if (response.status === 418) {
          throw new Error("Su usuario no ha sido verificado.");
        }
  
        const user = await response.json();
        // Store user data in local storage or state
        localStorage.setItem('user', JSON.stringify(user));
  
        return user;
      } catch (error) {

        throw new Error(error);
      }
    }

    static async register(name, surname, email, password, cif, phone) {
      try {
        const response = await fetch('https://api.rb.beeapp.es/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            Name: name,
            Surname: surname,
            Email: email,
            Password: password,
            CIF: cif,
            Phone: phone,
           }),
        });
  
        if (!response.ok) {
          throw new Error(response.text());
        }
  
        return {
          message: 'Usuario registrado correctamente',
        };
      } catch (error) {

        throw new Error('Login failed');
      }
    }
    
    static async isAdmin() {
      try {
        const response = await fetch('https://api.rb.beeapp.es/auth/isAdmin', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AuthService.getToken()}`, // Send an authentication token if needed
          },
        });

        if (!response.ok) {
          throw new Error(response.text());
        }

        return response.json();
      } catch (error) {
        throw new Error('Login failed');
      }
    }
    

    // Simulate a logout request
    static async logout() {
      try {
        // Clear user data from local storage or state
        localStorage.removeItem('user');

        toast.success('Se ha cerrado la sesión correctamente');
      } catch (error) {
        throw new Error('Logout failed');
      }
    }
  
    // Check if the user is authenticated
    static async isAuthenticated() {
        // Check if the user data is available in local storage
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          return false;
        }
    
        // If user data is not available, perform a background fetch
        try {
          const response = await fetch('https://api.rb.beeapp.es/auth/', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${AuthService.getToken()}`, // Send an authentication token if needed
            },
          });
    
          if (response.status === 200 || response.status === 201) {
            //get the user data from local storage
            const user = JSON.parse(localStorage.getItem('user'));
            // modify the user data except the accessToken
            const newUser = {
              ...user,
              ...await response.json(),
            };
            // Store the modified user data in local storage
            localStorage.setItem('user', JSON.stringify(newUser));

            return true;
          } else {
            if(user){
            this.logout();
            return false;
            }
            return false;
          }
        } catch (error) {
          console.error(error);
          return false;
        }
      }
    
      static async supportCode(){
        try {
          const response = await fetch('https://api.rb.beeapp.es/support', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${AuthService.getToken()}`, // Send an authentication token if needed
            },
          });
    
          if (response.status === 200 || response.status === 201) {
            return response.json();
          } else {
            return null;
          }
        } catch (error) {
          toast.error('Error al generar el código de soporte');
          return null;
        }
      }
  
    // Get the user data
    static getUser() {
      // Retrieve and return the user data from local storage or state
      if (!this.isAuthenticated()) {
        this.logout();
        return null;
      }
      return JSON.parse(localStorage.getItem('user'));

    }
  
    // Get the authentication token if needed for API requests
    static getToken() {
      // Retrieve and return the authentication token from local storage or state
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.accessToken : null;
    }
  }
  
  export default AuthService;
  