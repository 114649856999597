import React, { useEffect, useState } from 'react';
import { CheckoutContainer, CheckoutContent, CheckoutContentLeft,CheckOutRightLeft,CheckoutConfirmTitle,CheckoutConfirmMap,ShippmentCard,CheckoutConfirm,CheckoutConfirmBack, CheckoutContentRight,Animation,PaymentMethod, PaymentMethodCard,  CheckoutContentTitle,CheckoutItemInfoPrice, CheckoutFooter, CheckoutFooterButton, CheckoutFooterStep, CheckoutFooterStepLine, CheckoutFooterSteps, CheckoutFooterTotal, CheckoutItem, CheckoutItemImage, CheckoutItemInfo, CheckoutItemInfoTitle, CheckoutItemInfoQuantityContainer, CheckoutItemInfoQuantityButton, CheckoutItemInfoQuantity, CheckoutTitle } from './style';
import {BsFillBasket2Fill} from "react-icons/bs";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {BiReceipt, BiChevronRight, BiChevronLeft, BiTrash} from "react-icons/bi";
import { AiOutlinePlus } from 'react-icons/ai';
import CartService from '../../Utils/API/cart';
import { toast } from 'react-toastify';
import isMobile from '../../Components/Miscellaneous/Utils/isMobile';
import { AddressCard, AddressCardAddress, AddressCardContainer, AddressCardName, AddressCardText, AddressEmpty } from '../Profile/style';
import AddressService from '../../Utils/API/address';
import {IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle} from 'react-icons/io';
import { ChakraProvider } from "@chakra-ui/react";
import { StackedImageAnimation } from './StackedImageAnimation';
import { Loader } from '../Login/style';
import AnimatedNumbers from "react-animated-numbers";
import { CSSTransition } from 'react-transition-group';
import '../../App.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Button from '../../Components/Miscellaneous/Button';
import OrderService from '../../Utils/API/orders';
import ReactGA from 'react-ga4';
import ReactCodeInput from 'react-code-input';
import { usePostHog } from 'posthog-js/react'

export default function Checkout() {
    const posthog = usePostHog();
    const [products, setProducts] = useState([]);
    const [step, setStep] = useState(0);
    const [cartID, setCartID] = useState(null);
    const [address, setAddress] = useState([])
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [dropshipping, setDropshipping] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resume, setResume] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [latlong, setLatLong] = useState(null);
    const [loadingMap, setLoadingMap] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(0);

    const [code, setCode] = useState(null);
    const [showCode, setShowCode] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);
    function getLocation() {
        //get from selected address 

        let totalAddress = selectedAddress ? address.find(item => item._id === selectedAddress) : dropshipping.find(item => item._id === selectedAddress);
        if(totalAddress){
            let addressDirection = totalAddress.Address + ', ' + totalAddress.City + ', ' + totalAddress.ZIP + ', ' + totalAddress.Province + ', ' + totalAddress.Country;
            //Mapbox API to get lat and long
            fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/'+addressDirection+'.json?access_token=pk.eyJ1IjoicGFibG9wZzAzIiwiYSI6ImNsbjZ1bDFocTBsc2cycmxkbnJraGY2N2sifQ.79stAqJwi0Z7xGXC32qWmQ'
            ).then(response => response.json())
            .then(data => {
                setLatLong(data.features[0].center);
            }
            ).catch(error => {
                console.log(error);
            });
        }
    }

    function createOrder() {
        // setShowCode(true);
        sendOrder();


    }


    function sendOrder(){
        setLoadingOrder(true);

        ReactGA.event({
            name: "purchase",
            currency: "EUR",
            category: "ecommerce",
            action: "purchase",
            value: products.reduce((accumulator, currentValue) => {
                return accumulator + (currentValue.price*currentValue.quantity);
            }, 0),
            shipping: products.reduce((accumulator, currentValue) => {
                return accumulator + (currentValue.price*currentValue.quantity);
            }, 0) > 120 ? 0 : 6,
            tax: products.reduce((accumulator, currentValue) => {
                return accumulator + (currentValue.price*currentValue.quantity);
            }, 0) * 0.21,
            items: products.map(product => {
                return {
                    id: product.productID,
                    name: product.name,
                    price: product.price,
                    quantity: product.quantity,
                }
            }
            )
        });

        posthog?.capture({
            event: 'purchase',
            properties: {
                currency: "EUR",
                category: "ecommerce",
                action: "purchase",
                value: products.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.price*currentValue.quantity);
                }, 0),
                shipping: products.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.price*currentValue.quantity);
                }, 0) > 120 ? 0 : 6,
                tax: products.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.price*currentValue.quantity);
                }, 0) * 0.21,
                items: products.map(product => {
                    return {
                        id: product.productID,
                        name: product.name,
                        price: product.price,
                        quantity: product.quantity,
                    }
                }
                )
            }
        });



        OrderService.createOrder({
            addressID: selectedAddress,
            dropshipping: dropshipping.filter(item => item._id === selectedAddress).length > 0 ? true : false,
            paymentMethod: paymentMethod,
            cartID: cartID,
            code: code,
        }).then(response => {
            if(response.Status === "Created"){
            toast.success('Pedido realizado');
            setLoadingOrder(false);

            posthog?.capture({
                event: 'order_completed',
                properties: {
                    currency: "EUR",
                    category: "ecommerce",
                    action: "order_completed",
                    value: products.reduce((accumulator, currentValue) => {
                        return accumulator + (currentValue.price*currentValue.quantity);
                    }, 0),
                    shipping: products.reduce((accumulator, currentValue) => {
                        return accumulator + (currentValue.price*currentValue.quantity);
                    }, 0) > 120 ? 0 : 6,
                    tax: products.reduce((accumulator, currentValue) => {
                        return accumulator + (currentValue.price*currentValue.quantity);
                    }, 0) * 0.21,
                    items: products.map(product => {
                        return {
                            id: product.productID,
                            name: product.name,
                            price: product.price,
                            quantity: product.quantity,
                        }
                    }
                    )
                }
            });

            window.location.href='/me/orders';
            }else{
                
                toast.error(response.message);
                setLoadingOrder(false);
            }
        }).catch(error => {
            console.log(error);
        })
    }


    useEffect(() => {
        if(timeLeft===0){
           setTimeLeft(null)
              createOrder();
           setLoadingMap(true)
        }


    
        // exit early when we reach 0
        if (!timeLeft) {
            return;
        };
    
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
    
          setTimeLeft(timeLeft - 1);
        }, 1000);
    
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
      }, [timeLeft]);
    useEffect(() => {
        setLoading(true);
        const getProducts = async () => {
            const products = await CartService.getCart();
            setProducts(products.items);
            setCartID(products._id);
            setLoading(false);
            setStep(1);
        }

        ReactGA.event({
            name: "view_cart",
            currency: "EUR",
            category: "ecommerce",
            action: "view_cart",
            value: products.reduce((accumulator, currentValue) => {
                return accumulator + (currentValue.price*currentValue.quantity);
            }, 0),
            items: products.map(product => {
                return {
                    id: product.productID,
                    name: product.name,
                    price: product.price,
                    quantity: product.quantity,
                }
            }
            )
        });

        posthog?.capture({
            event: 'view_cart',
            properties: {
                currency: "EUR",
                category: "ecommerce",
                action: "view_cart",
                value: products.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.price*currentValue.quantity);
                }, 0),

                items: products.map(product => {
                    return {
                        id: product.productID,
                        name: product.name,
                        price: product.price,
                        quantity: product.quantity,
                    }
                }
                )
            }
        });


        const getAddress = async () => {
            const address = await AddressService.getAddresses();
            if(address.length > 0){
                setSelectedAddress(address[0]._id);
            }
            setAddress(address);
        }
        const getDropshipping = async () => {
            const dropshipping = await AddressService.getDropshippingAddresses();
            setDropshipping(dropshipping);
        }
        getDropshipping();
        getProducts();
        getAddress();
    }
    , []);

    function addQuantity(id) {
        const newProducts = products.map(product => {
            if(product._id === id){
                product.quantity = parseInt(product.quantity) + 1*product.quantityStep;
                CartService.addProductQuantity(product.productID._id, product.quantity);
            }
            return product;
        });
        setProducts(newProducts);
    }

    function removeQuantity(id) {
        const newProducts = products.map(product => {
            if(product._id === id){
                
                product.quantity = parseInt(product.quantity) - 1*product.quantityStep;
                if(product.quantity < 0){
                    product.quantity = 0;
                }
            }
            return product;
        });
        setProducts(newProducts);
        CartService.addProductQuantity(newProducts.find(product => product._id === id).productID._id, newProducts.find(product => product._id === id).quantity+"");

    }

       
    function changeQuantity(id, quantity) {
        let timer;
        const newProducts = products.map(product => {
            if(product._id === id && quantity % product.quantityStep === 0){
                product.quantity = (quantity);
                CartService.addProductQuantity(product.productID._id, product.quantity);
            }else if(product._id === id && quantity % product.quantityStep !== 0){
                product.quantity = quantity;
            }
            clearTimeout(timer);
            return product;
        });
        timer = setTimeout(() => checkMultiple(id), 600);
        setProducts(newProducts);
    }

    function checkMultiple(id) {
        const newProducts = products.map(product => {
            if(product._id === id && product.quantity % product.quantityStep !== 0){
                //allways round up
                product.quantity = Math.ceil(product.quantity/product.quantityStep)*product.quantityStep;
                CartService.addProductQuantity(product.productID, product.quantity);
                toast.error(`La cantidad debe ser múltiplo de ${product.quantityStep}`);
            }
            return product;
        });

        setProducts(newProducts);
    }

    return (
        <>
        <Animation>
        <CheckoutContainer
            style={{
                //compensate for the fixed footer 70px
                paddingBottom: '70px',
            }}
            opened={step !== 2 ? true : false}
        >
            <CheckoutContent>
                {
                    step === 0 && (
                        <Loader/>
                    )
                }
                {
                    step === 1 && (
                        <>
                        {
                            ReactGA.event({
                                name: "begin_checkout",
                                currency: "EUR",
                                category: "ecommerce",
                                action: "begin_checkout",
                                value: products.reduce((accumulator, currentValue) => {
                                    return accumulator + (currentValue.price*currentValue.quantity);
                                }, 0),
                                items: products.map(product => {
                                    return {
                                        id: product.productID,
                                        name: product.name,
                                        price: product.price,
                                        quantity: product.quantity,
                                    }
                                }
                                )
                            })
                        }{
                            posthog?.capture({
                                event: 'begin_checkout',
                                properties: {
                                    currency: "EUR",
                                    category: "ecommerce",
                                    action: "begin_checkout",
                                    value: products.reduce((accumulator, currentValue) => {
                                        return accumulator + (currentValue.price*currentValue.quantity);
                                    }, 0),
                                    items: products.map(product => {
                                        return {
                                            id: product.productID,
                                            name: product.name,
                                            price: product.price,
                                            quantity: product.quantity,
                                        }
                                    }
                                    )
                                }
                            })

                        }
                        <CheckoutTitle style={{width: '100vw'}}>Productos del carrito</CheckoutTitle>
                        
                    {products.map(product => {
                        return (
                            <CheckoutItem
                                key={product._id}
                                stock={product.stock}
                            >
                                <CheckoutItemImage >
                                    <img src={product?.images[0]} alt={product?.name} style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                    }}
                                        onClick={() => window.location.href='/details/'+product?.productURL}
                                    />
                                </CheckoutItemImage>
                                <CheckoutItemInfo>
                                    <CheckoutItemInfoTitle
                                        onClick={() => window.location.href='/details/'+product?.productURL}
                                    >{product?.name}</CheckoutItemInfoTitle>
                                    <CheckoutItemInfoPrice>{
                                        //depending product?.discounts minQuantity get discountedPrice
                                        product?.discounts.length > 0 ? (
                                            product?.discounts.map(discount => {
                                                if(product?.quantity >= discount.minQuantity){
                                                    return discount.discountedPrice;
                                                }else{
                                                    return product?.price;
                                                }
                                            })
                                        ) : (
                                            product?.price
                                        )
                                            }€</CheckoutItemInfoPrice>
                                </CheckoutItemInfo>
                                <CheckoutItemInfoQuantityContainer>
                                    <CheckoutItemInfoQuantityButton onClick={() => removeQuantity(product?._id)}>-</CheckoutItemInfoQuantityButton>
                                    <CheckoutItemInfoQuantity onChange={(e) => changeQuantity(product?._id, e.target.value)}  value={product?.quantity}></CheckoutItemInfoQuantity>
                                    <CheckoutItemInfoQuantityButton onClick={() => addQuantity(product?._id)}>+</CheckoutItemInfoQuantityButton>
                                    <CheckoutItemInfoQuantityButton
                                                style={{
                                                    background: '#fff',
                                                    marginLeft: '0px',
                                                }}
                                                onClick={() => {

                                                    ReactGA.event({
                                                        name: "remove_from_cart",
                                                        currency: "EUR",
                                                        category: "ecommerce",
                                                        action: "remove_from_cart",
                                                        value: product.price,
                                                        items: {
                                                            id: product.productID._id,
                                                            name: product.name,
                                                            price: product.price,
                                                            quantity: product.quantity,
                                                        }
                                                    });
                                                
                                                    posthog?.capture({
                                                        event: 'remove_from_cart',
                                                        properties: {
                                                            currency: "EUR",
                                                            category: "ecommerce",
                                                            action: "remove_from_cart",
                                                            value: product.price,
                                                            items: {
                                                                id: product.productID._id,
                                                                name: product.name,
                                                                price: product.price,
                                                                quantity: product.quantity,
                                                            }
                                                        }
                                                    });

                                                 CartService.deleteProductFromCart(product?._id).then(res => {
                                                    setProducts(products.filter(product2 => product2._id !== product._id));
                                                 }
                                                    ).catch(err => {
                                                        console.log(err);
                                                    }) 
                                                }
                                            }
                                                >
                                                    <BiTrash size={24} color="#028322"/>
                                                </CheckoutItemInfoQuantityButton>
                                </CheckoutItemInfoQuantityContainer>
                            </CheckoutItem>
                        )
                    })
                    }
                    </>
                    
                    )
                }
                {
                    step === 2 && (
                        <>
                        {
                            ReactGA.event({
                                name: "add_shipping_info",
                                currency: "EUR",
                                category: "ecommerce",
                                action: "add_shipping_info",
                                value: products.reduce((accumulator, currentValue) => {
                                    return accumulator + (currentValue.price*currentValue.quantity);
                                }, 0),
                                items: products.map(product => {
                                    return {
                                        id: product.productID,
                                        name: product.name,
                                        price: product.price,
                                        quantity: product.quantity,
                                    }
                                }
                                )
                            })

                        }
                        {
                            posthog?.capture({
                                event: 'add_shipping_info',
                                properties: {
                                    currency: "EUR",
                                    category: "ecommerce",
                                    action: "add_shipping_info",
                                    value: products.reduce((accumulator, currentValue) => {
                                        return accumulator + (currentValue.price*currentValue.quantity);
                                    }, 0),
                                    items: products.map(product => {
                                        return {
                                            id: product.productID,
                                            name: product.name,
                                            price: product.price,
                                            quantity: product.quantity,
                                        }
                                    }
                                    )
                                }
                            })
                        }
                        <CheckoutTitle>Dirección de envío</CheckoutTitle>
                        <AddressCardContainer>
                        <AddressCard onClick={() => window.location.href='/me/address'}>
                    <AddressEmpty>
                    <AiOutlinePlus size={20}/>
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textAlign: 'center'


                        }}>Añadir dirección</span>
                    </AddressEmpty>
                </AddressCard>
                            {
                                address.map((address, index) => (
                                    <AddressCard key={index}
                                        onClick={() => setSelectedAddress(address._id)}
                                    >
                                        <AddressCardAddress style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                        }}> 
                                            {
                                                selectedAddress === address._id ? (
                                                    <IoIosCheckmarkCircle size={22} color='#000'/>
                                                ) : (
                                                    <IoIosCheckmarkCircleOutline size={22} color='#000'/>
                                                )
                                            } {address.Address}, {address.City}, {address.ZIP}, {address.Province}, {address.Country}</AddressCardAddress>
                                    </AddressCard>
                                ))
                            }

                        </AddressCardContainer>
                        <CheckoutTitle>
                                DropShipping
                                {
                                    dropshipping.length > 0 && (
                                        <span style={{
                                            fontSize: '0.75rem',
                                            fontWeight: 'normal',
                                            color: '#aaa',
                                            marginLeft: '10px',
                                        }}>
                                            (Envío a terceros)
                                        </span>
                                    )
                                    
                                }
                            </CheckoutTitle>
                            <AddressCardContainer>
                            <AddressCard onClick={() => window.location.href='/me/dropshipping'}>
                    <AddressEmpty>
                    <AiOutlinePlus size={20}/>
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textAlign: 'center'


                        }}>Añadir Cliente</span>
                    </AddressEmpty>
                </AddressCard>
                                {
                                    dropshipping.map((item, index) => (
                                        <AddressCard
                                            key={index}
                                            onClick={() => setSelectedAddress(item._id)}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px',
                                            }}
                                        >
                                            {
                                                selectedAddress === item._id ? (
                                                    <IoIosCheckmarkCircle size={22} color='#000'/>
                                                ) : (
                                                    <IoIosCheckmarkCircleOutline size={22} color='#000'/>
                                                )
                                            }
                                        <div>
                                        <AddressCardName>
                                            {item.Name} {item.Surname}<br/>
                                        </AddressCardName>
                                        <AddressCardAddress>
                                            {item.Address},<br/> {item.City},<br/>{item.ZIP},<br/> {item.Province},<br/> {item.Country}<br/>
                                        </AddressCardAddress>
                                        <AddressCardText>
                                            {item.Email}
                                        </AddressCardText>
                                        </div>
  
                                      </AddressCard>
                                    ))
                                }
                                </AddressCardContainer>

                        
                        </>
                    )
                }
                {
                    step === 3 && (
                        <CheckOutRightLeft>
                            {
                                ReactGA.event({
                                    name: "add_payment_info",
                                    currency: "EUR",
                                    category: "ecommerce",
                                    action: "add_payment_info",
                                    value: products.reduce((accumulator, currentValue) => {
                                        return accumulator + (currentValue.price*currentValue.quantity);
                                    }, 0),
                                    items: products.map(product => {
                                        return {
                                            id: product.productID,
                                            name: product.name,
                                            price: product.price,
                                            quantity: product.quantity,
                                        }
                                    }
                                    )
                                })

                            }
                            {
                                posthog?.capture({
                                    event: 'add_payment_info',
                                    properties: {
                                        currency: "EUR",
                                        category: "ecommerce",
                                        action: "add_payment_info",
                                        value: products.reduce((accumulator, currentValue) => {
                                            return accumulator + (currentValue.price*currentValue.quantity);
                                        }, 0),
                                        items: products.map(product => {
                                            return {
                                                id: product.productID,
                                                name: product.name,
                                                price: product.price,
                                                quantity: product.quantity,
                                            }
                                        }
                                        )
                                    }
                                })
                            }
                        <CheckoutContentLeft>
                        <CheckoutTitle>Métodos de pago</CheckoutTitle>
                            <PaymentMethod>
                                <PaymentMethodCard
                                    active={paymentMethod === 0 ? true : false}
                                    onClick={() => setPaymentMethod(0)}
                                >
                                    {
                                        paymentMethod === 0 ? (
                                            <IoIosCheckmarkCircle size={22} color='#000'/>
                                            ) : (
                                                <IoIosCheckmarkCircleOutline size={22} color='#000'/>
                                        )
                                    }
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        textAlign: 'left'
                                    }}>
                                        Transferencia bancaria
                                    </span>
                                </PaymentMethodCard>
                                <PaymentMethodCard
                                active={paymentMethod === 1 ? true : false}
                                onClick={() => setPaymentMethod(1)}
                                >
                                    {
                                        paymentMethod === 1 ? (
                                            <IoIosCheckmarkCircle size={22} color='#000'/>
                                            ) : (
                                                <IoIosCheckmarkCircleOutline size={22} color='#000'/>
                                        )
                                    }
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        textAlign: 'left'
                                    }}>
                                        Pago según convenio
                                    </span>
                                </PaymentMethodCard>
                            </PaymentMethod>
                        </CheckoutContentLeft>
                        <CheckoutContentRight>
                            <CheckoutTitle>Envío</CheckoutTitle>
                            <ShippmentCard active={
                                products.reduce((accumulator, currentValue) => {
                                    return accumulator + (currentValue.price*currentValue.quantity);
                                }, 0) > 100 ? false : true
                            }
                            >
                                <IoIosCheckmarkCircle size={22} color='#000' />
                                <span style={{
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    textAlign: 'left'
                                }}>
                                    Envío mediante Distribuciones RB
                                </span>
                            </ShippmentCard>

                            </CheckoutContentRight>
                        
                        </CheckOutRightLeft>
                    )
                }

            </CheckoutContent>

        </CheckoutContainer>


                                
        </Animation>
        <CheckoutFooter>


            <CheckoutFooterTotal>
  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0px',
                }}>
                                {
                (step !== 1 && step !== 0 ) && (
                    <CheckoutFooterButton
                    onClick={() => {
                        if(step === 1){
                            setStep(1);
                        }else if(step === 2){
                            setStep(1);
                        }else if(step === 3){
                            setStep(2);
                        }
                    }}
                    style={{
                        marginRight: '10px',
                    }}
                >
                    {
                        isMobile() ? (
                            <BiChevronLeft size={22} color='#fff'/>
                        ) : (
                            'Volver'
                        )
                    }
                </CheckoutFooterButton>
                )
            } 
                      <AnimatedNumbers
                      includeComma
                      locale="es-ES"
                      animateToNumber={
                        products.reduce((accumulator, currentValue) => {
                            const productPrice = currentValue.discounts && currentValue.discounts.length > 0
                                ? currentValue.discounts.find(discount => currentValue.quantity >= discount.minQuantity)
                                    ? currentValue.discounts.find(discount => currentValue.quantity >= discount.minQuantity).discountedPrice
                                    : currentValue.price
                                : currentValue.price;
                            return accumulator + (productPrice * currentValue.quantity);
                        }, 0).toFixed(3).split('.')[0] >= 120 
                            ? products.reduce((accumulator, currentValue) => {
                                const productPrice = currentValue.discounts && currentValue.discounts.length > 0
                                    ? currentValue.discounts.find(discount => currentValue.quantity >= discount.minQuantity)
                                        ? currentValue.discounts.find(discount => currentValue.quantity >= discount.minQuantity).discountedPrice
                                        : currentValue.price
                                    : currentValue.price;
                                return accumulator + (productPrice * currentValue.quantity);
                            }, 0).toFixed(3).split('.')[0] 
                            : (products.reduce((accumulator, currentValue) => {
                                const productPrice = currentValue.discounts && currentValue.discounts.length > 0
                                    ? currentValue.discounts.find(discount => currentValue.quantity >= discount.minQuantity)
                                        ? currentValue.discounts.find(discount => currentValue.quantity >= discount.minQuantity).discountedPrice
                                        : currentValue.price
                                    : currentValue.price;
                                return accumulator + (productPrice * currentValue.quantity);
                            }, 0).toFixed(3) ).split('.')[0]
                    }
                    
                        configs={(number, index) => {
                        return { mass: 1, tension: 330 * (index + 1), friction: 100 };
                        }}
                    ></AnimatedNumbers>
                    <span
                        style={{
                            fontSize: '0.85rem',
                            fontWeight: 'normal',
                            color: '#656565',
                        }}
                    >
                        {products.reduce((accumulator, currentValue) => {
                                return accumulator + (currentValue.price*currentValue.quantity);
                            }
                            , 0).toFixed(3).split('.')[1]}
                    </span>
                    
                    €
                </div>


                </CheckoutFooterTotal>
            <CheckoutFooterSteps>
                <CheckoutFooterStep
                    onClick={() => setStep(1)}
                >
                    <BsFillBasket2Fill size={22} color='#028322'/>
                    <CheckoutFooterStepLine active></CheckoutFooterStepLine>
                </CheckoutFooterStep>
                <CheckoutFooterStep
                    onClick={() => setStep(2)}
                >
                    <HiOutlineLocationMarker size={22} color={step > 1 ? '#028322' : '#aaa'}/>
                    <CheckoutFooterStepLine active={step > 1 ? true : false}></CheckoutFooterStepLine>
                </CheckoutFooterStep>
                <CheckoutFooterStep
                    onClick={() => setStep(3)}
                >
                    <BiReceipt size={22} color={step > 2 ? '#028322' : '#aaa'}/>
                    <CheckoutFooterStepLine active={step > 2 ? true : false}></CheckoutFooterStepLine>
                </CheckoutFooterStep>
            </CheckoutFooterSteps>
            <CheckoutFooterButton 
                onClick={() => {
                    if(step === 1){
                        setStep(2);
                    }else if(step === 2){
                        setStep(3);
                    }else if(step === 3){
                        setStep(3);
                        getLocation();
                        setResume(true);
                        setTimeLeft(10);
                    }
                }}
            >
                {
                    isMobile() ? (
                        <BiChevronRight size={22} color='#fff'/>
                    ) : (
                        {
                            1: 'Siguiente',
                            2: 'Siguiente',
                            3: 'Finalizar',
                        }[step]
                    )
                        
                    
                }
            </CheckoutFooterButton>
            </CheckoutFooter>
            
                
                    <CheckoutConfirmBack resume={resume}>
                        <CSSTransition in={resume}
                            timeout={300}
                            appear={true}
                            classNames="slidingUp-bottom"
                            unmountOnExit>
      
                                    {
                                        showCode ? (
                                            <CheckoutConfirm>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    width: '100%',
                                                    height: '75px',
                                                }}>
                                                    <span style={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        color: '#028322'
                                                    }}>
                                                        Necesitamos confirmar su identidad
                                                    </span>

                                                

                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '30px',
                                                    width: '100%',
                                                    height: 'calc(100% - 150px)',
                                                }}>
                                                    <span style={{
                                                        fontSize: '1rem',
                                                        fontWeight: '500',
                                                        textAlign: 'pretty',
                                                        color: '#028322', marginBottom: 'auto'
                                                        
                                                    }}>
                                                        Le hemos enviado un código de confirmación a su teléfono móvil, este código es válido durante 5 minutos. Por favor, introduzca el código para confirmar su pedido.
                                                    </span>
                                                    <ReactCodeInput type="number" fields={6} onChange={(e) => setCode(e)}
                                                        style={{
                                                            marginBottom: 'auto',
                                                        }}
                                                    />
                                                    <Button outline onClick={() => {
                                                            sendOrder();
                                                    }}
                                                    customStyle={{
                                                        marginBottom: 'auto'
                                                    }}
                                                    overedAnimation
                                                        loading={loadingOrder}
                                                    >
                                                        Confirmar
                                                    </Button>
                                                </div>
                                                </CheckoutConfirm>
                                        ) : (
                                            <CheckoutConfirm>
                                            <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'center',
                                                gap: '10px',
                                                width: '100%',
                                                height: '75px',
                                            }}
                                        >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '10px',
                                                width: 50,
                                                height: 50,
                                            }}
                                        >
                                            {
                                                loadingMap ? (
                                                    <Loader/>
                                                ) : (
                                    <CircularProgressbar
                                        value={10-timeLeft}
                                        text={`${timeLeft}s`}
                                        maxValue={10}
                                        styles={buildStyles({
                                            rotation: 0,
                                            strokeLinecap: 'round',
                                            pathTransitionDuration: 0.5,
                                            pathColor: `#028322`,
                                            textColor: '#028322',
                                            textSize: '34px',
                                            fontWeight: '600',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#028322',
                                        })}
                                    />
                                                    )
                                            }
                                    
                                    </div>
                                    <CheckoutConfirmTitle>
                                        Confirmando su pedido...
                                    </CheckoutConfirmTitle>
                                    </div>
                                    <CheckoutConfirmMap src={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s-embassy+000(${latlong})/${latlong},14/400x150@2x?access_token=pk.eyJ1IjoicGFibG9wZzAzIiwiYSI6ImNrOW5lbWp3OTAxZTMzaHFiYTk4amV4Z3cifQ.kOjyYSvpqjQNyj_0dYFfsg&attribution=false&logo=false`}>
                                        </CheckoutConfirmMap>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                        width: '100%',
                                        height: 'calc(100% - 150px)',
                                    }}>
    
                                            {
                                                products.map(product => (
                                                    <div style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        height: 'auto',
                                                        padding: '10px',
                                                        boxSizing: 'border-box',
                                                        flexDirection: 'row',
                                                    }}>
                                                    <span
                                                    style={{
                                                        fontSize: '1rem',
                                                        fontWeight: 'bold',
                                                        textAlign: 'left',
                                                        width: 'auto',
                                                        backgroundColor: '#028322',
                                                        color: '#fff',
                                                        padding: '5px 10px',
                                                        maxHeight: '35px',
                                                    }}
                                                >
                                                    {product.quantity}
                                                </span>
                                                    <span
                                                        style={{
                                                            fontSize: '1rem',
                                                            fontWeight: '500',
                                                            textAlign: 'left',
                                                            width: 'auto',
                                                            padding: '0px 10px',
                                                        }}
                                                    >
                                                        {product.name}
                                                    </span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                height: '75px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '10px',
                                            }}>
                                                <Button 
                                                    onClick={() => {
                                                        setResume(false);
                                                        setTimeLeft(-1);
                                                        setLoadingMap(false);
                                                    }}
                                                    backgroundColor={'#aaa'}
                                                    item
                                                    width={'100px'}
                                                    borderRadius={'50px'}
                                                    padding={'0px 10px'}
                                                >
                                                    Cancelar
                                                </Button>
                                            </div>
                                    </CheckoutConfirm>  
                                        )
                                    }


                            
                        </CSSTransition>
                    </CheckoutConfirmBack>
                

            

        </>
    )
}