import React from "react";
import { useEffect, useState } from "react";
import { HomeItem, HomeItemImage } from "./style";
import { Input } from "../../Login/style";
import Button from "../../../Components/Miscellaneous/Button";
import AuthService from "../../../AuthService";
import { InputFile } from "./productCreator/style";
import { FiAlertTriangle } from "react-icons/fi";

export default function HomeEditor() {
    // Scroll to top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [image, setImage] = useState('');
    const [homeItems, setHomeItems] = useState([]);
    const fileInput = React.useRef(null);
    useEffect(() => {
        fetch('https://api.rb.beeapp.es/home')
            .then(response => response.json())
            .then(data => {
                setHomeItems(data.slider);
            });
    }, []);
    const updateSlider = () => {
        fetch('https://api.rb.beeapp.es/home/slider/'+homeItems[0]._id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getToken()
            },
            body: JSON.stringify({
                slider: homeItems
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleFileInput = (e) => {
        const file = e.target.files[0];

        //upload file to /admin/imageUpload

        const formData = new FormData();
        formData.append('image', file);

        fetch('https://api.rb.beeapp.es/admin/imageUpload', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + AuthService.getToken()
            },
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setHomeItems(homeItems.map((item, index) => {
                if(index === 0){
                    item.image = "https://cdn.beeapp.es/"+data.imagename;
                }
                return item;
            }
            ));
        })
        .catch(error => {
            console.error(error);
        })


    }
    return (
        <div style={{
            padding: '20px',
            maxWidth: '800px',
            margin: 'auto',
        }}>
            <h1>
                <FiAlertTriangle size={24} color="#FFA500" />
                &nbsp;
                En construcción</h1>
            <h1>Editor de la página de inicio</h1>
            {
                homeItems.map((item, index) => {
                    return (
                        <HomeItem key={index}>
                            <HomeItemImage src={item.image}
                            alt="Imagen de la página de inicio" 
                            onClick={() => fileInput.current.click()} />

                            
                            <InputFile type="file" ref={fileInput} onChange={handleFileInput}
                            style={{display: 'none'}} />
                            <Input type="text" value={item.link}
                            onChange={(e) => {
                                homeItems[index].link = e.target.value;
                            }}
                            />
                        </HomeItem>
                    );
                }
                )
            }

            <Button
                onClick={updateSlider}
                style={{
                    marginTop: '20px',
                    width: '100%'
                }}
            >
                Guardar
            </Button>
        </div>
    );
    }
