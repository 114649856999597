import CookieConsentAPI from './cookie'
import React, {useState, useEffect} from 'react';
import { BackDrop, HiddenInput, ModalBody, ModalBodyItem, ModalBodyItemLeft, ModalBodyItemRight, ModalContainer, ModalFooter, ModalHeader, RoundedSlider, Switch } from './style';
import Button from '../Miscellaneous/Button';
import { UtilsContext } from '../../Context/UtilsContext';
export default function CookieConsent() {

    const [Cookies, setCookies] = useState({});
    const {showCookieConsent, setShowCookieConsent} = React.useContext(UtilsContext);
    const [Analytics, setAnalytics] = useState(false);
    const [Marketing, setMarketing] = useState(false);
    const [activityFollow, setActivityFollow] = useState(false);

    const AnalyticsRef = React.createRef();
    const MarketingRef = React.createRef();
    const ActivityFollowRef = React.createRef();

    useEffect(() => {
        console.log('showCookieConsent', showCookieConsent);
        if (showCookieConsent){
            return;
        }
        if (CookieConsentAPI.getCookieConsent()?.notSet) {
            setShowCookieConsent(true);
            document.body.style.overflow = 'hidden';

        }
        document.body.style.overflow = 'unset';


        setCookies(CookieConsentAPI.getCookieConsent());
        //block scroll
        return () => {
            document.body.style.overflow = 'unset';
        }

    }
    , [setShowCookieConsent, showCookieConsent]);

    function saveCookies(){
        CookieConsentAPI.setCookieConsent({
            Analytics,
            Marketing,
            activityFollow
        });
        setShowCookieConsent(false);
        document.body.style.overflow = 'unset';
    }

    function acceptAllCookies(){
        CookieConsentAPI.acceptAllCookies();
        setShowCookieConsent(false);
        document.body.style.overflow = 'unset';
    }

    function rejectAllCookies(){
        CookieConsentAPI.rejectAllCookies();
        setShowCookieConsent(false);
        document.body.style.overflow = 'unset';
    }
    
    return showCookieConsent && (
    
        <BackDrop>
            <ModalContainer>
                <ModalHeader>
                    <h3>Política de Cookies</h3>
                </ModalHeader>
                <ModalBody>
                <ModalBodyItem>
                    <ModalBodyItemLeft>
                    <h4>Cookies de Analíticas</h4>
                    <p>
                    Las cookies de analíticas nos permiten recopilar información para analizar y medir el uso y actividad de nuestro sitio web, así como elaborar perfiles de navegación de los usuarios de dicho sitio con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.
                    </p>
                    </ModalBodyItemLeft>
                </ModalBodyItem>
                <ModalBodyItem>
                    <ModalBodyItemLeft>
                    <h4>Cookies de Marketing</h4>
                    <p>
                    Las cookies de marketing nos ayudan a brindarte una experiencia personalizada en nuestro sitio. Su propósito es mostrar anuncios que sean relevantes y atractivos para ti, haciéndolos más valiosos tanto para nosotros como para nuestros socios publicitarios.                </p>
                    </ModalBodyItemLeft>
                </ModalBodyItem>
                <ModalBodyItem>
                    <ModalBodyItemLeft>
                    <h4>Seguimiento de Actividad</h4>
                    <p>
                        Enviamos a nuestros servidores información sobre tu actividad en nuestro sitio web, como las páginas que has visitado, para que podamos mejorar y ofrecer un servicio más personalizado.
                    </p>
                    </ModalBodyItemLeft>
                </ModalBodyItem>
                </ModalBody>
                <ModalFooter>

                    <Button  onClick={() => acceptAllCookies()}>Acepto el uso de cookies</Button>

                </ModalFooter>
            </ModalContainer>
        </BackDrop>
    );
}

