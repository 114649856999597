import styled from 'styled-components';
import ContentLoader from "react-content-loader"

export const HeaderContainer = styled.header`
    background-color: #fff;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0 70px;
    box-sizing: border-box;
    @media screen and (max-width: 800px){
        padding: 0px 0px 0 20px;
        box-sizing: border-box;
    }

    @media screen and (max-width: 600px){
        padding: 0px 0 0 10px;
        height: 50px;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
    }


`;


export const LogoContainer = styled.div`
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    min-width: 200px;
    cursor: pointer;
    @media screen and (max-width: 800px){
        min-width: 150px;
    }
    @media screen and (max-width: 600px){
        min-width: 50px;
        width: 100px;
    }
    
`;

export const Logo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const SearchContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 40px;
    flex-direction: column;
    min-width: 300px;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    border-radius: 5px;
    ${props => props.opened && 'margin-top: 400px;'}
    
    @media screen and (max-width: 800px){
        display: none;
    }
    & span{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Search = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 1rem;
    outline: none;
    transition: all 0.2s ease-in-out;
    &:focus ${SearchContainer}{
        box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    }



`;

export const SearchButton = styled.button`
    width: 65px;
    height: 40px;
    border: none;
    ${
        props => props.opened ? 'border-radius: 0px 5px 0px 0px;' : 'border-radius: 0px 5px 5px 0px;'
    }
    background-color: #028322;
    color: #fff;
    font-size: 1rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    `;

export const Actions = styled.div`
    width: auto;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ProfileButton = styled.button`
   width: auto;
    min-width: 75px;
    height: 75px;
    border: none;
    background-color: #fff;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px){
        width: 50px;
        height: 50px;
        min-width: 50px;
        margin-right: 0px;
        background-color: transparent;
        & span{
            display: none;
        }
    }
    `;

    export const CartButton = styled.button`
    width: 75px;
    height: 75px;
    border: none;
    background-color: #028322;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after{
        content: '${props => props.count ? props.count : '0'}';
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;

        background-color: #fff;
        color: #028322;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 600px){
            top: 5px;
            right: 5px;
            width: 15px;
            height: 15px;
            font-size: 10px;
        }
    }
    @media screen and (max-width: 600px){
        width: 50px;
        height: 50px;
    }

    `;

export const SearchContent = styled.div`
    width: 100%;
    height: 400px;
    background-color: #fff;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    border-radius: 0 0 5px 5px;
    flex-direction: column;
    overflow-y: auto;
`;

export const MobileMenuButton = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: none;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px){
        display: flex;
    }
`;

export const SearchMobileContainer = styled.div`
    width: 100%;
    height: ${props => props.openSearch ? '70px' : '100vmax'};
    display: none;
    padding: 10px 20px;
    box-sizing: border-box;
    border-bottom: 0.5px solid #ddd;
    z-index: -1;
    @media screen and (max-width: 600px){
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: #fff;
        flex-direction: column;
        ${props => !props.openSearch && 'position: fixed; top:50px; z-index: 1000000; background-color: #fff; box-shadow: 0 2px 4px rgba(0,0,0,0.25)'}
    }
    & span{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
`;

export const SearchMobile = styled.input`
    width: 100%;
    height: 46px;
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 1rem;
    outline: none;
    transition: all 0.1s ease-in-out;
    &:focus {
        box-shadow: 0 2px 4px rgba(0,0,0,0.25);
        border: 0px solid #028322;
    }

`;

export const SearchMobileButton = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: #028322;
    color: #fff;
    font-size: 1rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    `;

export const SearchLink = styled.a`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 2px 10px;
    transition: all 0.1s ease-in-out;
    color: #000;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
        background-color: #e2e2e2;
    }

    &:active{
        background-color: #028322;
        color: #fff;
    }
`;

export const CartTitle = styled.span`
    width: auto;
    height: auto;
    font-size: 1.2rem;
    font-weight: 500;
    color: #028322;
    margin-right: 0px;
    text-align: left;

`;


export const CartItem = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: #e2e2e2;
    }
    &:last-child{
        border-bottom: none;
    }
`;

export const CartItemTitle = styled.span`
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 600;
    color: #028322;
    margin-right: 0px;
    text-align: left;
    margin-bottom: 0px;
    &:hover{
        text-decoration: underline;
    }
`;

export const CartItemPrice = styled.span`
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 400;
    color: #028322;
    margin-right: 0px;
    text-align: left;
    margin-bottom: 5px;
`;

export const CartItemPriceTotal = styled.span`
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 600;
    color: #028322;
    margin-right: 0px;
    text-align: right;
    margin-bottom: 5px;
`;

export const CartItemInfo = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`;

export const CartItemImage = styled.div`
    width: 50px;
    height: 50px;
    min-width: 50px;
    background-color: #ddd;
    border-radius: 5px;
    background-image: url(${props => props.background});
    background-size: cover;
    background-position: center;
    margin-right: 10px;
`;

export const SearchBarLoading = (props) => (
  <ContentLoader 
    speed={1}
    width={400}
    height={40}
    viewBox="0 0 400 40"
    backgroundColor="#cfcfcf"
    foregroundColor="#c2c2c2"
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="350" height="40" /> 
    <rect x="360" y="0" rx="5" ry="5" width="40" height="40" />
  </ContentLoader>
)

export const MobileDrawer = styled.div`
    width: 100dvw;
    height: 100dvh;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    padding-left: 0px;
    box-sizing: border-box;
    overflow-y: auto;
`;

export const MobileDrawerHeader = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    padding-left: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
`;

export const MobileDrawerItem = styled.div`
    width: 100%;
    height: ${props => props.special ? '80px' : 'calc((100dvh - 80px - 50px)/11)'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${props => props.special ? '1.5rem' : '1rem 1.5rem'};
    background-color: ${props => props.special ? '#FF0000' : '#fff'};
    font-size: ${props => props.special ? '1.2rem' : '1rem'};
    color: ${props => props.special ? '#fff' : '#000'};
    font-weight: 600;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    &:hover{
        background-color: ${props => props.special ? '#FF0000' : '#e2e2e2'};
    }
`;

export const MobileDrawerItemSpecialCard = styled.div`
    width: 170px;
    height: 200px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
        transform: scale(1.1);
    }
`;

export const MobileSearchDrawer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    padding-left: 0px;
    box-sizing: border-box;
    overflow-y: auto;
`;