import React, {useEffect, useState} from "react";
import { AddressCard, AddressCardAddress, AddressCardContainer, AddressCardName, AddressCardText, AddressEmpty, AddressInput, AddressInputLabel, Field, InfoBadge, ProfileContentSubTitle, ProfileContentTitle } from "./style";
//plus icon
import {AiOutlinePlus} from 'react-icons/ai';
import Modal from "../../Components/Miscellaneous/Modal";
import Button from "../../Components/Miscellaneous/Button";
import { toast } from "react-toastify";
import AddressService from "../../Utils/API/address";
import { Loader } from "../Login/style";
export default function DropShipping() {

    const [opened, setOpened] = useState(false);

    /**
     * 
     * The Schema is:
     * {
  "Address": "123 Main Street",
  "ZIP": "12345",
  "City": "Exampleville",
  "Province": "Sample Province",
  "Country": "Sample Country",
  "Name": "John",
  "Surname": "Doe",
  "CIF": "ABC123",
  "Email": "johndoe@example.com"
}
     */

    const [client, setClient] = useState({
        Address: '',
        ZIP: '',
        City: '',
        Province: '',
        Country: '',
        Name: '',
        Surname: '',
        CIF: '',
        Email: ''
    });

    const [address, setAddress] = useState([]);

    const [loading, setLoading] = useState(false);

    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        setLoadingData(true);
        AddressService.getDropshippingAddresses().then(response => {
            setAddress(response);
            setLoadingData(false);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    const addClient = () => {
        //Validate client object and add it to the database
        //Check if not empty any field
        setLoading(true);
        if(Object.values(client).some(x => x === '')){
            toast.error('Por favor, rellene todos los campos');
            setLoading(false);
            return;
        }

        //Check if email is valid
        if(!client.Email.includes('@')){
            toast.error('Por favor, introduzca un email válido');
            return;
        }

        //Check if CIF is valid
        if(client.CIF.length < 9){
            toast.error('Por favor, introduzca un CIF/DNI válido');
            return;
        }

        //Check if ZIP is valid
        if(client.ZIP.length < 5){
            toast.error('Por favor, introduzca un código postal válido');
            return;
        }

        //Check if phone is valid

        //Check if country is valid
        if(client.Country.length < 3){
            toast.error('Por favor, introduzca un país válido');
            return;
        }

        //Check if province is valid
        if(client.Province.length < 3){
            toast.error('Por favor, introduzca una provincia válida');
            return;
        }

        //Check if city is valid
        if(client.City.length < 3){
            toast.error('Por favor, introduzca una ciudad válida');
            return;
        }

        //Check if address is valid

        if(client.Address.length < 3){
            toast.error('Por favor, introduzca una dirección válida');
            return;
        }

        //Check if name is valid
        if(client.Name.length < 3){
            toast.error('Por favor, introduzca un nombre válido');
            return;
        }

        AddressService.addDropshippingAddress(client).then(response => {
                setLoading(false);
                setOpened(false);
                setClient({
                    Address: '',
                    ZIP: '',
                    City: '',
                    Province: '',
                    Country: '',
                    Name: '',
                    Surname: '',
                    CIF: '',
                    Email: ''
                });
            
        }
        ).catch(error => {
            setLoading(false);
        }
        );
    }



    const close = () => {
        setOpened(false);
    }

    return (
        <div>
            <ProfileContentTitle>
                DropShipping
            </ProfileContentTitle>

            <InfoBadge>
                <span style={{fontWeight: 'bold'}}>¿Qué es el DropShipping?</span>
                <span>
                El dropshipping es un modelo de negocio en el que nosotros, como proveedores, nos encargamos de enviar los productos directamente a los clientes de nuestros clientes. Basta con que usted nos proporcione los datos del cliente y, si lo desea, puede personalizar los correos electrónicos que se envían a esos clientes. Este enfoque simplifica la gestión de inventario y la logística, permitiéndole centrarse en la comercialización y la construcción de relaciones con los clientes.
                </span>
            </InfoBadge>
            <Button onClick={() => setOpened(true)} fontWeight={500}>
                    Configurar DropShipping
                </Button>
            <ProfileContentSubTitle>
                Clientes
            </ProfileContentSubTitle>
            <AddressCardContainer>
                <AddressCard onClick={() => setOpened(true)}>
                    <AddressEmpty>
                        <AiOutlinePlus size={20}/>
                        <span style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textAlign: 'center'


                        }}>Añadir Cliente</span>
                    </AddressEmpty>
                </AddressCard>
                        {

                            address.map((item, index) => {
                                //Name is AddressCardName
                                return (
                                  <AddressCard>
                                    <div>
                                    <AddressCardName>
                                        {item.Name} {item.Surname}<br/>
                                    </AddressCardName>
                                    <AddressCardAddress>
                                        {item.Address},<br/> {item.City},<br/>{item.ZIP},<br/> {item.Province},<br/> {item.Country}<br/>
                                    </AddressCardAddress>
                                    <AddressCardText>
                                        {item.Email}
                                    </AddressCardText>
                                    </div>
                                    <button style={{
                                        width: 'auto',
                                        height: 'auto',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => {
                                            AddressService.deleteDropshippingAddress(item._id).then(response => {
                                                setAddress(address.filter((item, index2) => index2 !== index));
                                            }).catch(error => {
                                                toast.error('Error al eliminar el cliente');
                                            });
                                        }
                                        }
                                    >
                                        Eliminar
                                    </button>
                                  </AddressCard> 
                                )
                            })
                        }
                        {
                            loadingData ? (
                                <Loader/>
                            ) : (
                                null
                            )
                        }
            </AddressCardContainer>
            <Modal title={"Añadir nuevo cliente"} opened={opened} close={()=>close}>
                <Field>

                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            Name: e.target.value
                        })} placeholder="Nombre"/>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            Surname: e.target.value
                        })} placeholder="Apellidos"/>
                </Field>
                <Field>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            CIF: e.target.value
                        })} placeholder="DNI/CIF"/>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            Province: e.target.value
                        })} placeholder="Provincia"/>
                </Field>
                <Field>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            City: e.target.value
                        })} placeholder="Ciudad"/>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            ZIP: e.target.value
                        })} placeholder="Código Postal" type="number"/>
                </Field>
                <Field>
                        <AddressInput onChange={(e) => setClient({
                            ...client,
                            Address: e.target.value
                        })} placeholder="Dirección"/>
                        <AddressInput 
                        onChange={(e) => setClient({
                            ...client,
                            Email: e.target.value
                        })} placeholder="Email"/>
                </Field>
                <AddressInput onChange={(e) => setClient({
                            ...client,
                            Country: e.target.value
                        })} placeholder="País" style={{width:'100%'}} />

                <Button loading={loading} onClick={() => addClient()} fontWeight={500}>
                    Añadir Cliente
                </Button>
            </Modal>
        </div>
    )
}