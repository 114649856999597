import { toast } from "react-toastify";
import AuthService from "../../AuthService";
import ReactGA from "react-ga4";

const API_URL = 'https://api.rb.beeapp.es';
const token = AuthService.getToken();

class CartService{

    //Get cart by user
    static async getCart() {
        try {
            const response = await fetch(`${API_URL}/cart/items`, {
                method: 'GET',
                headers:{
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            return data || [];
        }
        catch (error) {
            console.error(error);
        }
    }

    //Add product to cart
    static async addProductToCart(product) {
        const { id, quantity } = product;

        const response = await fetch(`${API_URL}/cart/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                productID: id,
                quantity,
            }),
        });

        if (!response.ok) {
            toast.error('No se pudo agregar el producto al carrito ya que no hay suficiente stock');
            return;
        }

        const data = await response.json();
        toast.success('Producto agregado al carrito');
        return data || [];


    }


    static async deleteProductFromCart(id) {
        const response = await fetch(`${API_URL}/cart/items/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        toast.success('Producto eliminado del carrito');
        return data || [];
    }


    static async addProductQuantity(id, quantity) {
        const response = await fetch(`${API_URL}/cart/add`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                productID: id,
                quantity,
            }),
        });
        const data = await response.json();
        return data || [];
    }

    static async getTotalItems() {
        const response = await fetch(`${API_URL}/cart/total`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        const data = await response.json();
        return data || 0;

    }



}

export default CartService;